import AddEventForm from "modules/website/pages/edit/components/addEventForm";
// import "./styles.scss";
import React from 'react';
import Modal from 'shared/components/modal';
// import { addNote } from "modules/website/redux/operators";
// import Notification from "shared/components/notification";
// import { Mixpanel } from "shared/services/mixpanel";

interface props {
    show: boolean
    close: any
    editEvent: any
    setData: any
    website: any
    events: any
}


const EditEventModal: React.FC<props> = ({  show, close, editEvent, website, setData, events }) => {
    return (
        <>
            <Modal show={show} close={close} head={false} title="Edit Event" destroyOnClose>
                <AddEventForm 
                    isUpdating={true} 
                    data={editEvent} 
                    website={website} 
                    events={events}
                    setData={setData}
                    handleFormClosing={close}
                />
            </Modal>
        </>
    )
}

export default EditEventModal;