import "./styles.scss"
import React, { useEffect, useState } from 'react'
import { Col, Row, Skeleton } from 'antd';
import Section from "shared/components/section";
import Heading from "shared/components/heading";
import Event from "shared/composedComponents/event";
import ViewEventDetailsModal from "../../modals/viewEventDetailsModal";
import useWindowSize from 'shared/hooks/use-window-size';
import { getEventsByWebsiteSlug } from "modules/website/redux/operators";
import Button from "shared/components/bButton";
import ViewAllEventsModal from "../../modals/viewAllEventsModal";
import EditEventModal from "../../components/editEventModal";
import { getBtnBackgroundColor } from "shared/utils/helper";
import { getTextColor } from "shared/utils/helper";

interface props{
    website: any
    color: string
    increaseIndex?: any
}

const EventSection: React.FC<props> = ({website, color, increaseIndex}) => {
    const {width} = useWindowSize()
    const [isViewEventDetailsModalVisible, setIsViewEventDetailsModalVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [events, setEvents] = useState([])
    const [isViewEventsModalVisible, setIsViewEventsModalVisible] = useState(false)
    const [isEditEventModalVisible, setIsEditEventModalVisible] = useState(false)
    const [editEvent, setEditEvent] = useState(null)

    const handleEditEvent = (event: any) => {
        setEditEvent(event)
        setIsEditEventModalVisible(true)
    }

    const fetchEvents = async (websiteId: string, slug: string) => {
        setIsLoading(true)
        const response = await getEventsByWebsiteSlug(websiteId, slug)
        setIsLoading(false)
        if (response?.status === "success") {
            setEvents(response?.data?.milestones)
        }
    }

    useEffect(() => {
        fetchEvents(website?.id, website?.slug)
    }, [])

    if(events?.length === 0) {
        return <></>
    }
    else{
        increaseIndex()
    }

    return (
        <>
            <div id="events"></div>
            <Section backgroundColor={color}>
                {
                    isLoading ?
                    <Skeleton title paragraph />
                    :
                    <Row className='eventSectionContainer' justify="space-between">
                        <Col span={24} style={{textAlign: width > 450 ? "center" : "left"}}>
                            <Heading 
                                text={<>View all
                                <span
                                className={"spanText"}
                                style={getTextColor(website?.website_color, color) || {undefined}}> Villie</span> Events</>}
                                classes={["heading"]}
                                styles={getTextColor(website?.website_color, color)}
                            />
                        </Col>
                        <Col span={24} className="eventsBox">
                            {events?.slice(0,4).map((event: any) => (
                                <div key={event?.id} style={{marginBottom: "5%"}}>
                                    <Event action={() => setIsViewEventDetailsModalVisible(true)} event={event} handleEditEvent={handleEditEvent} website={website}/>
                                </div>
                            ))}    
                        </Col>
                        <Col span={24} className="lastRow">
                            <Button  styles={getBtnBackgroundColor(website?.website_color, color)} classes={["btn"]} type="theme" text="view all events" action={() => setIsViewEventsModalVisible(true)}/>
                        </Col>
                    </Row>
                }
                
            </Section>

            <ViewEventDetailsModal 
                show={isViewEventDetailsModalVisible}
                close={() => {setIsViewEventDetailsModalVisible(false)}}
            />

            <ViewAllEventsModal 
                show={isViewEventsModalVisible}
                close={() => setIsViewEventsModalVisible(false)}
                website={website}
                events={events}
                handleEditEvent={handleEditEvent}
            />

            <EditEventModal
                close={() => {
                    setIsEditEventModalVisible(false)
                    setEditEvent(null)
                }}
                show={isEditEventModalVisible}
                editEvent={editEvent}
                website={website}
                events={events}
                setData={setEvents}
            />
        </>
    )
}

export default EventSection