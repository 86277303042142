import React from 'react';
import NewPreviewModeContainer from 'modules/website/pages/newPreviewMode';

const NewPreviewMode: React.FC = () => {

    return(
        <NewPreviewModeContainer />
    );
}

export default NewPreviewMode;