import "./styles.scss";
import React from 'react';
import { Row, Col } from "antd";
import Modal from 'shared/components/modal';
import Heading from "shared/components/heading";
import Note from "shared/composedComponents/note";
import useWindowSize from "shared/hooks/use-window-size";
// import Input from "shared/components/binput";

interface props {
    data: any
    show: boolean
    close: any
    setRefresh: any
}

const NotesDisplayModal: React.FC<props> = ({ show, close, data, setRefresh }) => {
    const {width} = useWindowSize()

    return (
        <>
            <Modal show={show} close={close} head={false} title={``}>
                <div className="notesDisplayModalContainer">
                    <Row className="notesDisplayModalSection">
                        <Col span={24}>
                            <Heading classes={["heading"]} text={<>Notes + Love Letters</>}/>
                        </Col>
                        <Col span={24}>
                        <Row justify="space-between" gutter={width > 450 ? [0, 40] : [0 , 20]}>
                            {
                                data?.notes?.map((note: any) => (
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                        <Note note={note} website={data?.website} setRefresh={setRefresh}/>
                                    </Col>
                                ))
                            }
                        </Row>
                            
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    )
}

export default NotesDisplayModal;