import "./styles.scss"
import classNames from 'classnames'
import React from 'react'

interface props{
    text?: any,
    type?: "help" | "error"
    styles?: any
}

const HelperText:React.FC<props> = ({text, type="help" ,styles}) => {
    return (
        <p className={classNames('helperTextContainer' , {errorText: type === "error"})} style={styles}>
            {text}
        </p>
    )
}

export default HelperText