import "./styles.scss";
import React from 'react'
import { Image } from "antd";
import Divider from 'shared/components/divider';
import Paragraph from "../paragraph";

const Engagement: React.FC = () => {
	return (
		<div className='engagementBoxContainer'>
			<div className="top">
				<div className='action'>
					<Image className='btn' src={`../assets/newIcons/chat.png`} preview={false} /> 0
				</div>
				<Divider type='vertical' />
				<div className='action'>
					<Image className='btn' src={`../assets/newIcons/like.png`} preview={false} /> 0
				</div>
			</div>
			<Divider />
			<div className="commentBox">
				<Paragraph classes={["name"]}>Katrill Jackson</Paragraph>
				<Paragraph classes={["comment"]}>Lorem ipsum dolor sit amet consectetur. Sit imperdiet at bibendum imperdiet at bibendum in vestibulum. Lectus mauris non in sed id ultrices vulputate nibh. Amet proin lectus tincidunt amet enim ultrices.</Paragraph>
				<Paragraph classes={["date"]}>Sunday, March 03</Paragraph>
			</div>
		</div>
	)
}

export default Engagement