import './styles.scss';
import React, { useEffect, useState } from "react";
import { Col, Row } from 'antd';
import { useStripe, useElements, PaymentElement, } from "@stripe/react-stripe-js";
import Notification from "shared/components/notification";
import Spinner from 'shared/components/spinner';
import { app } from 'shared/infra/config';
import Button from 'shared/components/bButton';

interface props {
	handlePayment: any
	data: any
}

export const CheckoutForm: React.FC<props> = ({ handlePayment, data }) => {
	const stripe: any = useStripe();
	const elements: any = useElements();
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret: any = new URLSearchParams(window.location.search).get(
			'payment_intent_client_secret'
		);

		const handlePaymentAsync = async () => {
			try {
				const { paymentIntent: { status } }: any = await stripe.retrievePaymentIntent(clientSecret);

				switch (status) {
					case 'succeeded':
						setIsLoading(true)
						await handlePayment();
						setIsLoading(false)
						break;
	
					case 'processing':
						setIsLoading(true)
						await handlePayment();
						setIsLoading(false)
						break;
	
					case 'requires_payment_method':
						Notification("error", 'Payment failed. Select another payment method.');
						break;
	
					default:
						Notification("error", 'Something went wrong. Please try again.');
						break;
				}
			} catch (error) {
				console.log("Something went wrong", error)
			}
		};
	
		handlePaymentAsync();
	}, [stripe]);

	const handleSubmit = async () => {
		if (!stripe || !elements) {
			return;
		}

		setIsLoading(true)
		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: `${app.APP_PUBLIC_URL}${data?.slug}?remove_add_explore=true`,
			},
		});

		if (error) {
			Notification("error", error.message)
		}

	};

	return (

		<Row>
			<Col span={24} className="stripeFormContainer">
				<PaymentElement />
				<br />
				<br />

				<Button
					type={!stripe ? "disabled" : "base3"}
					styles={{ cursor: !stripe ? "no-drop" : "pointer", textTransform: "capitalize" }}
					text={isLoading ? <Spinner /> : "Upgrade"}
					action={handleSubmit}
					classes={["w-100-p"]}
					disabled={!stripe}
				/>
			</Col>
		</Row>
	);
};