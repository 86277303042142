import "./styles.scss"
import React from 'react'
import { Link } from "react-router-dom";
import { Image } from 'antd';

interface props{
    getHelp?: boolean
    styles?: any
}

const Footer:React.FC<props> = ({styles, getHelp=false}) => {
    return (
        <div className="newFooter c-bg-w" style={{...styles}}>
            <Link to="/dashboard">
                <Image className="logo" src={"../assets/villie.svg"} alt="logo" preview={false} />
            </Link>
            <div className="rightSide">
                {getHelp && <p className="footerTab">Get Help</p>}
                <p className="footerTab"><a href="https://www.villie.com/privacy" target='_blank' rel="noopener noreferrer">Privacy Policy</a></p>
                <p className="footerTab"><a href="https://www.villie.com/terms" target='_blank' rel="noopener noreferrer">Terms</a></p>
                <p className="footerHelperText">© 2024 Villie. All rights reserved.</p>
            </div>
        </div>
    )
}

export default Footer