import "./styles.scss";
import React, { useEffect } from "react";
import classNames from 'classnames';
import { useState } from "react";
import { Alert, Row, Col, Image, Typography, Divider } from "antd";
import useWindowSize from "shared/hooks/use-window-size";
import CastingVoteModal from "shared/modals/castingVoteModal";
import { loadStripe } from "@stripe/stripe-js";
import { stripe } from "shared/infra/config"
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { getUser } from "modules/user/redux/userSlice";
import Notification from "shared/components/notification";
import { getGender, formatDate } from "shared/utils/helper";
import ChartSection from "../components/chartSection";

interface props {
    data?: any,
    setIsCreateGenderRevealGameModalVisible?: any,
    forEditPage?: boolean
    handleGirlVoting?: any,
	setRefresh?: any
}

const {Paragraph} = Typography

const GenderRevealGame:React.FC<props> = ({ data, setIsCreateGenderRevealGameModalVisible, forEditPage, setRefresh }) => {
	const stripeTestPromise = loadStripe(stripe.STRIPE_PUBLIC_KEY!);
	const user = useSelector(getUser)
	const createdAt = new Date(data?.game?.created_at)
	const launchAt = new Date('2022-11-01')
	const [votes, setVotes] = useState<any>([])
	const {width} = useWindowSize()
	const [voteInfo, setVoteInfo] = useState<any>(null)

	const [isCastingVoteModalVisible, setIsCastingVoteModalVisible] = useState(false)

	const [isVotersVisible, setIsVotersVisible] = useState(false)

	useEffect(() => {
		if(createdAt < launchAt){
			if(data?.game?.vote_counts){
				setVotes([...data?.game?.vote_counts])
			}
		}
		else{
			if(data?.game?.vote_counts){
				setVotes([{no_of_votes: 1, voter_first_name: "Kim", voter_last_name: "at Villie", boy_or_girl: getGender(data?.game?.contest?.name)}, ...data?.game?.vote_counts])
			}
		}
		
	}, [data?.game])

	const handleVoting = (voteFor: string) => {
		if (user?.id === data?.user_id) {
			return Notification("error", "You cannot vote on your own game")
		}

		if((data?.game?.contest_stage === "Pause Stage" || data?.game?.voting_ended) && !data?.game?.winning_vote_id) return 0;
		setIsCastingVoteModalVisible(true)
		setVoteInfo({voteFor: voteFor})
	}

	return (
		<>
		<Row className="grgContainer">
			<Col xs={24} sm={24} md={0} lg={0} xl={0} className="mobileImageBox">
				<Image className="mobileImage" src={"../assets/icons/grgIcon.svg"} preview={false}/>
			</Col>
			{
				data?.game?.baby_gender === null ?
				(data?.game?.contest_stage === "Pause Stage" || data?.game?.voting_ended) && !data?.game?.winning_vote_id &&
				<Col span={24} className="alertBoxContainer">
					<Alert type="error" 
						message={data?.game?.voting_ended? "Voting is now closed 🙁" : "Game is paused." }
						style={{margin: "0 0% 3% 0%"}}
						className="voteCloseAlert"
					/>
				</Col>
				:
				<Col span={24}>
					<div className="GenderAnnouncedBanner" >
						<div className="text" style={{color: data?.game?.baby_gender === 0 ? "#5263FF" : "#CC8DFF"}}>Congratulations! It’s a {data?.game?.baby_gender === 0 ? "boy" : "girl"} 🎉</div>
					</div>
				</Col>
			}
			{   !(width > 450) && forEditPage &&
				<Col span={24}>
					<p 
						className="editGameLink" 
						onClick={() => setIsCreateGenderRevealGameModalVisible(true)}
					>
						Edit Game
					</p>
				</Col>
			}
			<Col span={24}>
				<Row className="top" justify="space-between" align="middle">
					<Col xs={24} sm={24} md={20} lg={20} xl={20}>
						{<div>
							<Paragraph className="title">
								{data?.game?.name}
								{ 
								width > 450 && forEditPage && data?.game?.baby_gender === null &&
									<>&nbsp;&nbsp;
										<span
											className="editGameLink" 
											onClick={() => setIsCreateGenderRevealGameModalVisible(true)}
										>
											Edit Game
										</span>
									</>
								}
							</Paragraph>
							<Paragraph className="subtitle" style={{color: "red"}}>Ends: {formatDate(data?.game?.finish_date, 'dddd, MMMM D, YYYY')}</Paragraph>
							{data?.game?.baby_gender === null && <Paragraph className="subtitle">Select a gender to play and win:</Paragraph>}
						</div>}
					</Col>
					<Col xs={0} sm={0} md={4} lg={4} xl={4}>
						<Image src={"../assets/icons/grgIcon.svg"} preview={false}/>
					</Col>
				</Row>
				<Row className="middle" align="middle">
					<Col>
						{
							data?.game?.baby_gender === null ?
							<div className="buttons">
								<div className={classNames("vote-button", {'voteBtnDisabled': !!(data?.game?.voting_ended)}, "boy")} 
									onClick={() => handleVoting("boy")}
								>
									<img src={"../assets/blue-bear.svg"} alt="icon-btn" />
									<span style={{ color: "#5263FF" }}>It’s a Boy!</span>
								</div>
								<div className={classNames("vote-button", {'voteBtnDisabled': !!(data?.game?.voting_ended)}, "girl")} 
									onClick={() => handleVoting("girl")}
								>
									<img src={"../assets/pink-bear.svg"} alt="icon-btn" />
									<span style={{ color: "#CC8DFF" }}>It’s a Girl!</span>
								</div>
							</div>
							:
							data?.game?.winner[0]?.voter_first_name && 
							<div className="winnerCard" style={{border: `1px solid ${data?.game?.baby_gender === 1 ? "#CC8DFF" :  "#5263FF"}`}}>
                                <Paragraph className="title" style={{color: data?.game?.baby_gender === 1 ? "#CC8DFF" :  "#5263FF"}}>winner {data?.game?.winner[0]?.voter_first_name} {(data?.game?.winner[0]?.voter_last_name)?.substring(0,1)}.</Paragraph>
                                {/* <Paragraph className="subtitle">{data?.game?.winner[0]?.voter_first_name} {(data?.game?.winner[0]?.voter_last_name)?.substring(0,1)}.</Paragraph> */}
								<Paragraph className="subtitle">It’s a {data?.game?.baby_gender === 0 ? "boy" : "girl"}</Paragraph>
                            </div>
						}
					</Col>
					<Col className="right">
						<ChartSection data={data}/>
					</Col>
				</Row>
				{
					width > 450 &&
					<Row className="bottom" align="middle" justify="end" style={{marginTop: "25px"}}>
						<Col className="dividerBox">&nbsp;</Col>&nbsp;&nbsp;&nbsp;
						{!!data?.game?.prize_name && <Col><span className="title">Game Prize</span>&nbsp;&nbsp;&nbsp; <span className="subtitle">{data?.game?.prize_name}</span></Col>}
					</Row>
					
				}
				{
					!(width > 450) &&
					<Row className="bottom" align="middle" justify="end">
						{
							!!data?.game?.prize_name ?
							<>
								<Col span={24}><Divider plain>Game Prize</Divider></Col>
								<Col span={24}><span className="subtitle">{data?.game?.prize_name}</span></Col>
							</>
							:
							<Divider />
						}
					</Row>
				}
				
				<Row className="votersSection" justify="space-between" align="middle">
					<Col className="playersCount">{votes?.length} Players</Col>
					<Col className="playersLink"onClick={() => setIsVotersVisible(!isVotersVisible)}>{isVotersVisible? "Hide" : "Show"} all players</Col>
				</Row>

				<p className="countForMb">{votes?.length} Players</p>
				<div className={classNames("voters", {"isVotersVisible" : isVotersVisible})}>
					{
						votes?.map((v: any) => {
							return(
								<Row className="voter" justify="space-between" align="middle">
									<Col className="name">
										<div className="circle" style={{background: v?.boy_or_girl === 0 ? "#5263FF" : "#CC8DFF"}}>
											<img src={"../assets/white-bear.svg"}/>
										</div>
										{v?.voter_first_name} {v?.voter_last_name}
									</Col>
									<Col className="count">{v?.no_of_votes} vote</Col>
								</Row>
							)
						})
					}
				</div>
				

				
			</Col>
		</Row>

		<Elements stripe={stripeTestPromise}>
			<CastingVoteModal 
				show={isCastingVoteModalVisible} 
				close={() => setIsCastingVoteModalVisible(false)}
				data={data}
				voteInfo={voteInfo}
				refresh={setRefresh}
			/>
		</Elements>
		</>
	);
};


export default GenderRevealGame;
