export type ThemeColors = {
	[key: string]: string[];
};

export const themes: ThemeColors = {
	"#FBF7FF": ["#FBF7FF"],
	"#F0FFE4": ["#F0FFE4"],
	"#FFFDE6": ["#FFFDE6"],
	"linear-gradient(255deg, #BDB2FF 15.11%, #9BF6FF 31.06%, #CAFFBF 47.38%, #FDFFB6 61.04%, #FFD6A5 79.07%, #FFADAD 91.03%)":
		["#FEFFDF", "#E0FDDB", "#D9FCFF", "#FFE2E2", "#EEEBFF"],
	"linear-gradient(256deg, #D5BDAF 18.17%, #E3D5CA 40.84%, #F5EBE0 69.41%, #EDEDE9 93.57%)":
		["#EDEDE9", "#F5EBE0", "#E7E3E0", "#EDEDE9", "#F5EBE0"],
	"#CCD5AE": ["#FAEDCD", "#FEFAE0", "#E9EDC9"],
	"#FFB3C6": ["#FFE5EC", "#FF8FAB", "#FFB3C6"],
	"#C8EBF2": ["#0077B6", "#90E0EF", "#C8EBF2"],

	// legacy
	"linear-gradient(255deg, #BDB2FF 15.11%, #9BF6FF 31.06%, #CAFFBF 47.38%, #FDFFB6 61.04%, #FFD6A5 79.07%, #FFADAD 91.03%), #FAF7FE": ["#FEFFDF", "#E0FDDB", "#D9FCFF", "#FFE2E2", "#EEEBFF"],
	"linear-gradient(256deg, #D5BDAF 18.17%, #E3D5CA 40.84%, #F5EBE0 69.41%, #EDEDE9 93.57%), #F3FEE6": ["#EDEDE9", "#F5EBE0", "#E7E3E0", "#EDEDE9", "#F5EBE0"],
	"#00B4D8": ["#0077B6", "#90E0EF", "#C8EBF2"], 
	"#FF8FAB": [ "#FFE5EC", "#FF8FAB", "#FFB3C6"],
};

export type IntroSectionColor = {
	[key: string]: string;
};

  
export const introSectionColor : IntroSectionColor = {
	"#FBF7FF": "#FBF7FF",
	"#F0FFE4": "#F0FFE4",
	"#FFFDE6": "#FFFDE6",
	"linear-gradient(255deg, #BDB2FF 15.11%, #9BF6FF 31.06%, #CAFFBF 47.38%, #FDFFB6 61.04%, #FFD6A5 79.07%, #FFADAD 91.03%)": "linear-gradient(255deg, #BDB2FF 15.11%, #9BF6FF 31.06%, #CAFFBF 47.38%, #FDFFB6 61.04%, #FFD6A5 79.07%, #FFADAD 91.03%)",
	"linear-gradient(256deg, #D5BDAF 18.17%, #E3D5CA 40.84%, #F5EBE0 69.41%, #EDEDE9 93.57%)": "linear-gradient(256deg, #D5BDAF 18.17%, #E3D5CA 40.84%, #F5EBE0 69.41%, #EDEDE9 93.57%)",
	"#CCD5AE": "#CCD5AE",
	"#FFB3C6": "#FFB3C6",
	"#C8EBF2": "#C8EBF2",

	// legacy
	"linear-gradient(255deg, #BDB2FF 15.11%, #9BF6FF 31.06%, #CAFFBF 47.38%, #FDFFB6 61.04%, #FFD6A5 79.07%, #FFADAD 91.03%), #FAF7FE": "linear-gradient(255deg, #BDB2FF 15.11%, #9BF6FF 31.06%, #CAFFBF 47.38%, #FDFFB6 61.04%, #FFD6A5 79.07%, #FFADAD 91.03%)",
	"linear-gradient(256deg, #D5BDAF 18.17%, #E3D5CA 40.84%, #F5EBE0 69.41%, #EDEDE9 93.57%), #F3FEE6": "linear-gradient(256deg, #D5BDAF 18.17%, #E3D5CA 40.84%, #F5EBE0 69.41%, #EDEDE9 93.57%)",
	"#00B4D8": "#C8EBF2", 
	"#FF8FAB":  "#FFB3C6",
};

export type BtnThemeColors = {
	[key: string]: string;
};

export const btnThemes: BtnThemeColors = {
	"#FBF7FF": "#FFF16F",
	"#F0FFE4": "#FFF16F",
	"#FFFDE6": "#FFF16F",
	"linear-gradient(255deg, #BDB2FF 15.11%, #9BF6FF 31.06%, #CAFFBF 47.38%, #FDFFB6 61.04%, #FFD6A5 79.07%, #FFADAD 91.03%)":
		"#FFF16F",
	"linear-gradient(256deg, #D5BDAF 18.17%, #E3D5CA 40.84%, #F5EBE0 69.41%, #EDEDE9 93.57%)":
		"#D5BDAF",
	"#CCD5AE": "#D4A373",
	"#FF8FAB": "#FB6F92",
	"#00B4D8": "#03045E",
};
