import "./styles.scss";
import React from 'react';
import { Row, Col } from "antd";
import Modal from 'shared/components/modal';
import Heading from "shared/components/heading";
import useWindowSize from "shared/hooks/use-window-size";
import { getFirstNameAndLastName } from "shared/utils/helper";
import Event from "shared/composedComponents/event";

interface props {
    show: boolean
    close: any
    website: any
    events: any
    handleEditEvent: any
}

const ViewAllEventsModal: React.FC<props> = ({ show, close, website, events, handleEditEvent }) => {
    const { width } = useWindowSize()

    return (
        <>
            <Modal show={show} close={close} head={false} title={``} classes={["viewVillieUpdatesModal"]}>
                <div className="viewEventsModalContainer">
                    <Row className="viewEventsModalSection">
                        <Col span={24}>
                            <Heading
                                classes={["heading"]}
                                text={<>{getFirstNameAndLastName(website?.village_owner)?.first_name}’s <span className='accent'>Events</span></>}
                            />
                        </Col>
                        <Col span={24} className="eventsArea">
                            <Row justify="space-between" gutter={width > 450 ? [0, 20] : [0, 20]}>
                                {
                                    events?.map((event: any) => (
                                        <Col key={event?.id}>
                                            <Event event={event} handleEditEvent={handleEditEvent} website={website}/>
                                        </Col>
                                    ))
                                }
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    )
}

export default ViewAllEventsModal;