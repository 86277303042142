import "./styles.scss";
import React, { useEffect } from 'react'
import { Col, Row } from 'antd';
import Section from "shared/components/section";
import Heading from "shared/components/heading";
import GenderRevealGame from "../../games/genderRevealGame";
import { getTextColor } from "shared/utils/helper";

interface props{
    website: any
    setRefresh: any
    color: string
}

const GamesSection: React.FC<props> = ({website, setRefresh, color}) => {

    useEffect(() => {
        if (window.location.hash === '#games') {
            const element = document.getElementById('games');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    useEffect(() => {
        if (window.location.hash === '#games') {
            const element = document.getElementById('games');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <>
            <div id="games"></div>
             <Section backgroundColor={color}>
                <Row className='gamesSectionContainer'>
                    <Col span={24}>
                        <Heading text={<>Gender Reveal Game</>} styles={getTextColor(website?.website_color, color)}/>
                    </Col>
                    <Col className="gameBox">
                        <GenderRevealGame data={website} setRefresh={setRefresh}/>
                    </Col>
                </Row>
            </Section>
        </>
    )
}

export default GamesSection