import React, { useState } from 'react';
import { deletePhoto } from "modules/website/redux/operators";
import Notification from "shared/components/notification";
import { useDispatch } from "react-redux";
import { setWebsite } from "shared/infra/redux/global/global";
import Confirmation from "shared/components/confirmation";

interface props {
    show: boolean
    close: any
    photo: any
    data: any
}

const DeletePhoto: React.FC<props> = ({photo, show, close, data}) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const handleDelete = async () => {
        if(isLoading) return 0
        
        setIsLoading(true)
        const response = await deletePhoto(photo?.website_id, photo?.id)
        setIsLoading(false)

        if(response?.status === "success"){
            const updatedPhotos = data.photos.filter((p: any) => p.id !== photo.id);
            dispatch(setWebsite({website: { ...data, photos: updatedPhotos }}))

            Notification("success", "Photo deleted successfully")
            close()
        }
        else{
            Notification("error", response?.message)
        }
    }

    return (
        <>
            <Confirmation 
                show={show}
                close={close}
                heading="Are you sure you want to delete this?"
                subheading="Once it's deleted it's gone forever"
                okText="Yes Delete"
                okAction={handleDelete}
                isOkLoading={isLoading}
                cancelText="No, Do Not Delete"
            />
        </>
    )
}

export default DeletePhoto;