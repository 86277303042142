import './styles.scss';
import React, { useEffect, useState } from "react";
import { Col, Row, Checkbox, Input, Typography } from 'antd';
import { useStripe, useElements, PaymentElement, } from "@stripe/react-stripe-js";
import Notification from "shared/components/notification";
import Spinner from 'shared/components/spinner';
// import classNames from 'classnames';
import { calculateServiceFeeAndActualAmountFromFinalCost, calculateServiceFeeAndActualAmount } from 'shared/utils/helper';
import { app } from 'shared/infra/config';
import Button from 'shared/components/bButton';
import Tooltip from 'shared/components/tooltip';
import HelperText from "shared/components/helperText";

const { Paragraph } = Typography
interface props {
	handlePayment: any
	data: any
	nextStep: any
	donation: any
}

export const CheckoutForm: React.FC<props> = ({ donation, handlePayment, data }) => {
	const stripe: any = useStripe();
	const elements: any = useElements();

	const [isLoading, setIsLoading] = useState(false)
	const [checked, setChecked] = useState(false);
	const [donorName, setDonorName] = useState('');
	const [nameError, setNameError] = useState(false)
	const [agreementError, setAgreementError] = useState(false)

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret: any = new URLSearchParams(window.location.search).get(
			'payment_intent_client_secret'
		);

		const donor_first_name: any = new URLSearchParams(window.location.search).get(
			'donor_first_name'
		);
		const donor_last_name: any = new URLSearchParams(window.location.search).get(
			'donor_last_name'
		);
		const fund_type: any = new URLSearchParams(window.location.search).get(
			'fund_for'
		);

		const handlePaymentAsync = async () => {
			try {
				const { paymentIntent: { status, amount, id } }: any = await stripe.retrievePaymentIntent(clientSecret);
				const { serviceFee, actualAmount }: any = calculateServiceFeeAndActualAmountFromFinalCost(parseFloat(amount) / 100)

				switch (status) {
					case 'succeeded':
						setIsLoading(true)
						await handlePayment({
							donation_method: "stripe",
							total_paid: parseFloat(amount) / 100,
							donation_amount: actualAmount,
							service_fee: serviceFee,
							donation_id: id,
							fund_type,
							donor_first_name,
							donor_last_name,
							status: "success"
						});
						setIsLoading(false)
						break;
	
					case 'processing':
						setIsLoading(true)
						await handlePayment({
							donation_method: "stripe",
							total_paid: parseFloat(amount) / 100,
							donation_amount: actualAmount,
							service_fee: serviceFee,
							donation_id: id,
							fund_type,
							donor_first_name,
							donor_last_name,
							status: "pending"
						});
						setIsLoading(false)
						break;
	
					case 'requires_payment_method':
						Notification("error", 'Payment failed. Select another payment method.');
						break;
	
					default:
						Notification("error", 'Something went wrong. Please try again.');
						break;
				}
			} catch (error) {
				console.log("Something went wrong", error)
			}
		};
	
		handlePaymentAsync();
	}, [stripe]);

	const handleSubmit = async () => {
		if (!checked) {
			return setAgreementError(true)
			// return Notification("error", "Please accept agreement")
		}

		if (!donorName) {
			return setNameError(true)
		}
		const first_name = donorName.substring(0, donorName.indexOf(' '))
		const last_name = donorName.substring(donorName.indexOf(' ') + 1)

		if (!first_name || !last_name) {
			return setNameError(true)
		}

		if (!stripe || !elements) {
			return;
		}

		setIsLoading(true)
		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: `${app.APP_PUBLIC_URL}${data?.website?.slug}?donation=${donation}&fund_type=${data?.donationDetail?.type}&fund_for=${data?.donationDetail?.name}&donor_first_name=${first_name}&donor_last_name=${last_name}`,
			},
		});

		if (error) {
			Notification("error", error.message)
		}

	};

	const getBtnText = () => {
		switch (data?.donationDetail?.name) {
			case "Believe Diapers":
				return "Send Diapers"
			case "Wellness":
				return "Send Health"
			case "SquareBaby Baby Food":
				return "Send a Delivery"
			case "Zomee Breastfeeding Supplies":
				return "Send Breastfeeding Support"
			default:
				return "Donate Now"
		}
	}

	return (

		<Row>
			<Col span={24} className="stripeFormContainer">
				<Input
					className='stepInput'
					placeholder='First & last name'
					onChange={(e: any) => {
						if (nameError) {
							setNameError(false)
						}
						setDonorName(e?.target?.value)
					}}
				/>
				{
					nameError && <HelperText styles={{ marginTop: "-10px", marginBottom: "15px" }} type="error" text={"Please add your first & last name"} />
				}
				<PaymentElement />
				<br />
				<br />

				<Checkbox checked={checked} onChange={(e) => {
					setAgreementError(false)
					setChecked(e.target.checked)
				}}>
					By donating, I agree to receive updates and that all donations are non-refundable.
				</Checkbox>
				{
					agreementError ?
						<HelperText styles={{ marginTop: "5px" }} type="error" text={"Please accept agreement"} />
						:
						<>
							<br /><br />
						</>
				}
				<br />
				<Col span={24}>
					<Paragraph className="contributionAmountTitle">{data?.donationDetail?.type === "brand" ? "Gift Card Amount" : "Total contribution"}</Paragraph>
				</Col>

				<Col span={24}>
					<Paragraph className="contributionAmount">
						${(parseFloat(donation)).toFixed(2)}&nbsp;
						{/* ${(parseFloat(donation) + calculateServiceFee(donation)).toFixed(2)}&nbsp; */}
						<small className="small">

						</small>
						<Tooltip
							placement="bottom"
							content={[`$${calculateServiceFeeAndActualAmount(donation).actualAmount} ${data?.donationDetail?.type === "brand" ? "gift card" : "donation"} + $${calculateServiceFeeAndActualAmount(donation).serviceFee} fee`,]}>
							<img src="../assets/icons/tooltip.svg" />
						</Tooltip>
					</Paragraph>
				</Col>
				<Button
					type={!stripe ? "disabled" : "base3"}
					styles={{ cursor: !stripe ? "no-drop" : "pointer", textTransform: "capitalize" }}
					text={isLoading ? <Spinner /> : getBtnText()}
					action={handleSubmit}
					classes={["w-100-p"]}
					disabled={!stripe}
				/>
			</Col>
		</Row>
	);
};