import './styles.scss';
import React from 'react';
import classNames from 'classnames';

type props = {
    text: any;
    classes?: string[]
    styles?: any
};


const Heading: React.FC<props> = ({ text, classes=[], styles }) => {
    return (
        <p
        className={classNames("mainHeading", ...classes,)}
        style={{...styles}}
        >
            {text}
        </p>
    );
};

export default Heading;
