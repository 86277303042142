import React, { useState } from 'react';
import { deleteRegistry } from "modules/website/redux/operators";
import Notification from "shared/components/notification";
import { useDispatch } from "react-redux";
import { setWebsite } from "shared/infra/redux/global/global";
import Confirmation from "shared/components/confirmation";

interface props {
    show: boolean
    close: any
    registry: any
    data: any

}

const DeleteRegistry: React.FC<props> = ({registry, show, close, data}) => {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)

    const handleDelete = async () => {
        if(isLoading) return 0
        
        setIsLoading(true)
        const response = await deleteRegistry(registry?.website_id, registry?.id)
        setIsLoading(false)

        if(response?.status === "success"){
            const updatedRegistries = data.registries.filter((p: any) => p.id !== registry.id);
            dispatch(setWebsite({website: { ...data, registries: updatedRegistries }}))

            Notification("success", "Registry deleted successfully")
            close()
        }
        else{
            Notification("error", response?.message)
        }
    }

    return (
        <>
            <Confirmation 
                show={show}
                close={close}
                heading="Are you sure you want to delete this?"
                subheading="Once it's deleted it's gone forever"
                okText="Yes Delete"
                okAction={handleDelete}
                isOkLoading={isLoading}
                cancelText="No, Do Not Delete"
            />
        </>
    )
}

export default DeleteRegistry;