import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialAuthState from './states';
import type { AuthState } from "./states"
import { RootState } from 'shared/infra/redux/reducers';

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialAuthState,
    reducers: {
        setToken: (state: AuthState, action: PayloadAction<{accessToken: any}>) => {
            state.accessToken = action.payload.accessToken;
        },
        resetAuthState: (state: AuthState) => {
            state.accessToken = null
        },
    },
});


export const getAccessToken = (state: RootState) => state.auth.accessToken;

export const { setToken, resetAuthState } = authSlice.actions;

export default authSlice.reducer;
