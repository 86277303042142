import React from "react";
import { Col, Row } from 'antd';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./checkoutForm";
import {stripe} from "shared/infra/config";

const stripeTestPromise = loadStripe(stripe.STRIPE_PUBLIC_KEY!);

interface props {
	handlePayment: any
	data: any
	stripeClientSecret?: any
}

const Stripe:React.FC<props> = ({handlePayment, data, stripeClientSecret}) => {
	const options = {
		clientSecret: stripeClientSecret,
		// Fully customizable with appearance API.
		// appearance: {/*...*/},
	};
	return (
		<>	
			<Row>
				<Col span={24}>
					<Elements stripe={stripeTestPromise} options={options}>
						<CheckoutForm handlePayment={handlePayment} data={data}/>
					</Elements>
				</Col>
			</Row>
		</>
	);
};

export default Stripe;