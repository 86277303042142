import { getUser } from 'modules/user/redux/userSlice';
import { updateWebsite } from 'modules/website/redux/operators';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from "shared/components/bButton";
import Notification from 'shared/components/notification';
import Spinner from 'shared/components/spinner';
import Themes from "shared/components/themes";
import { getWebsite, setWebsite } from 'shared/infra/redux/global/global';
import { Mixpanel } from 'shared/services/mixpanel';
import { updateTheme } from 'shared/utils/helper';

interface props{
    // data: any
    // setData: any
    setClosingCollapse: any
}

const EditThemesForm:React.FC<props> = ({setClosingCollapse}) => {
    const dispatch = useDispatch()
    const data: any = useSelector(getWebsite)
    const user = useSelector(getUser)
    const [theme, setTheme] = useState(data?.website_color)
    const [isLoading, setIsLoading] = useState(false)

    const handleUpdateTheme = async () => {
        if (isLoading) return 0

        setIsLoading(true)
        const response = await updateWebsite(data.id, {website_color: theme})
        setIsLoading(false)

        if (response?.status === "success") {
            Mixpanel.track(
                "PARENT_update_theme",
                {
                    $name: user?.name,
                    $email: user?.email,
                    website_id: data?.id,
                    website_slug: data?.slug
                }
            )
            Notification("success", "Website update successfully")
            dispatch(setWebsite({website: {...data, website_color: theme}}))

            // setData({...data, website_color: theme})
            updateTheme(theme)
            setClosingCollapse(true)
            
        }
        else {
            setIsLoading(false)
            Notification("error", "There is an error. Try again later.")
        }
    }

    return(
        <>
            <Themes data={data} selectedTheme={theme} setTheme={setTheme}/>
            <Button 
                classes={["w-100-p", "m-t-3-p"]}
                type="base4"
                action={handleUpdateTheme}
                text={isLoading ? <Spinner/> : "Update Theme"}
            />
        </>
    )
}


export default EditThemesForm;