import { useState, useEffect } from 'react';

export default function useScrollPosition() {
    const [scrollPosition, setScrollPosition] = useState({
        scrollX: window.scrollX,
        scrollY: window.scrollY,
    });

    useEffect(() => {
        function handleScroll() {
            setScrollPosition({
                scrollX: window.scrollX,
                scrollY: window.scrollY,
            });
        }

        window.addEventListener('scroll', handleScroll, { passive: true });

        handleScroll(); // Initialize values

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return scrollPosition;
}
