import "./styles.scss";
import React, { useEffect } from 'react'
import { Col, Row } from 'antd';
import Section from "shared/components/section";
import GuessWhoGame from "../../games/guessWhoGame";

interface props{
    website: any
    setRefresh: any
}

const GuessWhoGameSection: React.FC<props> = ({website}) => {
    useEffect(() => {
        if (window.location.hash === '#guess-who') {
            const element = document.getElementById('guess-who');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <>
            <div id="guess-who"></div>
            <Section backgroundColor={"#D6DAFF"}>
                <Row className='guessWhoGameSectionContainer'>
                    <Col className="firstRow">
                        <img src="../assets/games/guess_who_title.svg"/>
                    </Col>
                    <Col className="gameBox">
                        <GuessWhoGame website={website}/>
                    </Col>
                </Row>
            </Section>

            
        </>
    )
}

export default GuessWhoGameSection