import "./styles.scss";
import React from 'react';
import { Row, Col, Divider } from 'antd';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import {paypal} from "shared/infra/config"
import Paypal from "./paypal";
import Stripe from "./stripe";
import { donate } from "modules/website/redux/operators";
import Notification from "shared/components/notification";
import { calculateServiceFee } from "shared/utils/helper";
import { Mixpanel } from "shared/services/mixpanel";
import { useSelector } from "react-redux";
import { getUser } from "modules/user/redux/userSlice";

// const {Paragraph} = Typography
interface props {
	donation: any
	nextStep: any
	stripeClientSecret?: any
	data?: any
}

const paypalScriptOptions = { "client-id": paypal.PAYPAL_KEY!, currency: "USD" };

const Payment: React.FC<props> = ({donation, nextStep, stripeClientSecret, data}) => {
	const fund_type: any = new URLSearchParams(window.location.search).get(
		'fund_for'
	);

	const user = useSelector(getUser)

	const handleDonation = async (payload: any) => {
        const response = await donate(data?.website?.id, payload)
		
		Mixpanel.track(
            `VILLAGER_${fund_type?.toLowerCase().replace(/\s+/g, '_')}_purchase`,
            {
                $name: user?.name,
                $email: user?.email,
				website_slug: data?.website?.slug,
                donation_amount: payload?.donation_amount
            }
        )

        if (response?.status === "success" || response?.message?.includes("Duplicate entry")) {
			nextStep(payload?.status === "success"? "Success!" : "Payment processing.")
            // Notification("success", data?.donationDetail?.type === "brand" ? "Success!" : "Money donated successsfully")
        }
        else {
            Notification("error", response?.message)
        }
	}

    return (
        <>
			<Row className="paymentContainer">
				{/* <Col span={24}>
					<Paragraph className="contributionAmountTitle">{data?.donationDetail?.type === "brand" ? "Gift Card Amount" : "Total contribution"}</Paragraph>
				</Col>

				<Col span={24}>
					<Paragraph className="contributionAmount">
						${(parseFloat(donation) + calculateServiceFee(donation)).toFixed(2)}&nbsp;
						<small className="small">
							${donation} {data?.donationDetail?.type === "brand" ? "gift card" : "donation"} + ${calculateServiceFee(donation).toFixed(2)} fee
						</small>
					</Paragraph>
				</Col> */}


				<Col span={24} className="paypalBox">
					<PayPalScriptProvider options={paypalScriptOptions}>
						<Paypal handlePayment={handleDonation} amount={parseFloat(donation) + calculateServiceFee(donation)} />
					</PayPalScriptProvider>
				</Col>
				<Col span={24}><Divider plain>or</Divider></Col>
				<Col span={24}>
					<Stripe 
						stripeClientSecret={stripeClientSecret} 
						handlePayment={handleDonation} 
						data={data}
						nextStep={nextStep}
						donation={parseFloat(donation) + calculateServiceFee(donation)}
						/>
				</Col>
				{/* <Col span={24}>
					<p className="cards">
						Cards Accepted
						<img src="../assets/mastercard.png" alt="Mastercard" width="35px" height="24px" />
						<img src="../assets/visa.png" alt="Visa" width="35px" height="24px" />
						<img src="../assets/discover.png" alt="Discover" width="35px" height="24px" />
					</p>
				</Col> */}
			</Row>
        </>
    )
}

export default Payment;