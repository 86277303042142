import React from 'react';
import {  Modal, Row, Col } from 'antd';
import { stopEventPropagation } from '../../utils/helper';
import classNames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import "./styles.scss";

interface props {
    show: boolean,
    close: () => void,
    width?: number | string |undefined,
    title?: any,
    children: any,
    classes?: any,
    maskClosable?: boolean
    destroyOnClose?: boolean
    styles?: any
    head?: boolean
    showCloseBtn?: boolean
}

const CustomModal: React.FC<props> = ({ show, close, title, width, children, showCloseBtn = true, classes, styles, maskClosable = true, destroyOnClose = false, head = true}) => {
    return (
        <Modal 
            open={show}
            title={""}
            onCancel={(e) => { stopEventPropagation(e); close() }} 
            footer={null} 
            width={width} 
            maskClosable={maskClosable}
            closable={head}
            className={classNames("modalContainer", classes)}
            style={{...styles}} 
            destroyOnClose={destroyOnClose}
        >
            {
                (!head && showCloseBtn) &&
                <div className='closeBtn'>
                    <CloseOutlined onClick={close} className='icon'/>
                </div>
            }
            <Row className='body'>
                {!!title && <Col span={24} className='title'>{title}</Col>}
                <Col span={24}>{children}</Col>
            </Row>
            
        </Modal>
    )
}

export default CustomModal;