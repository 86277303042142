import "./styles.scss"
import React from 'react';
import { Form, DatePicker  } from 'antd';
import classNames from "classnames";
import { InfoCircleFilled } from '@ant-design/icons';

interface props {
    label?: string,
    name: string,
    placeholder?: string,
    format?: string,
    allowClear?: boolean,
    styles?: any
    errorMessage?: string
    classes?: any
    tooltip?: any
    showTime?: boolean
    required?: boolean
    disabledDate?: any
    picker?: any
}

const CustomDatePicker: React.FC<props> = ({label, name, placeholder = "", picker, classes=[], required, errorMessage, format, allowClear, styles, showTime, disabledDate=null, tooltip=null}) => {
    return(
        <Form.Item 
            label={label}
            name={name}
            className="newDatepickerContainer"
            tooltip={!!tooltip && {
                title: tooltip,
                icon: <InfoCircleFilled style={{color: "#463649"}}/>,
            }}
            rules={[{ required: required, message: errorMessage ? errorMessage : 'This is required' }]}
            >
            <DatePicker 
                className={classNames('datepicker', ...classes)}
                disabledDate={(date) => !!disabledDate && disabledDate(date)} 
                placeholder={placeholder}
                allowClear={allowClear}
                picker={picker}
                format={format}
                style={{...styles}}
                showTime={showTime}
            />
        </Form.Item>
    );
}

export default CustomDatePicker;