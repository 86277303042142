import "./styles.scss";
import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import Section from "shared/components/section";
import Heading from "shared/components/heading";
import Contribution from "shared/composedComponents/contribution";
import Products from "shared/composedComponents/products";
import ContributionModal from "../../modals/contributionModal";
// import useWindowSize from "shared/hooks/use-window-size";
// import { getFirstNameAndLastName } from "shared/utils/helper";
import { Mixpanel } from "shared/services/mixpanel";
import { getTextColor } from "shared/utils/helper";

interface props {
	website?: any;
	setRefresh: any;
	color?: string;
}

const ContributionSection: React.FC<props> = ({
	website,
	setRefresh,
	color,
}) => {
	// const {width} = useWindowSize()
	const [isContributionModalVisible, setIsContributionModalVisible] =
		useState(false);
	const [contributionType, setContributionType] = useState({});

	const clientSecret: any = new URLSearchParams(window.location.search).get(
		"payment_intent_client_secret"
	);

	const remove_add_explore: any = new URLSearchParams(
		window.location.search
	).get("remove_add_explore");

	useEffect(() => {
		if (!!clientSecret && !remove_add_explore) {
			setIsContributionModalVisible(true);
		}
	}, [clientSecret]);

	return (
		<>
			<div id="gifts"></div>
			<Section backgroundColor={color}>
				<Row className="section2" justify="space-between">
					<Col span={24}>
						{/* <Heading text={<>Gift {getFirstNameAndLastName(website?.village_owner).first_name} with the Essentials</>}/> */}
						<Heading
							styles={getTextColor(website?.website_color, color)}
							text={
								<>
									Be the Village Send Your{" "}
									<span
										className={"spanText"}
										style={getTextColor(website?.website_color, color) || {undefined}}
									>
										Support
									</span>
								</>
							}
						/>
					</Col>
					{!!website?.receive_fund && (
						<Col
							xs={24}
							sm={24}
							md={12}
							lg={12}
							xl={12}
							className="leftSide"
						>
							<Contribution
								website={website}
								action={(args: any) => {
									Mixpanel.track(
										`VILLAGER_${args?.name
											?.toLowerCase()
											.replace(/\s+/g, "_")}_explore`,
										{
											website_slug: website?.slug,
										}
									);
									setContributionType(args);
									setIsContributionModalVisible(true);
								}}
								color = {color}
								/>
						</Col>
					)}
					<Col
						xs={24}
						sm={24}
						md={9}
						lg={9}
						xl={9}
						className="rightSide"
						id="brands-gifts"
					>
						<div className="top">
							<Products
								website={website}
								setRefresh={setRefresh}
								action={(args: any) => {
									Mixpanel.track(
										`VILLAGER_${args?.name
											?.toLowerCase()
											.replace(/\s+/g, "_")}_explore`,
										{
											website_slug: website?.slug,
										}
									);
									setContributionType(args);
									setIsContributionModalVisible(true);
								}}
								color = {color}
							/>
						</div>
						<div className="bottom"></div>
					</Col>
				</Row>
			</Section>

			<ContributionModal
				website={website}
				show={isContributionModalVisible}
				close={() => {
					setIsContributionModalVisible(false);
				}}
				contributionType={contributionType}
			/>
		</>
	);
};

export default ContributionSection;
