import "./styles.scss"
import React from 'react'
import Button from "shared/components/bButton";
import CParagraph from "shared/components/paragraph";
import { Image, Typography } from "antd";
import useWindowSize from "shared/hooks/use-window-size";
import classNames from "classnames";
import { getMainPhoto } from "shared/utils/helper";

const { Paragraph } = Typography

interface props {
    data: any
    forThemes?: boolean
    forDashboard?: boolean
}

const Preview: React.FC<props> = ({ data , forThemes = false, forDashboard = false }) => {
    const { width } = useWindowSize()
    return (
        <div className='preview' style={{background: data?.website_color}}>

            <div className={classNames("navbar", {"navbarForThemes" : forThemes}, {"navbarForDashboard" : forDashboard})}>
                <Image className="logo" src={"../assets/villie.svg"} alt="logo" preview={false} />

                {(forThemes || forDashboard) ? <div className="loginBtn">Login</div>
                :<img className="profileImage" src="../assets/newIcons/profile.svg" alt="Profile" />
            }
            </div>

            <div className={classNames("previewHero", {"previewHeroForTheme" : forThemes}, {"previewHeroForDashboard": forDashboard})}>
                <div className="previewLeftSide">
                    <CParagraph classes={["headline"]}>{!!data?.title ? data?.title : <>Welcome to Our <span style={{ color: "#CC8DFF" }}>Villie</span></>}</CParagraph>
                    <Button classes={["jorBtn"]} type={"base3"} text="JOIN THIS VILLAGE"
                    />
                    <Paragraph ellipsis={
                        {
                            rows: width > 450 ? 2 : 4,
                            expandable: true,
                            symbol: "MORE...",
                        }
                    } className="discription">{data?.description}</Paragraph>
                </div>
                <div className="rightSide">
                    <Image className="image"
                        src={getMainPhoto(data?.photos)?.img_url || `../assets/placeholders/villie/image${data?.slug?.length % 3 || 1}.png`}
                        alt="villie-image" preview={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default Preview