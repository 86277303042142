import './styles.scss';
import React from 'react';
import classNames from 'classnames';
import { Col, Row } from 'antd';
import Label from 'shared/Label';
import Paragraph from 'shared/components/paragraph';
import Button from 'shared/components/bButton';
import { getFirstNameAndLastName } from 'shared/utils/helper';
import { getTextColor } from 'shared/utils/helper';

type props = {
    classes?: string[]
    action?: any
    website: any
    color?: string;
};


const Contribution: React.FC<props> = ({classes=[], action, website, color}) => {
    return (
        <Row 
            className={classNames("contributionContainer", ...classes,)}
        >
            <Col span={24}>
                <Label styles={getTextColor(website?.website_color, color) || {undefined}} text="Donate to fund"/>
            </Col>
            <Col span={24} className='block'>                
                <Paragraph classes={["title"]}>{!!website?.fund_type? (website?.fund_type) : `Support ${getFirstNameAndLastName(website?.village_owner)?.first_name}'s Fund`}</Paragraph>
                <Paragraph classes={["helper"]}>It takes a village!</Paragraph>
                <Button 
                    type='theme' 
                    text={"Contribute"} 
                    action={() => action({type: "fund", name: website?.fund_type})}
                    classes={["contributionBtn"]}
                />
            </Col>
        </Row>
    );
};

export default Contribution;
