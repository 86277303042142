import { WebsiteService } from "../services/http/website"
import { OtherService } from "../services/http/other"
import { GameService } from "../services/http/game"
// import { setLastPasswordEnter } from "./lastPasswordEnterSlice"

export const createWebsite = async (data: any) => {
    try {
        const response = await WebsiteService.createWebsite(data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const getUserWebsites = async () => {
    try {
        const response = await WebsiteService.getUserWebsites()

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const findWebsite = async (slug: any) => {
    try {
        const response = await WebsiteService.findWebsite(slug)
        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const verifyVisitorPassword = async (websiteId: string, data: any) => {
    try {
        const response = await WebsiteService.verifyVisitorPassword(websiteId, data)
        
        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const findWebsiteByUser = async (userId: any) => {
    try {
        const response = await WebsiteService.findWebsiteByUser(userId)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const updateWebsite = async (websiteId: any, data: any) => {
    try {
        const response = await WebsiteService.updateWebsite(websiteId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const addNewUpdate = async (websiteId: string, data: any) => {
    try {
        const response = await WebsiteService.addNewUpdate(websiteId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const editUpdate = async (websiteId: string, updateId: string, data: any) => {
    try {
        const response = await WebsiteService.editUpdate(websiteId, updateId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const deleteUpdate = async (websiteId: string, updateId: string) => {
    try {
        const response = await WebsiteService.deleteUpdate(websiteId, updateId)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const deleteGuest = async (websiteId: string, guestId: string) => {
    try {
        const response = await WebsiteService.deleteGuest(websiteId, guestId)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const deletePhoto = async (websiteId: string, photoId: string) => {
    try {
        const response = await WebsiteService.deletePhoto(websiteId, photoId)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const setMainImage  = async (websiteId: string, photoId: string) => {
    try {
        const response = await WebsiteService.setMainImage(websiteId, photoId)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const addNewRegistry = async (websiteId: string, data: any) => {
    try {
        const response = await WebsiteService.addNewRegistry(websiteId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const updateRegistry = async (websiteId: string, registryId: string, data: any) => {
    try {
        const response = await WebsiteService.updateRegistry(websiteId, registryId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}


export const deleteRegistry = async (websiteId: string, registryId: string) => {
    try {
        const response = await WebsiteService.deleteRegistry(websiteId, registryId)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const addNewEvent = async (websiteId: string, data: any) => {
    try {
        const response = await WebsiteService.addNewEvent(websiteId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const editEvent = async (websiteId: string, milestoneId: string, data: any) => {
    try {
        const response = await WebsiteService.editEvent(websiteId, milestoneId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const deleteEvent = async (websiteId: string, milestoneId: string) => {
    try {
        const response = await WebsiteService.deleteEvent(websiteId, milestoneId)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const getEventsByWebsiteSlug = async (websiteId: string, slug: string) => {
    try {
        const response = await WebsiteService.getEventsByWebsiteSlug(websiteId, slug)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const likeAnEvent = async (websiteId: string, milestoneId: string, data: any) => {
    try {
        const response = await WebsiteService.likeAnEvent(websiteId, milestoneId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const commentOnEvent = async (websiteId: string, milestoneId: string, data: any) => {
    try {
        const response = await WebsiteService.commentOnEvent(websiteId, milestoneId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const addNote = async (websiteId: string, data: any) => {
    try {
        const response = await WebsiteService.addNote(websiteId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const deleteNote = async (websiteId: string, noteId: any) => {
    try {
        const response = await WebsiteService.deleteNote(websiteId, noteId)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}


export const getNotesByWebsiteSlug = async (websiteId: string, slug: string) => {
    try {
        const response = await WebsiteService.getNotesByWebsiteSlug(websiteId, slug)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const donate = async (websiteId: string, data: any) => {
    try {
        const response = await WebsiteService.addDonation(websiteId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const getPaymentSecret = async (websiteId: string, data: any) => {
    try {
        const response = await WebsiteService.createPaymentSecret(websiteId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const uploadImagesToGallery = async (websiteId: string, data: any) => {
    try {
        const response = await WebsiteService.uploadImagesToGallery(websiteId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const addGuest = async (websiteId: string, data: any) => {
    try {
        const response = await WebsiteService.addGuest(websiteId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const getWebsiteSetting = async (websiteId: string, key: any) => {
    try {
        const response = await WebsiteService.getWebsiteSetting(websiteId, key)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const addWebsiteSetting = async (websiteId: string, data: any) => {
    try {
        const response = await WebsiteService.addWebsiteSetting(websiteId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const updateWebsiteSetting = async (websiteId: string, data: any) => {
    try {
        const response = await WebsiteService.updateWebsiteSetting(websiteId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const customizeWebsiteUrl = async (websiteId: any, data: any) => {
    try {
        const response = await WebsiteService.customizeWebsiteUrl(websiteId, data)

        return {status: "success", data: response?.data?.data, message: response?.data?.message}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const getCityAndState = async (zip: any) => {
    try {
        const response = await OtherService.getCityAndState(zip)

        return {status: "success", data: response?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const createGame = async (data: any) => {
    try {
        const response = await GameService.createGame(data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const createGuessWhoGame = async (data: any) => {
    try {
        const response = await GameService.createGuessWhoGame(data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const answerGuessWhoGame = async (data: any) => {
    try {
        const response = await GameService.answerGuessWhoGame(data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const castVoteWithDonation = async (data: any) => {
    try {
        const response = await GameService.castVoteWithDonation(data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const castFreeVote = async (data: any) => {
    try {
        const response = await GameService.castFreeVote(data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const updateGame = async (gameId: string, data: any) => {
    try {
        const response = await GameService.updateGame(gameId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const endGame = async (gameId: string, data: any) => {
    try {
        const response = await GameService.endGame(gameId, data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}
