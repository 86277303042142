import './app.css';
import {Suspense, lazy, useEffect} from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import PageTracker from 'shared/infra/http/pageTracker';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'modules/user/redux/userSlice';
import { Mixpanel } from 'shared/services/mixpanel';
import Spinner from 'shared/components/spinner';

import NewPreviewMode from 'pages/newPreviewMode';
import Edit from 'pages/edit';
import NewDashboard from 'pages/newDashboard';
import NewUpgrade from 'pages/newUpgradeContainer';
import ProtectedRoute from "shared/infra/http/protectedRoutes";
import { setUpgradeModalVisible } from 'shared/infra/redux/global/global';
import { getQueryParam } from 'shared/utils/helper';
import { PostHog } from 'shared/services/posthog';
const CreateNewPassword = lazy(() => import('pages/createNewPassword'));
const WebflowPredictor = lazy(() => import('pages/webflowPredictor'));
const NotFound = lazy(() => import('pages/notFound'));
const HardReload = lazy(() => import('pages/hardReload'));
const Login = lazy(() => import('pages/login'));
const Signup = lazy(() => import('pages/signup'));

const App = () => {
	const dispatch = useDispatch();
	const user = useSelector(getUser);
	const upgrade = getQueryParam('upgrade')

	useEffect(() => {
		Mixpanel.initialize()
		PostHog.initialize()
	}, [])

	useEffect(() => {
		if (!!user) {
			Mixpanel.setIdentity(user?.id)
			PostHog.setIdentity(user?.id, {
				name: user?.name,
				email: user?.email,
			});
		}
	}, [user])

	useEffect(() => {
		if(!!upgrade){
			dispatch(setUpgradeModalVisible({isUpdgradeModalVisible: true}))
		}
	}, [upgrade])
	return (
		<>
			<BrowserRouter>
				<PageTracker />
				<Routes>
					<Route path="/login" element={
						<Suspense fallback={<Spinner fullPage />}>
							<Login />
						</Suspense>
					} />
					<Route path="/" element={
						<Suspense fallback={<Spinner fullPage />}>
							<Login />
						</Suspense>
					} />
					<Route path="/create-new-password" element={
						<Suspense fallback={<Spinner fullPage />}>
							<CreateNewPassword />
						</Suspense>
					} />
					<Route path="/signup" element={
						<Suspense fallback={<Spinner fullPage />}>
							<Signup />
						</Suspense>
					} />

					<Route path="/edit/:editType" element={
						<ProtectedRoute><Edit/></ProtectedRoute>
					} />

					<Route path="/dashboard" element={
						<ProtectedRoute><NewDashboard/></ProtectedRoute>
					} />

					<Route path="/upgrade" element={
						<ProtectedRoute><NewUpgrade/></ProtectedRoute>
					} />
					
					<Route path="/webflow/predictor" element={
						<Suspense fallback={<Spinner fullPage />}>
							<WebflowPredictor />
						</Suspense>
					} />

					<Route path="/not-found" element={
						<Suspense fallback={<Spinner fullPage />}>
							<NotFound />
						</Suspense>
					} />

					<Route path="/hard-reload" element={
						<Suspense fallback={<Spinner fullPage />}>
							<HardReload />
						</Suspense>
					} />

					<Route path="/:slug" element={
						<Suspense fallback={<Spinner fullPage />}>
							<NewPreviewMode />
						</Suspense>
					} />

					<Route path="/*" element={
						<Suspense fallback={<Spinner fullPage />}>
							<NotFound />
						</Suspense>
					} />
				</Routes>
			</BrowserRouter>
		</>
	);
}

export default App;

