export type SidebarComponents =
    "noData" | "myWallet" | "credits" | "settings" | "buyCredits"

export type DynamicDrawerState = {
    showSidebar: boolean
    sidebarComponent: SidebarComponents
}

const initialState: DynamicDrawerState = {
    showSidebar: false,
    sidebarComponent: "noData"
}

export default initialState