import React from 'react';
import Modal from 'shared/components/modal';
import { Row, Col, Table } from 'antd';

interface props {
    show: boolean
    close: any
    data: any
}

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (_: any, record: any) => <>{record?.voter_first_name} {record?.voter_last_name}</>,
    },
];

const ViewVotersModal: React.FC<props> = ({ show, close, data }) => {
    return (
        <>
            <Modal show={show} close={close} head={false} title="Voters list">
                <Row className='addMediaLinkModalContainer'>
                    <Col span={24}>
                        <Table columns={columns} dataSource={data} pagination={false}/>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default ViewVotersModal;