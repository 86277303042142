import './styles.scss';
import React from 'react';
import { Typography } from 'antd';
import classNames from 'classnames';

type props = {
    children: React.ReactNode;
    classes?: string[]
};

const {Paragraph: ANTParagraph} = Typography

const Paragraph: React.FC<props> = ({ children, classes=[] }) => {
    return (
        <ANTParagraph 
            className={classNames(...classes,)}
        >
            {children}
        </ANTParagraph>
    );
};

export default Paragraph;
