import "./styles.scss"
import React from 'react'
import CParagraph from "shared/components/paragraph";
import { formatDate, getFirstNameAndLastName } from "shared/utils/helper";

interface props{
    website: any
}

const PersonalInfoWidget:React.FC<props> = ({website}) => {
    return (
        <div className="personalInfoWidgeContainer">
            <div>
                <CParagraph classes={["personalInfoTitle"]}>{`${getFirstNameAndLastName(website?.village_owner).first_name}${!!website?.partner_name ? ` + ${getFirstNameAndLastName(website?.partner_name)?.first_name}` : ''}`}</CParagraph>
                <CParagraph classes={["personalInfoPragraph"]}>{website?.city}, {website?.country}</CParagraph>
            </div>
            <div>
                <CParagraph classes={["personalInfoTitle"]}>{new Date(website?.due_date_or_dob) > new Date(website?.created_at) ? "Due Date" : "Birthdate"}:</CParagraph>
                <CParagraph classes={["personalInfoPragraph"]}>{formatDate(website?.due_date_or_dob)}</CParagraph>
            </div>
        </div>
    )
}

export default PersonalInfoWidget