import React from 'react';
import { Spin } from 'antd';
import "./styles.scss";
import classNames from 'classnames';

interface props {
    fullPage?: boolean
    section?: boolean
    styles?: any
    classes?: any
}

const Spinner: React.FC<props> = ({ fullPage = false, section = false, styles= {}, classes=[] }) => {
    return (
        <>
            {
                fullPage ?
                    <div className={classNames("spinnerForFullPage", ...classes,)}>
                        <Spin style={{...styles}}/>
                    </div>
                    :
                    section ?
                        <div className={classNames("spinnerForSection", ...classes,)}>
                            <Spin style={{...styles}}/>
                        </div>
                        :
                        <div className={classNames("spinner", ...classes,)}>
                            <Spin style={{...styles}}/>
                        </div>
            }

        </>
    )
}


export default Spinner;