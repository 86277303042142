import React from 'react';
import EditContainer from 'modules/website/pages/edit';

const Edit: React.FC = () => {

    return(
        <EditContainer />
    );
}

export default Edit;