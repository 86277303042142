import "./styles.scss"
import { getPaymentSecret, updateWebsite } from 'modules/website/redux/operators'
import React, { useEffect, useState } from 'react'
import Spinner from 'shared/components/spinner'
import { Mixpanel } from 'shared/services/mixpanel'
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import {paypal} from "shared/infra/config"
import Paypal from "./paypal";
import { calculateServiceFee } from 'shared/utils/helper';
import { Divider } from "antd"
import Stripe from "./stripe";
import { useSelector } from "react-redux"
import { getUser } from "modules/user/redux/userSlice"
import Notification from "shared/components/notification"

const paypalScriptOptions = { "client-id": paypal.PAYPAL_KEY!, currency: "USD" };

interface props{
    website: any
    donation: {amount: any, description: string}
    nextStep: any
}

const Payments: React.FC<props> = ({website, donation, nextStep}) => {
    const user = useSelector(getUser)
    const [isLoading, setIsLoading] = useState(false)
    const [stripeClientSecrete, setStripeClientSecret] = useState(null)

    const handleSecretGeneration = async () => {
        setIsLoading(true)
        const response = await getPaymentSecret(website?.id, { amount: donation?.amount, description: `${donation?.description}` })
        
        Mixpanel.track(
            `${donation?.description}`,
            {website_slug: website?.slug,}
        )

        setIsLoading(false)
        setStripeClientSecret(response?.data?.client_secret)
    }

    const handlePayment = async () => {
        const response = await updateWebsite(website.id, {
            remove_ads_explore: true,
            remove_ads_explore_upgrade: true,
        })
		

        if (response?.status === "success") {
            Mixpanel.track(
                `Parent_remove_ads_explore`,
                {
                    $name: user?.name,
                    $email: user?.email,
                    website_slug: website?.slug,
                }
            )
            nextStep()
            Notification("success", "Ads remove successsfully")
        }
        else {
            Notification("error", response?.message)
        }
	}

    useEffect(() => {
        if(!!donation?.amount && !stripeClientSecrete) handleSecretGeneration()
    }, [donation])

    return (
        <>
            {
                isLoading ?
                <Spinner section/>
                :
                <div className='paymentsContainer'>
                    <div>
                        <PayPalScriptProvider options={paypalScriptOptions}>
						    <Paypal handlePayment={handlePayment} amount={parseFloat(donation?.amount) + calculateServiceFee(donation?.amount)} />
					    </PayPalScriptProvider>
                    </div>
                    <div><Divider plain>or</Divider></div>
                    <div>
                        {
                            !!stripeClientSecrete &&
                                <Stripe 
                                    stripeClientSecret={stripeClientSecrete} 
                                    handlePayment={handlePayment} 
                                    data={website}
                                />
                        }
                    </div>                
                </div>
            }
        </>
    )
}

export default Payments