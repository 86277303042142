import "./styles.scss";
import React, { useState } from 'react';
import { Row, Col, Image, Form } from "antd";
import Modal from 'shared/components/modal';
import Paragraph from "shared/components/paragraph";
import Input from "shared/components/binput";
import Button from "shared/components/bButton";
import TextArea from "shared/components/btextArea";
import Engagement from "shared/components/engagement";
// import useWindowSize from "shared/hooks/use-window-size";

interface props {
    show: boolean
    close: any
}

const ViewEventDetailsModal: React.FC<props> = ({ show, close }) => {
    // const {width} = useWindowSize()
    const [addComment, setAddComment] = useState(false)

    const handleClose = () => {
        setAddComment(false)
        close()
    }
    return (
        <>
            <Modal show={show} close={handleClose} head={false} title={``} classes={["viewEventDetailModal"]}>
                <Row className="viewEventDetailModalContainer" gutter={[24, 0]}>

                    <>
                        {
                            !addComment ?
                                <Col span={24}>
                                    <div className="topSide">
                                        <div className="imageBox">
                                            <div className="eventInfoBox">
                                                <div>
                                                    <Paragraph classes={["title"]}>Baby shower</Paragraph>
                                                    <Paragraph classes={["pragraph"]}>Little Rock, AR</Paragraph>
                                                </div>
                                                <div>
                                                    <Paragraph classes={["title"]}>Event Date:</Paragraph>
                                                    <Paragraph classes={["pragraph"]}>Jan 3, 2027</Paragraph>
                                                </div>
                                            </div>
                                            <Image className="image"
                                                src={"https://cdn.pixabay.com/photo/2021/07/09/02/29/family-6398107_1280.jpg"}
                                                alt="logo" preview={false}
                                            />
                                        </div>
                                        <div className="bottomSide">
                                            <div className="engagementBox">
                                                <Engagement />
                                            </div>
                                            <Button
                                                type="base3"
                                                text={"Leave a comment"}
                                                action={() => setAddComment(true)}
                                                classes={["commentBtn"]}
                                            />
                                        </div>

                                    </div>
                                </Col>
                                :
                                <>

                                    <Col>
                                        <div className="leftSide">
                                            <Paragraph classes={["headline"]}>
                                                <Image
                                                    onClick={() => setAddComment(false)}
                                                    className="backBtn" src={`../assets/newIcons/go-back.svg`}
                                                    preview={false}
                                                />
                                                Leave a Comment
                                            </Paragraph>
                                            <div className="eventInfoBox">
                                                <div>
                                                    <Paragraph classes={["title"]}>Baby shower</Paragraph>
                                                    <Paragraph classes={["pragraph"]}>Little Rock, AR</Paragraph>
                                                </div>
                                                <div>
                                                    <Paragraph classes={["title"]}>Event Date:</Paragraph>
                                                    <Paragraph classes={["pragraph"]}>Jan 3, 2027</Paragraph>
                                                </div>
                                            </div>
                                            <Image className="image"
                                                src={"https://cdn.pixabay.com/photo/2021/07/09/02/29/family-6398107_1280.jpg"}
                                                alt="logo" preview={false}
                                            />
                                        </div>
                                    </Col>
                                    <Col className="rightSide">
                                        <Form
                                            name="followOurVillieForm"
                                            // onFinish={addGuestInfo}
                                            autoComplete="off"
                                            // form={form}
                                            layout="vertical"
                                        >
                                            <Input
                                                label="Your Name"
                                                name={"name"}
                                            />
                                            <Input
                                                label="Email address"
                                                name={"email"}
                                            />
                                            <TextArea
                                                label="your Comment"
                                                name="comment"
                                            />
                                            <Button
                                                type="base3"
                                                text={"Post your comment"}
                                                classes={["submitBtn"]}
                                            />
                                        </Form>
                                    </Col>
                                </>
                        }
                    </>

                </Row>
            </Modal>
        </>
    )
}

export default ViewEventDetailsModal;