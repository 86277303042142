import './styles.scss';
import Input from 'shared/components/binput';
import { Form } from 'antd';

interface QuestionBoxProps {
    data: string[]; // Array of questions or parent names
    selectedItem: any;
    setSelectedItem: React.Dispatch<any>;
    form: any;
    formRef: React.RefObject<HTMLDivElement>;
    saveItem: (index: number, value: string) => void; // Function to save edited item
    inputNames: string[]; // Name of the input fields for validation
}

const QuestionBox: React.FC<QuestionBoxProps> = ({
    data,
    selectedItem,
    setSelectedItem,
    form,
    formRef,
    saveItem,
    inputNames,
}) => {
    const focusInput = (index: number) => {
        setTimeout(() => {
            const inputInstance = form.getFieldInstance(inputNames[index]);
            if (inputInstance) {
                inputInstance.focus();
            }
        }, 0);
    };

    const handleSave = (index: number, event: any) => {
        if (event.target.value.trim().length !== 0) {
            saveItem(index, event.target.value); // Save the updated value
        }

        setSelectedItem({ id: null, question: null }); // Deselect the input after saving
    };

    return (
        <div className="questionsBoxContainer">
            {data.map((item, index) => {
                return (
                    <div
                        className="questionBox"
                        key={index}
                        ref={selectedItem.id === index ? formRef : null}
                    >
                        {selectedItem.id === index ? (
                            <Form form={form} style={{ flex: '1' }}>
                                <div className="questionEditInput">
                                    <Input
                                        inLine
                                        name={inputNames[index]}
                                        onBlur={(e) => handleSave(index, e)}
                                    />
                                </div>
                            </Form>
                        ) : (
                            <p className="question">{item}</p>
                        )}

                        {selectedItem?.id !== index && (
                            <img
                                className="editIcon"
                                alt="edit"
                                src={`../assets/newIcons/pencil.svg`}
                                onClick={() => {
                                    if (selectedItem.id !== null) {
                                        saveItem(index, item);
                                    }
                                    setSelectedItem({ id: index, question: item });
                                    focusInput(index);
                                }}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default QuestionBox;