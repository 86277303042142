import './styles.scss';
import React from 'react';
import {
    WhatsappShareButton,
    FacebookShareButton,
    FacebookMessengerShareButton,
    // TwitterShareButton,
    WhatsappIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    // XIcon,
} from 'react-share';
import { Mixpanel } from 'shared/services/mixpanel';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'modules/user/redux/userSlice';
import { app } from 'shared/infra/config';
import Notification from 'shared/components/notification';
import { capitalize, isChecklistTaskCompleted, updateChecklistByInfoAndStringify } from 'shared/utils/helper';
import { addWebsiteSetting } from 'modules/website/redux/operators';
import { getWebsiteSettings, setWebsiteSettingsRefresh } from 'shared/infra/redux/global/global';
import { PostHog } from 'shared/services/posthog';

interface props {
    data: any;
    shareIcon?: 'black' | 'white';
}

const Share: React.FC<props> = ({ data, shareIcon = 'black' }) => {
    const user = useSelector(getUser);
    const dispatch = useDispatch()
    const checklist = useSelector(getWebsiteSettings)?.checklist

    const updateChecklistTask = async () => {
        if (!isChecklistTaskCompleted(checklist, "Share Your Baby Page")) {
            const websiteSettingPayload = {
                setting_key: "checklist",
                setting_value: updateChecklistByInfoAndStringify(checklist?.setting_value, "Share Your Baby Page"),
            };
    
            await addWebsiteSetting(data?.id, websiteSettingPayload);
            PostHog.track("share_baby_page_gem", {})
            dispatch(setWebsiteSettingsRefresh({ websiteSettingsRefresh: true }));
        }
    };

    const handleShares = async (platform: string) => {
        Mixpanel.track('PARENT_shares_villie', {
            $name: user?.name,
            $email: user?.email,
            platform: platform,
        });

        await updateChecklistTask();
    };

    const handleCopyUrl = async () => {
        navigator.clipboard
            .writeText(`${app?.APP_PUBLIC_URL}${data?.slug}`)
            .then(() => {
                Notification('success', 'Url copied successfully');
            })
            .catch(() => {
                Notification('error', 'Could not copy URL');
            });

            await updateChecklistTask();
    };

    return (
        <>
            <div className="socialIconsBox">
                <WhatsappShareButton
                    url={`${app?.APP_PUBLIC_URL}${data?.slug}`}
                    title={`You are my Village! Check out my Villie page to stay up to date on all things Baby. I’ll share updates, pictures and more. Click “Join my Village” to subscribe.`}
                    onClick={() => handleShares('whatsapp')}
                >
                    <WhatsappIcon size={'1.5rem'} round={true} /> &nbsp;&nbsp;&nbsp;&nbsp;
                </WhatsappShareButton>
                <FacebookShareButton
                    onClick={() => handleShares('facebook')}
                    url={`${app?.APP_PUBLIC_URL}${data?.slug}`}
                    hashtag={'#villie'}
                >
                    <FacebookIcon size={'1.5rem'} round={true} /> &nbsp;&nbsp;&nbsp;&nbsp;
                </FacebookShareButton>
                <FacebookMessengerShareButton
                    onClick={() => handleShares('facebook_messanger')}
                    appId="1111927622279359"
                    url={`${app?.APP_PUBLIC_URL}${data?.slug}`}
                >
                    <FacebookMessengerIcon size={'1.5rem'} round={true} /> &nbsp;&nbsp;&nbsp;&nbsp;
                </FacebookMessengerShareButton>
                {/* <TwitterShareButton onClick={() => handleShares("twitter")} url={`${app?.APP_PUBLIC_URL}${website?.slug}`} title={You are my Village! Check out my Villie page to stay up to date on all things Baby. I’ll share updates, pictures and more. Click “Join my Village” to subscribe.}>
                    <XIcon size={"1.5rem"} round={true} />
                </TwitterShareButton> */}
                <img
                    className="shareIcon"
                    alt="share-icon"
                    src={`../assets/newIcons/share${capitalize(shareIcon)}.svg`}
                    onClick={handleCopyUrl}
                />
            </div>
        </>
    );
};
export default Share;
