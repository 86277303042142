import './styles.scss';
import React from 'react';
import classNames from 'classnames';
import { Col, Row, Image } from 'antd';
// import Label from 'shared/Label';
import Paragraph from 'shared/components/paragraph';
// import Divider from 'shared/components/divider';
import { formatDate } from 'shared/utils/helper';
import { useSelector } from 'react-redux';
import { getUser } from 'modules/user/redux/userSlice';
// import Engagement from "shared/components/engagement";

type props = {
    classes?: string[]
    forModal?: boolean
    type?: "Update"
    handleEditUpdate: any
    data: any
    website: any
};

const Update: React.FC<props> = ({ classes = [], forModal = false, type = "update", data, handleEditUpdate, website }) => {
    const user = useSelector(getUser)

    return (
        <>

            <Row
                className={classNames("updateContainer", ...classes,)}
                style={{ backgroundColor: forModal ? "rgba(244, 229, 255, 0.50)" : "" }}
            >
                <Col className='firstRow' span={24}>
                    <Paragraph classes={["name"]}>{data?.title}</Paragraph>
                    <div 
                        className='tag' 
                        style={{cursor: (!!user && user?.id === website?.user_id)? "pointer" : "default"}}
                        onClick={() => !user? {} : handleEditUpdate(data)}
                        >
                            {(!!user && user?.id === website?.user_id) ? "Edit" : type}
                    </div>
                </Col>
                <Col className='secondRow' span={24}>
                    {data?.message}
                    <br />
                    <Paragraph classes={["date"]}>{formatDate(data?.created_at, "EEEE, MMMM dd")}</Paragraph>
                </Col>
                <Col span={24}>
                    {data?.images?.length > 0 && 
                        <div style={{width: "100%", height: "9.6875rem", overflow: "hidden", borderRadius: "10px", marginTop: "10px"}}>
                            <Image className="image"
                                src={data?.images[0]?.src}
                                alt="logo" preview={true}
                            />
                        </div>
                    }
                </Col>
                {/* <Col span={24}>
                            <Divider />
                        </Col> */}
                {/* <Col span={24} className='engagementBox'>
                            <div className='action'>
                                <Image className='btn' src={`../assets/newIcons/chat.png`} preview={false} /> 0
                            </div>
                            <Divider type='vertical' />
                            <div className='action'>
                                <Image className='btn' src={`../assets/newIcons/like.png`} preview={false} /> 0
                            </div>
                        </Col> */}
            </Row>
            {/* {
                !details ?
                    
                    :
                    <>

                        {
                            haveImage ?

                                <div className="updateContainerDetails">
                                    <div className="imageBox">
                                        <Image className="image"
                                            src={"https://cdn.pixabay.com/photo/2021/07/09/02/29/family-6398107_1280.jpg"}
                                            alt="logo" preview={false}
                                        />
                                    </div>
                                    <div className="bottomSide">
                                        <div className="engagementBox">
                                            <Engagement />
                                        </div>
                                        <Button
                                            type="base3"
                                            text={"Leave a comment"}
                                            action={() => setAddComment(true)}
                                            classes={["commentBtn"]}
                                        />
                                    </div>
                                </div>
                                :
                                <>
                                    <Row
                                        className={classNames("updateContainer", ...classes,)}
                                        style={{ backgroundColor: forModal ? "rgba(244, 229, 255, 0.50)" : "", borderRadius: "30px", padding: "40px 24px" }}
                                    >
                                        <Col className='firstRow' span={24}>
                                            <Paragraph classes={["name"]}>Second Ultrasound</Paragraph>
                                            <div className='tag'>Milestone</div>
                                        </Col>
                                        <Col className='secondRow' span={24}>
                                            Lorem ipsum dolor sit amet consectetur. Sit imperdiet at bibendum imperdiet at bibendum in vestibulum. Lectus mauris non in sed id ultrices vulputate nibh. Amet proin lectus tincidunt amet enim ultrices.
                                            <br />
                                            <Paragraph classes={["date"]}>Sunday, March 03</Paragraph>
                                        </Col>

                                    </Row>
                                    <div className="bottomSideForUpdateContainer">
                                        <div className="engagementBox">
                                            <Engagement />
                                        </div>
                                        <Button
                                            type="base3"
                                            text={"Leave a comment"}
                                            action={addCommentAction}
                                            classes={["commentBtn"]}
                                        />
                                    </div>
                                </>

                        }
                    </>


            } */}
        </>

    )
}

export default Update;