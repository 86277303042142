import { updateWebsite } from "modules/website/redux/operators";
import "./styles.scss";
import React, { useEffect, useState } from 'react';
import UploadImages from "shared/components/uploadImagesForSteps";
import { getMainPhoto, newConvertBlobToBase64 } from "shared/utils/helper";
import Button from "shared/components/bButton";
import Spinner from "shared/components/spinner";
import Notification from "shared/components/notification";
import { Mixpanel } from "shared/services/mixpanel";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "modules/user/redux/userSlice";
import DeletePhoto from "../deletePhoto";
import { getWebsite, setWebsite } from "shared/infra/redux/global/global";
import SetMainImageModal from "../../modals/setMainImageModal";
import Url from "shared/components/url";

interface props {
    // data: any
    // setData: any
}

const PhotosForm:React.FC<props> = ({}) => {
    const data: any = useSelector(getWebsite)
    const dispatch = useDispatch()
    const user = useSelector(getUser)
    const [images, setImages] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isDeletePhotoConfirmationVisible, setIsDeletePhotoConfirmationVisible] = useState(false)
    const [isSetMainImageModalVisible, setIsMainImageModalVisible] = useState(false)
    const [deletePhotoData, setDeletePhotoData] = useState(null)
    const [mainPhoto, setMainPhoto] = useState(getMainPhoto(data?.photos))

    const handleImagesUpload = async () => {
        if (images?.length === 0 ) {
            return Notification("error", "Select some images")
        }

        if (isLoading) return 0

        setIsLoading(true)
        const payload = {
            "website_photos": await newConvertBlobToBase64(images)
        }

        const response = await updateWebsite(data.id, payload)
        setIsLoading(false)


        if (response?.status === "success") {
            Mixpanel.track(
                "PARENT_adds_a_photo",
                {
                    $name: user?.name,
                    $email: user?.email,
                    website_id: data?.id,
                    website_slug: data?.slug
                }
            )

            dispatch(setWebsite({website: {...data, photos: [...data?.photos, ...response?.data?.photos]}}))

            setImages([])
            Notification("success", "Images uploaded successfully.")
        }
        else {
            setIsLoading(false)
            Notification("error", "There is an error. Try again later.")
        }
    }

    useEffect(() => {
        setMainPhoto(getMainPhoto(data?.photos))
    }, [data])

    return(
        <>
            <div className='photosFormContainer'>
                <UploadImages
                    full={true}
                    name="Upload Images"
                    images={images}
                    onChange={(images: any) => setImages(images)}
                    noOfImagesAllowed={3}
                    forStep
                    btnSublabel=""
                />
                <div className="imagesDisplayBox">
                    {
                        data?.photos?.map((photo: any) => (
                            <div className="imageBox" key={photo?.id}>
                                <img className="deleteBtn" 
                                    src="../assets/newIcons/delete.svg"
                                    onClick={() => {
                                        setDeletePhotoData(photo)
                                        setIsDeletePhotoConfirmationVisible(true)
                                    }}
                                />
                                <img className="img" src={photo?.img_url}/>
                                {mainPhoto?.id === photo?.id && <div className="badge">Main</div>}
                            </div>
                        ))
                    }
                    
                </div>
                <Button
                    type={"base4"}
                    action={handleImagesUpload}
                    classes={["w-100-p"]}
                    text={isLoading ? <Spinner /> : `Save`}
                />
                <div className="d-v-c m-t-3-p">
                    <Url 
                        classes={[]}
                        text={`Change Main Image`}
                        action={() => setIsMainImageModalVisible(true)}
                    />
                </div>
                
            </div>

            <DeletePhoto 
                show={isDeletePhotoConfirmationVisible}
                close={() => setIsDeletePhotoConfirmationVisible(false)}
                photo={deletePhotoData}
                data={data}
            />

            <SetMainImageModal 
                show={isSetMainImageModalVisible}
                close={() => setIsMainImageModalVisible(false)}
                photo={deletePhotoData}
                data={data}
                mainPhoto={mainPhoto}
            />
        </>
    )
}


export default PhotosForm;