import './styles.scss';
import React from 'react';
import { Typography } from 'antd';
import classNames from 'classnames';

type props = {
    text: any;
    classes?: string[]
    styles?: any
};

const {Paragraph} = Typography

const Label: React.FC<props> = ({ text, classes=[], styles = {} }) => {
    return (
        <Paragraph 
            className={classNames("mainLabel", ...classes,)}
            style={{...styles}}
        >
            {text}
        </Paragraph>
    );
};

export default Label;
