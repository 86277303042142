declare global {
    interface Window {
        gtag: any;
        dataLayer: any;
    }
}

export namespace GoogleAnalytics {
    // log the pageview with their URL
    // export const pageview = (url: string) => {
    //     if (typeof window !== undefined) {
    //         window?.gtag('config', google.GOOGLE_ANALYTICS, {
    //             page_path: url,
    //         })
    //     }
    // }

    export const pageview = (url: string) => {
        if (typeof window !== undefined) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "GTMevent",
                event_name: "pageview",
                page_path: url
            });
        }
    }

    export const event = (eventName: string, params: Record<string, any>, event: string="event") => {
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: event,
                event_name: eventName,
                ...params
            });
        }
    };

    // pageview = (url) => {
        // if (typeof window !== undefined) {
        //     window.dataLayer = window.dataLayer || [];
        //     window.dataLayer.push({
        //         event: "GTMevent",
        //         event_name: "pageview",
        //         page_path: url
        //     });
        // }
    // }

    // log specific events happening.
    // export const event = ({ action, params }: { action: any, params: any }) => {
    //     if (typeof window !== undefined) {
    //         window.gtag('event', action, params)
    //     }
    // }
}