import "./styles.scss"
import React from 'react'
import { Skeleton as AntdSkeleton } from 'antd'
import useWindowSize from "shared/hooks/use-window-size"

interface props {
    show?: boolean
    title?: boolean
    paragraph?: boolean
    image?: boolean
}

const Skeleton:React.FC<props> = ({show = true, paragraph = false, title = false, image = false}) => {
    const {width} = useWindowSize()

    return (
        <>
        <AntdSkeleton
            active 
            loading={show} 
            title={title} 
            paragraph={paragraph}
        />
        {image && 
            <div className='imageSkeltonBox'>
                <AntdSkeleton.Image active/>
                <AntdSkeleton.Image active/>
                {!(width <= 450) && <AntdSkeleton.Image active/>}
            </div>
        }
        </>
    )
}

export default Skeleton