import classNames from "classnames";
import "./styles.scss";
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TDispatch } from "shared/infra/redux/store";
import { logout } from 'modules/auth/redux/operators';
import { getUser } from "modules/user/redux/userSlice";
import { isFreeTrialValid, openInNewTab } from "shared/utils/helper";
import { setUpgradeModalVisible } from "shared/infra/redux/global/global";

interface props {
    website: any
}

const ProfileDropdown: React.FC<props> = ({website}) => {
    const user = useSelector(getUser)
    const dispatch: TDispatch = useDispatch();
    const navigate = useNavigate()
    const [showDropdown, setShowDropdown] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleNavigation = (url: string) => {
        navigate(url)
        setShowDropdown(false)
    }

    const onLogout = async () => {
        setShowDropdown(false)
        dispatch(logout({email: user?.email, product_type: "village"}))
        navigate("/login")
    }

    return (
        <div className="profileDropdownContainer" ref={containerRef}>
            <img className="profileImage" src="../assets/newIcons/profile.svg" alt="Profile" onClick={() => setShowDropdown(!showDropdown)} />
            
            <div className={classNames("dropdown", { isDropDownVisible: showDropdown })}>
                <p onClick={() => handleNavigation("/dashboard")}>Dashboard</p>
                <p onClick={() => handleNavigation("/edit/content")}>Edit Content</p>
                <p onClick={() => handleNavigation("/edit/design")}>Design</p>
                <p onClick={() => handleNavigation("/edit/account")}>Account Info</p>
                {website?.package !== "tier1" && !isFreeTrialValid(website?.free_trial_started_at) && <p onClick={() => dispatch(setUpgradeModalVisible({isUpdgradeModalVisible: true}))}>Upgrade Plan</p>}
                <p onClick={() => openInNewTab("https://www.villie.com/contact")}>Get Help</p>
                <p onClick={onLogout}>Log Out</p>
            </div>
        </div>
    );
}

export default ProfileDropdown;
