import "./styles.scss";
import React from "react";
import { Row, Col, Divider } from "antd";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { paypal } from "shared/infra/config";
import Paypal from "./paypal";
import Stripe from "./stripe";
import { createGuessWhoGame, updateWebsite } from "modules/website/redux/operators";
import Notification from "shared/components/notification";
import { DATETIME, getQueryParam } from "shared/utils/helper";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGuessWho, setGuessWhoGame } from "shared/infra/redux/global/global";
import Spinner from "shared/components/spinner";

interface props {
	amountCharged?: any;
	stripeClientSecret?: any;
	data?: any;
	handlePaymentComplete: any
	setGuessWhoGameIsCreating: any
	guessWhoGameIsCreating: any
}

const paypalScriptOptions = {
	"client-id": paypal.PAYPAL_KEY!,
	currency: "USD",
};

const Payment: React.FC<props> = ({ amountCharged, stripeClientSecret, data, handlePaymentComplete, setGuessWhoGameIsCreating, guessWhoGameIsCreating }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const guessWho = useSelector(getGuessWho)
	const websiteSlug: any = getQueryParam('website_slug');

	const handleUpgrade = async (payload: any) => {
		const response = await updateWebsite(data.id, {
			package: payload?.status === "success" ? data.package : "tier0",
			package_payment_id: payload?.payment_id,
			package_payment_status: payload?.status,
			package_updated_on: DATETIME
		});

		if (response?.status === "success") {
			handlePaymentComplete(payload?.status)

			// Create a "Guess Who" game here if the user upgrades after starting the process of creating a "Guess Who" game.
			if(!!guessWho){
				setGuessWhoGameIsCreating(true);
				await createGuessWhoGame(guessWho);
				await dispatch(setGuessWhoGame({guessWhoGame: null}))
				setGuessWhoGameIsCreating(false);
			}
			
			setTimeout(
				() => navigate(`/${websiteSlug}`),
				2000
			);
		} else {
			Notification("error", response?.message);
		}
	};

	return (
		<>
			<Row className="upgradePaymentContainer">
				{
					guessWhoGameIsCreating ?
					<Spinner section/>
					:
						<>
							<Col span={24}>
								<PayPalScriptProvider options={paypalScriptOptions}>
									<Paypal
										handlePayment={handleUpgrade}
										amount={parseFloat(amountCharged)}
									/>
								</PayPalScriptProvider>
							</Col>
							<Col span={24}>
								<Divider plain>or</Divider>
							</Col>

							<Col span={24}>
								<Stripe
									stripeClientSecret={stripeClientSecret}
									handlePayment={handleUpgrade}
									data={data}
								/>
							</Col>

						</>
				}

			</Row>
		</>
	);
};

export default Payment;
