import "./styles.scss"
import React, { useEffect, useState } from 'react'
import Button from "shared/components/bButton";

interface props{
    label?: string
    actionOnYes: any
    actionOnNo: any
    decision?: "No" | "Yes" | null
}
const DecisionButtons:React.FC<props> = ({label, decision=null, actionOnYes, actionOnNo}) => {
    const [isDecisionMade, setIsDecisionMade] = useState<'Yes' | 'No' | null>(decision);

    const handleYes = () => {
        setIsDecisionMade('Yes');
        actionOnYes()
    };

    const handleNo = () => {
        setIsDecisionMade('No');
        actionOnNo()
    };

    useEffect(() => {
        setIsDecisionMade(decision)
    }, [decision])

    return (
        <div className="decisionBtnContainer">
            {!!label && <p className='labelDecision'>{label}</p>}
            <div className='btnBoxDecision'>
                <Button
                    text="Yes"
                    action={handleYes}
                    classes={['btnD', isDecisionMade === "Yes" ? 'isBtnSeclected' : '']}
                />
                <Button
                    text="No"
                    action={handleNo}
                    classes={['btnD', isDecisionMade === "No" ? 'isBtnSeclected' : '']}
                />
            </div>
        </div>
    )
}

export default DecisionButtons