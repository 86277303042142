import { UserModel } from "../models/user"

export type UserState = {
    user: null | UserModel,
}

const initialUserState: UserState = {
    user: null,
}

export default initialUserState