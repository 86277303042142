import React, { useState } from "react";
import "./styles.scss";
import Modal from "shared/components/modal";
import { Form } from "antd";
import CustomInput from "shared/components/binput";
import BButton from "shared/components/bButton";
import { verifyVisitorPassword } from "modules/website/redux/operators";
import Spinner from "shared/components/spinner";
import { useDispatch } from "react-redux";
import { setToken } from "modules/auth/redux/authSlice";
// import { useDispatch } from "react-redux";
// import { TDispatch } from "shared/infra/redux/store";

interface props {
	show: boolean;
	close: any;
	data: any;
	handleResponse: any;
}


const EnterPassCodeModal: React.FC<props> = ({ data, show, close, handleResponse}) => {
	const dispatch = useDispatch()
	const [form] = Form.useForm();
	const [isLoading, setIsLoading] = useState(false);

	const handlePassCode = async (values: any) => {
		setIsLoading(true);
		const response = await verifyVisitorPassword(data.id, values);
		setIsLoading(false);

		if (response?.status === "success") {
			handleResponse(response)
			dispatch(setToken({accessToken: response?.data?.token}))
		}
		else {
			form.setFields([{name: "password", errors: ["Invalid password"]}]);
		}
	};

	return (
		<>
			<Modal show={show} close={close} head={false}>
				<div className="enterPassCodeBox">
					<p className="title">
						This Page is password protected!
					</p>
					<p className="subtitle">
						{data?.password_instructions}
					</p>
					<Form
						form={form}
						name="basic"
						layout="vertical"
						onFinish={handlePassCode}
						autoComplete="off"
					>
						<CustomInput
							name="password"
							placeholder="Password"
							required
							errorMessage="Please enter password"
							classes={["c-bg-w"]}
						/>

						<BButton
							type="base4"
							htmlType="submit"
							text={isLoading ? <Spinner /> : "Submit"}
							classes={["w-100-p", "m-tb-25-px"]}
						/>
					</Form>
				</div>
			</Modal>
		</>
	);
};

export default EnterPassCodeModal;
