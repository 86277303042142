import "./styles.scss"
import React from 'react';
import { Tooltip as AntdTooltip } from "antd";

interface props {
    children?: any
    content?: string | string[] | any
    placement?: "top" | "bottom"
}

const Tooltip: React.FC<props> = ({ children, content, placement="top" }) => {
    return (
        <AntdTooltip placement={placement} 
            title={<>
                {
                        Array.isArray(content) ?
                        <ul>{content?.map((c:string) => <li>{c}</li>)}</ul>
                        :
                        content
                    }
            </>} 
            showArrow={false}
            >
            {children}
        </AntdTooltip>
    );
}

export default Tooltip;
