import { BaseAPI } from "shared/infra/services/baseAPI";


export namespace GameService {
    export const createGame = (data: any) =>  BaseAPI.post('game/', data)
    export const updateGame = (gameId: string, data: any) =>  BaseAPI.patch(`game/${gameId}`, data)
    export const deleteGame = (gameId: string) =>  BaseAPI.del(`game/${gameId}`)
    export const castVoteWithDonation = (data: any) =>  BaseAPI.post(`vote/castVoteWithDonation`, data)
    export const castFreeVote = (data: any) =>  BaseAPI.post(`vote/castFreeVote`, data)
    export const endGame = (gameId: string, data: any) =>  BaseAPI.post(`game/winner/${gameId}`, data)

    export const createGuessWhoGame = (data: any) =>  BaseAPI.post('guess/', data)
    export const answerGuessWhoGame = (data: any) =>  BaseAPI.post('guess/answer', data)
    

}