import axios from 'axios';
import { resetAuthState } from 'modules/auth/redux/authSlice';
import { resetUserState } from 'modules/user/redux/userSlice';
import { app } from '../config';
import {store} from "../redux/store"
import { resetOnBoardingState } from '../redux/onboarding/onboarding';

export namespace BaseAPI {
    axios.defaults.baseURL = app.API_BASE_URL;
    const axiosInstance = axios.create({})
    axiosInstance.interceptors.request.use(function (config: any) {
        const state = store.getState()
        if (state.auth.accessToken) {
            config.headers["x-auth-token"] = state.auth.accessToken
        }
        return config;
    });

    const getSuccessResponseHandler = () => {
        return (response: any) => {
            return response;
        }
    }
    const getErrorResponseHandler = () => {
        return async (error: any) => {
            if (error?.config && error?.response && error?.response?.status === 401) {
                store.dispatch(resetAuthState())
                store.dispatch(resetUserState())
                store.dispatch(resetOnBoardingState())
                window.location.reload();
            }
            return Promise.reject({ ...error })
        }
    }
    axiosInstance.interceptors.response.use(
        getSuccessResponseHandler(),
        getErrorResponseHandler()
    )

    export const get = (url: string, params: any = {}, headers: any = {}): Promise<any> => {
        return axiosInstance({
            method: 'GET',
            url: url,
            params: params,
            headers: headers
        })
    }
    export const post = (url: string, data?: any, params: any = {}, headers: any = {}): Promise<any> => {
        return axiosInstance({
            method: 'POST',
            url: url,
            data: data ? data : null,
            params: params,
            headers: headers
        })
    }
    export const del = (url: string, data?: any, params: any = {}, headers: any = {}): Promise<any> => {
        return axiosInstance({
            method: 'DELETE',
            url: url,
            data: data ? data : null,
            params: params,
            headers: headers
        })
    }
    export const put = (url: string, data?: any, params: any = {}, headers: any = {}): Promise<any> => {
        return axiosInstance({
            method: 'PUT',
            url: url,
            data: data ? data : null,
            params: params,
            headers: headers
        })
    }

    export const patch = (url: string, data?: any, params: any = {}, headers: any = {}): Promise<any> => {
        return axiosInstance({
            method: 'PATCH',
            url: url,
            data: data ? data : null,
            params: params,
            headers: headers
        })
    }
}