import React from 'react';
import NewDashboardContainer from 'modules/website/pages/newDashboard';

const NewDashboard: React.FC = () => {

    return(
        <NewDashboardContainer />
    );
}

export default NewDashboard;