import './styles.scss';
import React from 'react';
import classNames from 'classnames';

interface props {
    color?: string;
    thickness?: string;
    hideOnMobile?: boolean;
    hideOnDesktop?: boolean;
    styles?: any;
    type?: 'horizontal' | 'vertical'; // New prop for specifying the divider type
}

const Divider: React.FC<props> = ({ hideOnMobile = false, hideOnDesktop = false, styles, type = 'horizontal' }) => {
    const dividerClass = classNames('dividerContainer', {
        'hideOnMobile': hideOnMobile,
        'hideOnDesktop': hideOnDesktop,
        'verticalDivider': type === 'vertical', // Apply the 'verticalDivider' class for vertical dividers
    });

    return <div className={dividerClass} style={{ ...styles }} />;
};

export default Divider;
