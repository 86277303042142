// import React from 'react';
// import { Navigate,  } from "react-router-dom";
// import { useSelector,  } from 'react-redux';
// import { getAccessToken } from 'modules/auth/redux/authSlice';

// interface props {
// 	children: any,
// }

// const ProtectedRoute: React.FC<props> = ({ children }) => {
// 	const token = useSelector(getAccessToken)
// 	return (
// 		<>
// 			{
// 				token
// 					? children
// 					: <Navigate to="/" />
// 			}
// 		</>
// 	);
// };

// export default ProtectedRoute;

import React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getAccessToken } from 'modules/auth/redux/authSlice';

interface Props {
    children: any;
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
    const token = useSelector(getAccessToken);
    const location = useLocation();

    return (
        <>
            {token ? children : <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname + location.search)}`} />}
        </>
    );
};

export default ProtectedRoute;
