import "./styles.scss";
import React, { useState } from 'react'
import { Col, Row } from 'antd';
import Section from "shared/components/section";
import Heading from "shared/components/heading";
import Button from "shared/components/bButton";
import useWindowSize from "shared/hooks/use-window-size";
import PhotoCollage from "shared/components/photoCollage";
import ViewVilliePhotosModal from "../../modals/viewVilliePhotosModal";
import { Mixpanel } from "shared/services/mixpanel";
import { getBtnBackgroundColor } from "shared/utils/helper";
import { getTextColor } from "shared/utils/helper";

interface props{
    website: any
    color: string
}

const PhotosSection: React.FC<props> = ({website, color}) => {
    const {width} = useWindowSize()
    const [isVilliePhotosModalVisible, setIsVilliePhotosModalVisible] = useState(false)

    return (
        <>
            <div id="photos"></div>
            <Section backgroundColor={color}>
                <Row className='photosSectionContainer'>
                <Col span={24} style={{textAlign: width > 450 ? "center" : "left"}}>
                    <Heading text={<>Photos</>} styles={getTextColor(website?.website_color, color)}/>
                </Col>
                <Col span={24}>
                    <PhotoCollage 
                        photos={website?.photos}
                        overview
                    />
                </Col>
                <Col span={24} className="lastRow">
                    <Button 
                        classes={["btn"]}
                        styles={getBtnBackgroundColor(website?.website_color, color)}
                        type="theme" text="View all Photos" action={() => {
                            Mixpanel.track("VILLAGER_view_photos", {
                                website_id: website?.id,
                                website_slug: website?.slug,
                            })
                            setIsVilliePhotosModalVisible(true)
                        }}
                    />
                </Col>
                </Row>
            </Section>

            <ViewVilliePhotosModal 
                photos={website?.photos}
                show={isVilliePhotosModalVisible}
                close={() => setIsVilliePhotosModalVisible(false)}
            />
        </>
    )
}

export default PhotosSection