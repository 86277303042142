import React, { useEffect, useState } from 'react';
import './style.scss';
import Tooltip from '../tooltip';
import {
    addWebsiteSetting,
    getWebsiteSetting,
    updateWebsite,
} from 'modules/website/redux/operators';
import Spinner from '../spinner';
import { useDispatch, useSelector } from 'react-redux';
import {
    getWebsiteSettings,
    getWebsiteSettingsRefresh,
    setWebsiteRefresh,
    setWebsiteSettings,
    setWebsiteSettingsRefresh,
} from 'shared/infra/redux/global/global';
import { DATETIME, formatDate } from 'shared/utils/helper';
import InfoModal from '../infoModal';
import Notification from '../notification';
import { checklist } from 'shared/utils/constants';
import { PostHog } from 'shared/services/posthog';
// import { updateChecklistByInfoAndStringify } from 'shared/utils/helper';

interface props {
    website: any;
}

const Gemscard: React.FC<props> = ({ website }) => {
    const [isLoading, setIsLoading] = useState(false);
    const websiteSettings = useSelector(getWebsiteSettings);
    const dispatch = useDispatch();
    const refresh = useSelector(getWebsiteSettingsRefresh);
    const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);

    const fetchChecklist = async () => {
        setIsLoading(true);
        const response = await getWebsiteSetting(website?.id, 'checklist');
        setIsLoading(false);

        if (response?.status === 'success') {
            // if checklist is completed then update user to free trail for 1 week
            if (
                !!response?.data?.setting?.setting_value?.checklist_completed_at &&
                !website?.is_free_trial_notification_viewed && website?.package === "tier0"
            ) {
                const { status } = await updateWebsite(website?.id, {
                    is_free_trial: true,
                    free_trial_started_at: DATETIME,
                });
                if (status === 'success') {
                    setIsInfoModalVisible(true);
                    await updateWebsite(website?.id, { is_free_trial_notification_viewed: true });
                    PostHog.track("gems_free_trial", {})
                }
            }

            dispatch(
                setWebsiteSettings({
                    websiteSetting: { ...websiteSettings, checklist: response?.data?.setting },
                })
            );
        } else if (response?.status === 'error' && response?.message === 'Setting not found.') {
            dispatch(
                setWebsiteSettings({ websiteSetting: { ...websiteSettings, checklist: null } })
            );
        }
    };

    useEffect(() => {
        if (!!website?.id) {
            fetchChecklist();
        }
    }, [website]);

    useEffect(() => {
        if (refresh) {
            if (!!website?.id) fetchChecklist();
            dispatch(setWebsiteSettingsRefresh({ websiteSettingsRefresh: false }));
        }
    }, [refresh]);

    const completedGems = websiteSettings?.checklist?.setting_value?.checklist
        ? websiteSettings.checklist.setting_value.checklist.filter((c: any) => c.completed).length
        : 0;

    const handleGemsCardClose = async () => {
        const payload = {
            setting_key: 'checklist',
            setting_value: JSON.stringify({
                ...websiteSettings?.checklist?.setting_value,
                is_visible: false,
            }),
        };

        const response = await addWebsiteSetting(website?.id, payload);

        if (response?.status === 'success') {
            dispatch(setWebsiteSettingsRefresh({ websiteSettingsRefresh: true }));
        } else {
            Notification('error', 'There is an error.');
        }
    };

    // Check if is_visible is false, return null
    if (websiteSettings?.checklist?.setting_value?.is_visible === false) {
        return null;
    }

    const getTooltip = () => {
        return (
            <Tooltip
                content={
                    <div style={{ paddingTop: '10px' }}>
                        <p style={{ marginBottom: '5px' }}>
                            Here's how to earn gems
                        </p>
                        <ul>
                            {!websiteSettings?.checklist?.setting_value
                                ?.checklist &&
                                checklist.map((c: any) => (
                                    <li
                                        key={c.id}
                                        style={{
                                            textDecoration: c.completed
                                                ? 'line-through'
                                                : 'none',
                                            textDecorationColor: c.completed
                                                ? '#CC8dFF'
                                                : 'inherit',
                                        }}
                                    >
                                        {c.info}
                                    </li>
                                ))}
                            {websiteSettings?.checklist?.setting_value?.checklist
                                ?.slice() // Create a shallow copy of the array
                                .sort(
                                    (a: any, b: any) =>
                                        a.completed - b.completed
                                ) // Sort so incomplete tasks come first
                                .map((c: any) => (
                                    <li
                                        key={c.id}
                                        style={{
                                            textDecoration: c.completed
                                                ? 'line-through'
                                                : 'none',
                                            textDecorationColor: c.completed
                                                ? '#CC8dFF'
                                                : 'inherit',
                                        }}
                                    >
                                        {c.info}
                                    </li>
                                ))}
                        </ul>
                    </div>
                }
                placement="top"
            >
                <img
                    src="/assets/newIcons/info-icon.png"
                    alt=""
                    className="gemscard-info-icon"
                />
            </Tooltip>
        )
    }
    return (
        <>
            <div className="gemscard-parent">
                {isLoading ? (
                    <Spinner section />
                ) : (
                    <div className="gemscard-child">
                        {websiteSettings?.checklist?.setting_value?.checklist_completed_at ? (
                            <div className="gemscard-hide" onClick={handleGemsCardClose}>
                                <span>Hide</span>
                            </div>
                        ):
                        <>
                            {
                                website?.package !== "tier0" &&
                                <div className="gemscard-hide">
                                    {getTooltip()}
                                </div> 
                            }
                             
                        </>
                    
                        }
                        <div className="gemscard-rotation">
                            {!websiteSettings?.checklist &&
                                Array.from({ length: 5 }).map((_, i) => (
                                    <div key={i} className="gemscard-germs-place-holder"></div>
                                ))}
                            {websiteSettings?.checklist?.setting_value?.checklist
                                ?.slice() // Create a shallow copy of the array
                                .sort((a: any, b: any) => b.completed - a.completed) // Sort by completed status (true first)
                                .map((c: any) =>
                                    c?.completed ? (
                                        <img
                                            key={c.id}
                                            src={`/assets/newIcons/${c.id}-diamond.svg`}
                                            alt="diamond"
                                            className="diamond-width"
                                        />
                                    ) : (
                                        <div
                                            key={c.id}
                                            className="gemscard-germs-place-holder"
                                        ></div>
                                    )
                                )}
                        </div>
                        <div className="gemscard-body">
                            <p className="gemscard-germs-earned">
                                {completedGems} of 5 gems earned
                            </p>
                            {
                                (!website?.is_free_trial && website?.package === "tier0") &&
                                <div className="gemscard-germs">
                                    <p>
                                        Collect all 5 gems to unlock a free upgrade! (a $39.99 value!)
                                        {getTooltip()}
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                )}
            </div>

            <InfoModal
                show={isInfoModalVisible}
                close={() => {
                    setIsInfoModalVisible(false);
                    dispatch(setWebsiteRefresh({ refresh: true }));
                }}
                type="success"
                content={
                    <div className="infoModalBox">
                        <p className="title">Congratulations 🎉</p>
                        <p
                            style={{
                                color: '#463649',
                                fontFamily: 'Poppins Regular',
                                fontSize: '16px',
                                marginBottom: '10px',
                            }}
                        >
                            You've earned a 7-day free trial!
                        </p>
                        <p
                            style={{
                                color: '#463649',
                                fontFamily: 'Poppins Regular',
                                fontSize: '16px',
                            }}
                        >
                            Free trial will end on:&nbsp;
                            <span style={{ fontFamily: 'Poppins Bold' }}>
                                {formatDate(
                                    new Date(new Date(DATETIME).setDate(new Date(DATETIME).getDate() + 7)).toISOString(),
                                    "EEEE, MM/dd"
                                )}
                            </span>
                        </p>
                    </div>
                }
            />
        </>
    );
};

export default Gemscard;
