import "./styles.scss";
import React, { useState } from 'react';
import Modal from 'shared/components/modal';
import { Row, Col, Typography, Checkbox, Button, Input, Form } from 'antd';
import { useStripe, useElements, CardCvcElement, CardExpiryElement, CardNumberElement} from "@stripe/react-stripe-js";
import { paypal } from "shared/infra/config"
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Paypal from "modules/website/pages/newPreviewMode/components/payment/paypal";
import classNames from "classnames";
import useWindowSize from "shared/hooks/use-window-size";
import { castVoteWithDonation, castFreeVote } from "modules/website/redux/operators";
import { calculateServiceFee, capitalize, extractDigitsFromString } from "shared/utils/helper";
import Spinner from "shared/components/spinner";
import CustomInput from "shared/components/newinput";
import Notification from "shared/components/notification";
import { Mixpanel } from "shared/services/mixpanel";
import { LeftOutlined } from '@ant-design/icons';

interface props {
    show: boolean
    close: any
    data: any
    voteInfo: any
    refresh: any
}

const { Paragraph } = Typography

const paypalScriptOptions = { "client-id": paypal.PAYPAL_KEY!, currency: "USD" };


const CastingVoteModal: React.FC<props> = ({ show, close, data, voteInfo, refresh }) => {
    const stripe: any = useStripe();
    const elements: any = useElements();
    const [form] = Form.useForm()
    const { width } = useWindowSize()
    const [step, setStep] = useState(1)
    const [isAnonymousVote, setIsAnonymousVote] = useState(false)
    const [nameOnCard, setNameOnCard] = useState<any>(null)
    const [voteFor, setVoteFor] = useState<null | "free" | "cash">(null)
    const [donationAmount, setDonationAmount] = useState<any>("0")
    const [customDonationAmount, setCustomDonationAmount] = useState<any>(null)
    const [donationMethod, setDonationMethod] = useState<null | "stripe" | "paypal">(null)
    const [error, setError] = useState<any>({ donationAmountError: "Please select the donation amount" })

    const [isVoteAddedSuccessfully, setIsVoteAddedSuccessfully] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleAnonymousVoteAcknowlegment = (e: any) => {
        setIsAnonymousVote(e.target.checked)
    };

    const handleClose = () => {
        close();
        setStep(1)
        setVoteFor(null)
        setNameOnCard(null)
        setCustomDonationAmount(null)
        setDonationMethod(null)
        setDonationAmount("0")

        if(isVoteAddedSuccessfully){
            refresh()
        }
    }


    const handleSubmitVote = async () => {
        if (isLoading) return 0

        if (donationAmount === "0") {
            return setError({ donationAmountError: "Please select the donation amount" })
        }
        if (donationAmount === "other" && !customDonationAmount) {
            return setError({ donationAmountError: "Donation amount is required" })
        }
        if (!donationMethod) {
            return setError({ donationMethodError: "Please select a donation method" })
        }
        if (!nameOnCard) {
            return setError({ nameOnCardError: "Please add name on card" })
        }

        const voter_first_name = nameOnCard.substring(0, nameOnCard.indexOf(' '))
        const voter_last_name = nameOnCard.substring(nameOnCard.indexOf(' ') + 1)

        if (!voter_first_name || !voter_last_name) {
            return setError({ nameOnCardError: "Please add full name" })
        }

        setIsLoading(true)
        const { token } = await stripe.createToken(elements.getElement(CardNumberElement))

        if (token) {
            await handleVotingWithDonation({ payment_method: "stripe", stripe_token: token.id, voter_first_name, voter_last_name })
        } else {
            setIsLoading(false)
            return setError({ cardDetailError: "Please add card details" })
        }
    }

    const handleVotingWithDonation = async (values: any) => {
        if(isLoading) return
        setIsLoading(true)

        const payload = {
            boy_or_girl: voteInfo?.voteFor === 'boy' ? 0 : 1,
            no_of_votes: 1,
            is_anonymous: isAnonymousVote,
            website_id: data?.id,
            contest_id: data?.game?.id,
            donation_amount: donationAmount === "other" ? customDonationAmount : donationAmount,
            ...values
        }

        const response = await castVoteWithDonation(payload)
        Mixpanel.track("VILLAGER_paid_vote_on_grg", {
            vote_amount: donationAmount === "other" ? customDonationAmount : donationAmount,
            website_id: data?.id,
            game_id: data?.game?.id,
            website_slug: data?.slug
        })
        
        if (response?.status === "success") {
            setIsLoading(false)
            setStep(3)
            setIsVoteAddedSuccessfully(true)
        }
        else {
            setIsLoading(false)
            Notification("error", response?.message)
        }
    }

    const handleFreeVoting = async ({ name, phone, email, relation_with_mom }: any) => {
        if(isLoading) return
        setIsLoading(true)
        
        const payload = {
            boy_or_girl: voteInfo?.voteFor === 'boy' ? 0 : 1,
            no_of_votes: 1,
            is_anonymous: isAnonymousVote,
            contest_id: data?.game?.id,
            website_id: data?.id,
            voter_first_name: name?.substring(0, name?.indexOf(' ')),
            voter_last_name: name?.substring(name?.indexOf(' ') + 1),
            name,
            phone,
            email,
            relation_with_mom
        }

        const response = await castFreeVote(payload)
        Mixpanel.track("VILLAGER_free_vote_on_grg", {
            website_id: data?.id,
            game_id: data?.game?.id,
            website_slug: data?.slug
        })

        if (response?.status === "success") {
            setIsLoading(false)
            setStep(3)
            setIsVoteAddedSuccessfully(true)
        }
        else {
            setIsLoading(false)
            Notification("error", response?.message)
        }
    }

    return (
        <>
            <Modal show={show} close={handleClose} head={false}>
                <Row className="castingVoteModalContainer">
                    {
                        step === 1 &&
                        <>
                            <Col span={24}>
                                <Paragraph className="title">Gender Reveal Game</Paragraph>
                            </Col>

                            <Col span={24}>
                                <div className="voteButtonSection">
                                    <div className="left">
                                        <div className={classNames("vote-button", { "forBoy": voteInfo?.voteFor === "boy" }, { "forGirl": voteInfo?.voteFor === "girl" })}>
                                            <img src={"../assets/white-bear.svg"} alt="icon-btn" />
                                            <span>It’s a {capitalize(voteInfo?.voteFor)}!</span>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <Paragraph className="heading">Game Prize</Paragraph>
                                        <Paragraph className="subheading">{data?.game?.prize_name}</Paragraph>
                                    </div>
                                </div>
                            </Col>

                            <Col span={24}>
                                <Checkbox className="anonymousVote" onChange={handleAnonymousVoteAcknowlegment}>Check the box to cast your vote anonymously.</Checkbox>
                            </Col>

                            {/* <Col span={24}>
                                <Divider />
                            </Col> */}

                            {/* <Col span={24}>
                                <Paragraph className="subtitle">Contribute to {data?.website?.village_owner} fund </Paragraph>
                            </Col> */}

                            <Col span={24} className="buttonSection">
                                <Button className={classNames("leftBtn", { "leftBtnForBoy": voteInfo?.voteFor === "boy" }, { "leftBtnForGirl": voteInfo?.voteFor === "girl" })} onClick={
                                    () => {
                                        setVoteFor(data?.game?.raise_funds === 1 ? "cash" : "free");
                                        setStep(2)
                                    }
                                }>Vote</Button>
                                {/* <Button className={classNames("rightBtn", { "rightBtnForBoy": voteInfo?.voteFor === "boy" }, { "rightBtnForGirl": voteInfo?.voteFor === "girl" })} onClick={
                                    () => {
                                        setVoteFor("free");
                                        setStep(2)
                                    }
                                }>Skip</Button> */}
                            </Col>
                        </>
                    }

                    {
                        step === 2 && voteFor === "cash" &&
                        <>
                            <Col span={24}>
                                <Paragraph 
                                    className="backButton"
                                    onClick={() => {
                                        setVoteFor(null)
                                        setStep(1)
                                    }}
                                >
                                    <LeftOutlined /> Back
                                </Paragraph>
                            </Col>
                            <Col span={24}>
                                <Paragraph className="donationTitle">Select donation amount (USD)</Paragraph>
                            </Col>
                            <Col span={24} className="donationAmountBtnSection">
                                {
                                    ["5", "10", "15", "20", "other"].map(
                                        (amount: string, id: number) => <div
                                            className={classNames("btn", { "btnForBoy": voteInfo?.voteFor === "boy" }, { "btnSelectedForBoy": donationAmount === amount && voteInfo?.voteFor === "boy" }, { "btnForGirl": voteInfo?.voteFor === "girl" }, { "btnSelectedForGirl": donationAmount === amount && voteInfo?.voteFor === "girl" })}
                                            onClick={() => {
                                                setDonationAmount(amount)
                                                setError(null)
                                            }}
                                            key={id}
                                        >
                                            ${amount}
                                        </div>
                                    )
                                }
                            </Col>
                            {
                                donationAmount === "other" &&
                                <Col span={24}>
                                    <Input
                                        className={classNames('castingVoteModalInput', { "castingVoteModalInputForBoy": voteInfo?.voteFor === "boy" }, { "castingVoteModalInputForGirl": voteInfo?.voteFor === "girl" })}
                                        placeholder='Enter custom amount'
                                        onChange={(e: any) => {
                                            setCustomDonationAmount(extractDigitsFromString(e?.target?.value))
                                            setError(null)
                                        }}
                                    />
                                </Col>
                            }

                            {!!error?.donationAmountError &&
                                <Col span={24}>
                                    <Paragraph className="errorMessage">{error?.donationAmountError}</Paragraph>
                                </Col>
                            }

                            <Col span={24}>
                                <Paragraph className="donationTitle">Select donation method</Paragraph>
                            </Col>

                            <Col span={24} className="donationMethodBtnSection">
                                <Row justify="space-between">
                                    <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                        <div
                                            className={classNames("cardBtn", { "cardBtnForBoy": voteInfo?.voteFor === "boy" }, { "btnSelectedForBoy": donationMethod === "stripe" && voteInfo?.voteFor === "boy" }, { "cardBtnForGirl": voteInfo?.voteFor === "girl" }, { "btnSelectedForGirl": donationMethod === "stripe" && voteInfo?.voteFor === "girl" })}
                                            onClick={() => {
                                                setDonationMethod("stripe")
                                                setError(null)
                                            }}
                                        >
                                            Card
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={11} lg={11} xl={11}>
                                        <PayPalScriptProvider options={paypalScriptOptions}>
                                            <Paypal handlePayment={handleVotingWithDonation} amount={donationAmount === "other" ? parseFloat(customDonationAmount) + calculateServiceFee(customDonationAmount) : parseFloat(donationAmount) + calculateServiceFee(donationAmount)} styles={{ height: width > 450 ? 55 : 45, width: width > 450 ? 210 : 100 }} />
                                        </PayPalScriptProvider>
                                    </Col>
                                </Row>

                            </Col>

                            {!!error?.donationMethodError &&
                                <Col span={24}>
                                    <Paragraph className="errorMessage" style={{ marginTop: "15px", marginBottom: "-10px" }}>{error?.donationMethodError}</Paragraph>
                                </Col>
                            }

                            {
                                donationMethod === "stripe" &&
                                <Col span={24} className="cardDetailsSection">
                                    <Input
                                        className={classNames('castingVoteModalInput', { "castingVoteModalInputForBoy": voteInfo?.voteFor === "boy" }, { "castingVoteModalInputForGirl": voteInfo?.voteFor === "girl" })}
                                        placeholder='Name on card'
                                        onChange={(e: any) => {
                                            setNameOnCard(e?.target?.value)
                                            setError(null)
                                        }}
                                    />
                                    {!!error?.nameOnCardError &&
                                        <Col span={24}>
                                            <Paragraph className="errorMessage" style={{ marginTop: "15px", marginBottom: "-10px" }}>{error?.nameOnCardError}</Paragraph>
                                        </Col>
                                    }

                                    <Row className="card">
                                        <Col span={24}>
                                            <label className="customLabel">Card Number <span className='error'>*</span></label>
                                            <CardNumberElement options={{ placeholder: "Enter card number", style: { base: { fontSize: width > 450 ? "18px" : "14px", fontFamily: 'Poppins Regular', color: "#262626" } } }} />
                                        </Col>
                                        <Col span={12}>
                                            <label className="customLabel">Expiration Date <span className='error'>*</span></label>
                                            <CardExpiryElement options={{ placeholder: "-------- ----", style: { base: { fontSize: width > 450 ? "18px" : "14px", fontFamily: 'Poppins Regular', color: "#262626" } } }} />
                                        </Col>
                                        <Col span={12}>
                                            <label className="customLabel">Security Code/CVC <span className='error'>*</span></label>
                                            <CardCvcElement options={{ placeholder: "Enter security code/cvc", style: { base: { fontSize: width > 450 ? "18px" : "14px", fontFamily: 'Poppins Regular', color: "#262626" } } }} />
                                        </Col>
                                    </Row>
                                    {!!error?.cardDetailError &&
                                        <Col span={24}>
                                            <Paragraph className="errorMessage" style={{ marginTop: "15px", marginBottom: "-10px" }}>{error?.cardDetailError}</Paragraph>
                                        </Col>
                                    }

                                </Col>
                            }

                            <Col span={24}>
                                <Paragraph className="donationAmountTitle">Total donation amount</Paragraph>
                            </Col>

                            <Col span={24}>
                                <Paragraph className="donationAmount">
                                    ${donationAmount === "other" ? parseFloat(customDonationAmount) + calculateServiceFee(customDonationAmount) : parseFloat(donationAmount) + calculateServiceFee(donationAmount)}&nbsp;
                                    <small className="small">
                                        ${donationAmount === "other" ? customDonationAmount : donationAmount} donation + ${calculateServiceFee(donationAmount === "other" ? customDonationAmount : donationAmount).toFixed(2)} fee
                                    </small>
                                </Paragraph>
                            </Col>

                            {/* <Divider /> */}

                            <Col span={24}>
                                <div className="submitVoteBtnSection">
                                    <Button
                                        className={classNames("btn", { "btnForBoy": voteInfo?.voteFor === "boy" },
                                            { "btnForGirl": voteInfo?.voteFor === "girl" })}
                                        onClick={handleSubmitVote}
                                    >
                                        {isLoading ? <Spinner /> : "Donate"}
                                    </Button>
                                    <Button
                                        style={{color: voteInfo?.voteFor === "boy"? "#5263FF" : "#CC8DFF"}}
                                        className={classNames("btn")}
                                        onClick={() => {
                                            setVoteFor("free");
                                            setStep(2)
                                        }}
                                    >
                                        Skip
                                    </Button>
                                    {/* <Paragraph className="terms">
                                        By Continuing You Agree To Our Terms & Conditions
                                    </Paragraph> */}
                                </div>
                            </Col>
                        </>
                    }

                    {
                        step === 2 && voteFor === "free" &&
                        <>
                            <Form
                                name="updateUserInfoForm"
                                onFinish={handleFreeVoting}
                                autoComplete="off"
                                form={form}
                            >
                                <Row>
                                    <Col span={24}>
                                        <Paragraph 
                                            className="backButton"
                                            onClick={() => {
                                                setVoteFor(data?.game?.raise_funds === 1 ? "cash" : null)
                                                setStep(data?.game?.raise_funds === 1 ? 2 : 1)
                                            }}
                                        >
                                            <LeftOutlined /> Back
                                        </Paragraph>
                                    </Col>
                                    <Col span={24}>
                                        <Paragraph className="donationTitle">1 vote for {voteInfo?.voteFor}</Paragraph>
                                    </Col>
                                    <Col span={24}>
                                        <CustomInput
                                            name="name"
                                            placeholder='Your name (first & last)'
                                            required
                                            classes={[voteInfo?.voteFor === "boy" ? "castingVoteModalInputForBoy" : "castingVoteModalInputForGirl"]}
                                        />
                                    </Col>
                                    {
                                        ["United States", "USA",].includes(data?.country) ?
                                            <Col span={24}>
                                                <CustomInput
                                                    name="phone"
                                                    placeholder='Your phone number'
                                                    required
                                                    classes={[voteInfo?.voteFor === "boy" ? "castingVoteModalInputForBoy" : "castingVoteModalInputForGirl"]}
                                                    minLength={"10"}
                                                />
                                                <p className="impMessage" style={{color: voteInfo?.voteFor === "boy"? "#5263FF" : "#CC8DFF"}}>You will receive SMS updates on game and baby.</p>
                                            </Col>   
                                            :
                                            <Col span={24}>
                                                <CustomInput
                                                    name="email"
                                                    placeholder='Your email address'
                                                    required
                                                    classes={[voteInfo?.voteFor === "boy" ? "castingVoteModalInputForBoy" : "castingVoteModalInputForGirl"]}
                                                />
                                                <p className="impMessage" style={{color: voteInfo?.voteFor === "boy"? "#5263FF" : "#CC8DFF"}}>You will receive email updates on game and baby.</p>
                                            </Col>
                                    }
                                    
                                    <Col span={24}>
                                        <CustomInput
                                            name="relation_with_mom"
                                            placeholder='Your relation with mom e.g aunt, cousin etc'
                                            required
                                            classes={[voteInfo?.voteFor === "boy" ? "castingVoteModalInputForBoy" : "castingVoteModalInputForGirl"]}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <div className="submitVoteBtnSection" style={{marginTop: "10px"}}>
                                            <Button
                                                className={classNames("btn", { "btnForBoy": voteInfo?.voteFor === "boy" }, { "btnForGirl": voteInfo?.voteFor === "girl" })}
                                                htmlType="submit"
                                            >
                                                {isLoading ? <Spinner /> : "Submit Your Vote!"}
                                            </Button>
                                            <Paragraph className="terms">
                                                By Continuing You Agree To Our <a href="https://www.villie.com/terms" target='_blank' rel="noopener noreferrer">Terms & Conditions</a>
                                            </Paragraph>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    }

                    {
                        step === 3 &&
                        <Row className="voteSubmittedSection">
                            <Paragraph className="title">Vote Submitted</Paragraph>

                            <div className="voteButtonSection" style={{ marginTop: "0px" }}>
                                <div className="left">
                                    <div className={classNames("vote-button", { "forBoy": voteInfo?.voteFor === "boy" }, { "forGirl": voteInfo?.voteFor === "girl" })}>
                                        <img src={"../assets/white-bear.svg"} alt="icon-btn" />
                                        <span>It’s a {capitalize(voteInfo?.voteFor)}!</span>
                                    </div>
                                </div>
                            </div>

                            <Paragraph className="anonymousVote">Good luck!</Paragraph>
                        </Row>
                    }
                </Row>
            </Modal>
        </>
    )
}

export default CastingVoteModal;