import { UserService } from "../services/http/user"


export const updateUser = async (data: any) => {
    try {
        const response = await UserService.updateUser(data)

        return {status: "success", data: response?.data?.data}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}
