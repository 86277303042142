import React from 'react';
import Modal from 'shared/components/modal';
import AddRegistryForm from "../../components/addRegistryForm";

interface props {
    show: boolean
    close: any
    registry: any
    data: any
}

const EditRegistryModal: React.FC<props> = ({show, close, data, registry}) => {

    return (
        <>
            <Modal show={show} close={close} head={false} title={``}> 
                <AddRegistryForm 
                    data={data}
                    isUpdating
                    updatingData={registry}
                    successAction={() => close()}
                />
            </Modal>
        </>
    )
}

export default EditRegistryModal;