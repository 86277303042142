import { useNavigate } from "react-router-dom";
import "./styles.scss"
import React, { useEffect, useState } from 'react'
import BButton from "shared/components/bButton";
import Modal from 'shared/components/modal';
import Payments from "shared/composedComponents/payment";

interface props {
    show: any
    close: any
    website: any
    setRefresh: any
}

const CancelPremiumProducts: React.FC<props> = ({ show, close, website, setRefresh }) => {
    const remove_add_explore: any = new URLSearchParams(window.location.search).get(
		'remove_add_explore'
	);
    const navigate = useNavigate()
    const [showPayment, setShowPayment] = useState(false)

    const handleClose = () => {
        setShowPayment(false)
        close()
    }

    useEffect(() => {
        if(!!remove_add_explore){
            setShowPayment(true)
        }
    }, [])

    return (
        <Modal show={show} close={handleClose} destroyOnClose head={false} showCloseBtn={showPayment} title={``} classes={[showPayment ? "" : "cancelPremiumModal"]}>
            <div className="cancelPremiumProductsModalContainer">
                {
                    showPayment ?
                        <Payments 
                            website={website} 
                            donation={{amount: "9.99", description: "Parent_remove_ads_explore"}}
                            nextStep={() => {
                                handleClose()
                                navigate(`/${website?.slug}`)
                                setRefresh()
                            }}
                        />
                        :
                        <>
                            <span
                                className="btnDelete"
                                onClick={close}
                            >
                                <img src="../assets/delete.svg" alt="x" />
                            </span>
                            <p className="heading">Want to remove these?</p>
                            <p className="subheading">This is a premium feature. One-time fee of $10.99</p>
                            <BButton
                                text="Upgrade"
                                type="base3"
                                action={() => {
                                    setShowPayment(true)
                                }}
                            />
                        </>
                }
            </div>
        </Modal>
    )
}

export default CancelPremiumProducts