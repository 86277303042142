import "./styles.scss";
import React from 'react';
import { Popover as AntdPopover, Typography } from 'antd';
// import { CloseOutlined } from '@ant-design/icons';
import Button from "shared/components/button";
import Spinner from "../spinner";

const { Paragraph } = Typography

interface props {
    open?: boolean
    text?: string
    close?: any
    action?: any
    isLoading?: boolean
    children?: any
}

const Popover:React.FC<props> = ({children, open, close, isLoading, text, action}) => {

    return(
        <>
            <AntdPopover
                placement="bottom"
                // title={`Are you sure you want to set your memory visiblity as ${openPopup?.visibility}?`}
                content={
                <div className="popoverContainer">
                    {/* <div className="closeBtn">
                        <CloseOutlined onClick={close} className='icon'/>
                    </div> */}
                    <div className="content">
                        <Paragraph className="subtitle">{text}</Paragraph>
                        <div className="buttonBox">
                            <Button classes={["btns"]} text={isLoading? <Spinner/> : "Yes"} action={action}/>
                            <Button classes={["btns", "secondryBtn"]} text={"Cancel"} action={close} type="secondary"/>
                        </div>
                    </div>
                    
                </div>
                }
                visible={open}
            >
                {children}
            </AntdPopover>

            <AntdPopover/>
        </>
    )
}


export default Popover;