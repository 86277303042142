import classNames from "classnames";
import "./styles.scss";
import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import BButton from "../bButton";
import { useDispatch } from "react-redux";
import { setUpgradeModalVisible } from "shared/infra/redux/global/global";
import { isFreeTrialValid } from "shared/utils/helper";


interface props {
    styles?: React.CSSProperties;
    website: any
}

const NavbarEditButtons: React.FC<props> = ({styles, website}) => {
    const dispatch = useDispatch()
    const {editType} = useParams()
    const navigate = useNavigate()

    return (
        <div className="navbarEditButtonsContainer" style={styles}>
            <div className={classNames("editContentBtn" , {"isEditContentBtnSelected": editType === "content"})}
                onClick={() => navigate("/edit/content")}
                >
                <img src={`../assets/newIcons/edit-content${editType === "content" ? "-selected" : ""}.svg`} alt="Edit Content" /> Edit Content
            </div>
            <div className={classNames("editContentBtn" , {"isEditContentBtnSelected": editType === "design"})}
                onClick={() => navigate("/edit/design")}
                >
                <img src={`../assets/newIcons/edit-design${editType === "design" ? "-selected" : ""}.svg`} alt="Design" /> Design
            </div>
            <BButton
                classes={["upgradePlanBtn"]}
                text={website?.package === "tier1" || isFreeTrialValid(website?.free_trial_started_at) ? "Super Villie" : "Upgrade Plan"}
                icon={
                    <img className="icon" src={`../assets/newIcons/${website?.package === "tier1" || isFreeTrialValid(website?.free_trial_started_at) ? "super-villie" :"upgrade-icon"}.svg`} alt="heart" />
                }
                type={website?.package === "tier1" || isFreeTrialValid(website?.free_trial_started_at) ? "base8" : "base4"}
                action={() => {
                    dispatch(setUpgradeModalVisible({isUpdgradeModalVisible: true}))
                }}
                disabled={website?.package === "tier1" || isFreeTrialValid(website?.free_trial_started_at)}
                styles={{cursor: "pointer"}}
            />
        </div>
    );
}

export default NavbarEditButtons;
