import "./styles.scss";
import React from 'react';

interface props {
    children?: any
    styles?: any
    backgroundColor?: string
}

const Section:React.FC<props> = ({children, styles, backgroundColor}) => {

    return(
        <>
            <div className='section' style={{...styles, background: backgroundColor}}>
               <div>{children}</div>
            </div>
        </>
    )
}


export default Section;