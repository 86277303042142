import './styles.scss';
import React from 'react';
import classNames from 'classnames';
import { Col, Row} from 'antd';
import { formatDate, getFirstNameAndLastName } from 'shared/utils/helper';
import { useSelector } from 'react-redux';
import { getUser } from 'modules/user/redux/userSlice';
import { deleteNote } from 'modules/website/redux/operators';
import { Mixpanel } from 'shared/services/mixpanel';
import Notification from 'shared/components/notification';
import Spinner from 'shared/components/spinner';

interface props {
    note: any
    classes?: string[]
    website: any
    setRefresh: any
};


const Note: React.FC<props> = ({ classes=[], note, website, setRefresh }) => {
    const user = useSelector(getUser)
    const [isLoading, setIsLoading] = React.useState(false)

    const handleNoteDelete = async () => {
        setIsLoading(true)
        const response = await deleteNote(website?.id, note?.id)
        setIsLoading(false)

        if(response?.status === "success"){
            Mixpanel.track(
                "PARENT_delete_a_note",
                {
                    $name: user?.name,
                    $email: user?.email,
                    website_id: website?.id,
                    website_slug: website?.slug
                }
            )
            Notification("success", "Note deleted successfully")
            setRefresh(true)
        }
        else{
            Notification("error", response?.message)
        }
    }
    return (
        <Row 
            className={classNames("noteContainer", ...classes,)}
        >
            {/* <img className="topVector" src={`../assets/newIcons/note-top-vector.svg`}/> */}
            {/* <Col span={24}>
                <Paragraph classes={["name"]}>Second Ultrasound</Paragraph>
            </Col> */}
            <Col className='description' span={24}>
                {note?.note}
            </Col>
            <Col className='details'>
                <div className='left'>
                    {getFirstNameAndLastName(note?.user_name).first_name} 
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {formatDate(note?.created_at, "EEEE, MMMM dd")}
                </div>

                <div className='right'>
                    {!!user && user?.id === website?.user_id && 
                        <div
                            className='tag'
                            onClick={handleNoteDelete}
                        >
                            {isLoading? <Spinner classes={["spinnerForDelete"]} /> : "Delete"}
                        </div>
                    }
                </div>
            </Col>
            {/* <img className="bottomVector" src={`../assets/newIcons/note-bottom-vector.svg`}/> */}
        </Row>
    )
}

export default Note;