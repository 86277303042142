import "./styles.scss"
import React from 'react';
import { Form, Input  } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import classNames from "classnames";

interface props {
    label?: string,
    name: string,
    placeholder?: string,
    minLength?: any
    fixLength?: number
    tooltip?: any
    required?: boolean
    errorMessage?: string
    classes?: any
    isRounded?: boolean
    minLengthFieldName?: any
    fixLengthFieldName?: any
    rules?: any
}

const CustomInput: React.FC<props> = ({label, name, placeholder, classes=[], minLength=false, minLengthFieldName=null, fixLength=false, fixLengthFieldName=null, rules=false, tooltip=null, required=false, errorMessage, isRounded=false}) => {
    
    const generateRules = () => {
        const rule = []

        if(required){
            rule.push({ required: true, message: errorMessage ? errorMessage : "This is required" })
        }

        if(minLength){
            rule.push({
                validator: (_: any, value: any) => {
                    if (!value) {
                        return Promise.reject(false)
                    }
                    if (isNaN(value) || value.trim() === "") {
                        return Promise.reject(`${!minLengthFieldName ? "Phone number" : minLengthFieldName} must be numeric.`)
                    }
                    if ((value.trim()).length < minLength) {
                        return Promise.reject(`${!minLengthFieldName ? "Phone number" : minLengthFieldName} length must greater than ${minLength-1}.`)
                    }
                    return Promise.resolve(true)
                }
            })
        }

        if(fixLength){
            rule.push({
                validator: (_: any, value: any) => {
                    if (!value) {
                        return Promise.reject(false)
                    }
                    if (isNaN(value) || value.trim() === "") {
                        return Promise.reject(`${fixLengthFieldName} must be numeric.`)
                    }
                    if ((value.trim()).length !== fixLength) {
                        return Promise.reject(`${fixLengthFieldName} must be ${fixLength} digits.`)
                    }
                    return Promise.resolve(true)
                }
            })
        }

        if(!!rules){
            rules?.map((r: any) => rule.push(r))
        }

        return rule
    }

    return(
        <Form.Item
            label={label}
            name={name}
            rules={generateRules()}
            className="newInputContainer"
            tooltip={!!tooltip && {
                title: tooltip,
                icon: <InfoCircleFilled style={{color: "#463649"}}/>,
            }}
        >
            <Input className={classNames('input', ...classes, {"isInputRounded": isRounded})} placeholder={placeholder}/>
        </Form.Item>
    );
}

export default CustomInput;