import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialAuthState from './states';
import type { OnBoardingState } from "./states"
import { RootState } from '../reducers';

export const onboardingSlice = createSlice({
    name: 'onBoarding',
    initialState: initialAuthState,
    reducers: {
        setOnBoardingStep:(state: OnBoardingState, action: PayloadAction<{onBoardingStep: any}>) => {
            state.onBoardingStep = action.payload.onBoardingStep;
        },
        setAddOnsAdded:(state: OnBoardingState, action: PayloadAction<{addOns: any}>) => {
            state.addOns = action.payload.addOns;
        },
        setOnBoardingData:(state: OnBoardingState, action: PayloadAction<{onBoardingData: any}>) => {
            state.onBoardingData = action.payload.onBoardingData;
        },
        resetOnBoardingState: (state: OnBoardingState) => {
            state.onBoardingStep = 1
            state.addOns = []
            state.onBoardingData = null
        },
    },
});

export const getAddOnsAdded = (state: RootState) => state.onBoarding.addOns;
export const getOnBoardingStep = (state: RootState) => state.onBoarding.onBoardingStep;
export const getOnBoardingData = (state: RootState) => state.onBoarding.onBoardingData;

export const { setOnBoardingStep, setAddOnsAdded, setOnBoardingData, resetOnBoardingState } = onboardingSlice.actions;

export default onboardingSlice.reducer;