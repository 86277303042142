import React from "react";
import { Col, Row } from 'antd';
import {PayPalButtons, usePayPalScriptReducer, FUNDING} from "@paypal/react-paypal-js";

interface props {
    handlePayment: any,
    amount: number,
    styles?: any
}

const Paypal:React.FC<props> = ({handlePayment, amount, styles}) => {
	const [{ isPending }] = usePayPalScriptReducer();

    const paypalbuttonTransactionProps: any = {
        fundingSource: FUNDING.PAYPAL,
        style: {layout: "vertical", ...styles},
        createOrder(_: any, actions: any) {
          return actions.order.create({
            application_context: {
                brand_name: 'Villie Platform',
                user_action: 'PAY_NOW'
            },
            purchase_units: [
                {
                    amount: {
                        currency_code: 'USD',
                        value: amount
                    },
                    description: "",
                    custom_id: "",
                    soft_descriptor: 'Upgrade village',
                }
            ]
          });
        },
        onApprove(_: any, actions: any) {
            return actions.order.capture().then(async function (details: any) {
                if (details.status === 'COMPLETED') {
                    await handlePayment({payment_id: details.id, status: "success"})
                }
            })
        }
      };

	return (
		<>	
			<Row>
				<Col span={24}>
                    {isPending ? <p>Loading paypal button</p> : null}
                    <PayPalButtons {...paypalbuttonTransactionProps} disabled={!(amount > 0)} />
				</Col>
			</Row>
		</>
	);
};

export default Paypal;