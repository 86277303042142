import React, { useEffect, useState } from 'react'
import './styles.scss'
import BButton from 'shared/components/bButton'
import Spinner from 'shared/components/spinner';
import { answerGuessWhoGame } from 'modules/website/redux/operators';
import Notification from 'shared/components/notification';
import { Mixpanel } from 'shared/services/mixpanel';
import { QuestionOutlined } from '@ant-design/icons';

interface props {
    website: any
}

const GuessWhoGame: React.FC<props> = ({ website }) => {
    const [gameStarted, setGameStarted] = useState(false)
    const [isQuestionVisible, setIsQuestionVisible] = useState(false)
    const [answerSelected, setAnswerSelected] = useState<null | string>(null)
    const [questions, setQuestions] = useState<any>([])
    const [currentQuestion, setCurrentQuestion] = useState(1)
    const [showIndivisualResult, setShowIndivisualResult] = useState(false)
    const [_, setIndivisualResult] = useState<any>(null)
    const [error, setError] = useState<any>(null)
    const [showResult, setShowResult] = useState<any>(null)
    const [submitAnswers, setSubmitAnswers] = useState<any>([])
    const [isLoading, setIsLoading] = useState(false)

    const handleSubmit = async () => {
        if (isQuestionVisible) {
            if (!answerSelected) return setError("Please select an answer")

            // also store the answer because at the end we need to store the answers into db
            setSubmitAnswers([...submitAnswers, {
                question: questions[currentQuestion - 1]?.question,
                question_id: questions[currentQuestion - 1]?.id,
                parent_one: answerSelected === "parent one",
                parent_two: answerSelected === "parent two"
            }])
            // Show the results for each question
            setIndivisualResult(website?.guessWho?.results?.questionDetails[questions[currentQuestion - 1]?.question])
            setShowIndivisualResult(true)
            setIsQuestionVisible(false)

        }
        else if (!isQuestionVisible && currentQuestion < questions.length) {
            setShowIndivisualResult(false)
            setIsQuestionVisible(true)
            setAnswerSelected(null)
            setCurrentQuestion(currentQuestion + 1)
        }
        else if (currentQuestion === questions.length) {
            setIndivisualResult(null)
            setIsLoading(true)
            const response = await answerGuessWhoGame({ website_id: website?.id, answers: submitAnswers })
            setIsLoading(false);

            if (response?.status === "success") {
                setShowResult(response?.data)
                setIsQuestionVisible(false)
                Notification("success", `Answer submitted successfully successfully`);
                Mixpanel.track("VILLAGER_played_guess_who");
            } else {
                Notification("error", response?.message);

            }

        }
    }

    const handleAnswerSelection = (answer: string) => {
        setError(null)
        setAnswerSelected(answer)
    }

    useEffect(() => {
        setQuestions(website?.guessWho?.questions)
    }, [website])

    const getProgressPercentage = () => {

        if(!!showResult){
            const percentage = (showResult?.totalVotesParentOne / showResult?.totalVotes) * 100 

            if(percentage === 0) return "5%"
            else if(percentage === 100) return "98%"
            else return percentage + "%"
        }
        else {
            const individualQuestionResults = (website?.guessWho?.results.questionDetails[questions[currentQuestion - 1]?.question])
            let parent_one_votes = individualQuestionResults?.parentOneVotes
            let total_votes = individualQuestionResults?.totalVotes

            if(parent_one_votes == undefined && total_votes == undefined){
                parent_one_votes = answerSelected === "parent one" ? 1 : 0
                total_votes = 1
            }
            else{
                parent_one_votes += answerSelected === "parent one" ? 1 : 0
                total_votes += 1
            }

            const percentage = (
                parent_one_votes / total_votes) * 100 

            if(percentage === 0) return "5%"
            else if(percentage === 100) return "95%"
            else return percentage + "%"
        }
    }
    
    return (
        <div className='guessWhoContainer'>
            {
                gameStarted && questions?.length > 0 ?
                    <>
                        {
                            showResult ?
                                <div className='result'>
                                    <div className='box'>
                                        <img src="../assets/newIcons/parent-one.svg" alt="parent one" />
                                        <p className='text'>{showResult?.overallWinner === "Parent One"? website?.guessWho?.game?.parent_one : showResult?.overallWinner === "Parent Two"? website?.guessWho?.game?.parent_two : showResult?.overallWinner}</p>
                                    </div>
                                </div>
                                :
                                <>
                                    <div className='topRow'>
                                        {false && <img src="../assets/newIcons/back.svg" alt="<" />}
                                        <div className='questionNumber'>
                                            Question {currentQuestion}
                                        </div>
                                    </div>

                                    <div className='progressBar'>
                                        <div className='internalBar' style={{width: `${20 * currentQuestion}%` }}></div>
                                    </div>
                                    <div className='question'>{questions[currentQuestion - 1]?.question}</div>
                                </>
                        }

                        {
                            showIndivisualResult ?
                                <div className="chartSectionArea">
                                    <div className='bar'>
                                        <div className='internalBar' style={{width: `${getProgressPercentage()}`}}></div>
                                    </div>
                                    <div className='parentName'>
                                        <p>{website?.guessWho?.game?.parent_one}</p>
                                        <p>{website?.guessWho?.game?.parent_two}</p>
                                    </div>
                                </div>

                                :
                                <>
                                    <div className='selectionBox'>
                                        <div className='select'
                                            style={{ background: answerSelected === "parent one" ? "#CC8DFF" : "" }}
                                            onClick={() => handleAnswerSelection("parent one")}
                                        >
                                            <QuestionOutlined className='iconImage' style={{color: "#9745DA"}}/>
                                            {/* <img src="../assets/newIcons/parent-one.svg" alt="parent one" /> */}
                                            <p>{website?.guessWho?.game?.parent_one}</p>
                                        </div>
                                        <div className='select'
                                            style={{ background: answerSelected === "parent two" ? "#CC8DFF" : "" }}
                                            onClick={() => handleAnswerSelection("parent two")}
                                        >
                                            <QuestionOutlined className='iconImage' style={{color: "#A3D080"}}/>
                                            {/* <img src="../assets/newIcons/parent-two.svg" alt="parent one" /> */}
                                            <p>{website?.guessWho?.game?.parent_two}</p>
                                        </div>
                                    </div>
                                    {error && <div className='error'>{error}</div>}
                                </>
                        }

                        
                        {
                            showResult &&
                            <div className='showAllResults'>
                                <p className='heading'>All Answers:</p>
                                <div className='box'>
                                    {Object.entries(showResult?.questionDetails)?.map(([question, details]: any, index) => (
                                        <div className='individual' key={index}>
                                            <p className='q'>{question}</p>
                                            <p className='winner'>{details.winner === "Parent One"? website?.guessWho?.game?.parent_one : details.winner === "Parent Two"? website?.guessWho?.game?.parent_two : details?.winner}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }

                    </>
                    :
                    <>
                        <div className='title'>Guess Who!?</div>
                        <div className='subtitle'>{website?.guessWho?.game?.parent_one} or {website?.guessWho?.game?.parent_two}</div>
                        <div className='paragraph'>Guess which parent the baby will be more like.</div>
                        <div className='paginations'>
                            <img src="../assets/newIcons/guess-who-pagination.svg" alt="guess who" />
                        </div>
                    </>
            }

            {
                !showResult &&
                <div className='bottomBtn'>
                    <BButton
                        type="base3"
                        text={isLoading ? <Spinner /> : !gameStarted ? "START GAME!" : isQuestionVisible ? "Submit" : currentQuestion < questions.length ? "Next Question" : "See Results"}
                        styles={{ width: "100%" }}
                        action={() => {
                            if (!gameStarted) {
                                setGameStarted(true)
                                setIsQuestionVisible(true)
                            }
                            else {
                                handleSubmit()
                            }

                        }}
                    />
                </div>

            }

        </div>
    )
}

export default GuessWhoGame