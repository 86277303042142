import React from "react";

export const Fonts: React.FC = () => {
	return (
		<style jsx={"true"} global={"true"}>{`
		@font-face {
			font-family: 'Poppins Bold';
			src: url('/fonts/Poppins-Bold.woff');
			font-display: swap; /* Define how the browser behaves during download */
		}
		@font-face {
			font-family: 'Poppins Medium';
			src: url('/fonts/Poppins-Medium.woff');
			font-display: swap; /* Define how the browser behaves during download */
		}
		@font-face {
			font-family: 'Poppins Regular';
			src: url('/fonts/Poppins-Regular.woff');
			font-display: swap; /* Define how the browser behaves during download */
		}
		@font-face {
			font-family: 'Poppins SemiBold';
			src: url('/fonts/Poppins-SemiBold.woff');
			font-display: swap; /* Define how the browser behaves during download */
		}
		@font-face {
			font-family: 'Recoleta Bold';
			src: url('/fonts/Recoleta-Bold.woff');
			font-display: swap; /* Define how the browser behaves during download */
		}
	`}</style>
	)
}