import { app } from 'shared/infra/config';
import Share from '../share';
import './styles.scss';
import React, { useState } from 'react';
import BButton from '../bButton';
import Paragraph from 'shared/components/paragraph';
import { isChecklistTaskCompleted } from 'shared/utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { getWebsiteSettings, setWebsiteRefresh } from 'shared/infra/redux/global/global';
import CustomizeUrlModal from '../customizeUrlModal';


interface props {
    data: any;
}
const ShareBox: React.FC<props> = ({ data }) => {
    const dispatch = useDispatch()
    const checklist = useSelector(getWebsiteSettings)?.checklist
    const [isCustomModalVisible,setIsCustomModalVisible] =useState(false)

    return (
        <>
            <div className="shareBox">
                <div className="text-and-edit-button">
                    <div className="text">{`${app?.APP_PUBLIC_URL}${data?.slug}`}</div>

                    <BButton
                        icon={
                            <>
                            {
                                !isChecklistTaskCompleted(checklist, "Update URL") &&
                                <img
                                    src="/assets/newIcons/1-diamond.svg"
                                    alt="blue-diamond"
                                    className="diamond-width"
                                />
                            }
                            </>
                        }
                        type="base3"
                        text={'Edit'}
                        classes={['edit-button']}
                       action={()=>setIsCustomModalVisible(true)}
                        
                    />  
                </div>
            </div>

            <div className="shareBox">
                <Paragraph classes={["title"]}>Share Your Baby Page 
                    {
                        !isChecklistTaskCompleted(checklist, "Share Your Baby Page") &&
                        <img
                            src="/assets/newIcons/5-diamond.svg"
                            alt="red-diamond"
                            className="diamond-width"
                        />
                    }
                </Paragraph>
                <Paragraph classes={["helper"]}>Share to get more support!</Paragraph>
                <Share data={data} />
            </div>

            <CustomizeUrlModal
                isCustomModalVisible={isCustomModalVisible}
                close={()=>setIsCustomModalVisible(false)}
                data={data}
                actionAfterSuccess={() => dispatch(setWebsiteRefresh({refresh: true}))}
            />
        </>
    );
};
export default ShareBox;
