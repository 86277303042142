import "./styles.scss";
import React from 'react';
import { Row, Col } from "antd";
import Modal from 'shared/components/modal';
import Heading from "shared/components/heading";
// import useWindowSize from "shared/hooks/use-window-size";
import PhotoCollage from "shared/components/photoCollage";
// import Input from "shared/components/binput";

interface props {
    photos: any
    show: boolean
    close: any
}

const ViewVilliePhotosModal: React.FC<props> = ({ show, close, photos }) => {
    // const {width} = useWindowSize()

    return (
        <>
            <Modal show={show} close={close} head={false} title={``} classes={["viewVilliePhotosModal"]}>
                <div className="viewVilliePhotosModalContainer">
                    <Row className="viewVilliePhotosModalSection" >
                        <Col span={24}>
                            <Heading classes={["heading"]} text={<>Photos</>}/>
                        </Col>
                        <Col span={24}>
                        <PhotoCollage forModal 
                            photos={photos}
                        />

                        {/* <Row justify="space-between" gutter={width > 450 ? [0, 20] : [0 , 10]}>
                            <Col span={24} style={{background: "red"}}>
                            </Col> */}
                            {/* <Col>
                                <PhotoCollage forModal photos={photos}/>
                            </Col> */}
                        {/* </Row> */}
                            
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    )
}

export default ViewVilliePhotosModal;