import "./styles.scss";
import React, { useEffect, useState } from 'react';
import Modal from 'shared/components/modal';
import { Row, Col, Table, Alert, Space, Tooltip } from 'antd';
import AddGuestPhoneNumberModal from 'modules/website/pages/newDashboard/components/addGuestPhoneNumberModal';
import { PhoneFilled, DeleteFilled } from '@ant-design/icons';
import Popover from "shared/components/popover";
import { deleteGuest } from "modules/website/redux/operators";
import Notification from "shared/components/notification";

interface props {
    show: boolean
    close: any
    website: any
    isAddPhoneNumberModalVisible: any
    setIsAddPhoneNumberModalVisible: any
}

const ViewSubscribersModal: React.FC<props> = ({ show, close, website, setIsAddPhoneNumberModalVisible, isAddPhoneNumberModalVisible }) => {
    const [openPopup, setOpenPopup] = useState(null)
    const [isLoading, setIsLoaidng] = useState(false)
    const [subscribers, setSubscribers] = useState<any>([])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Relation",
            dataIndex: 'relation_with_mom',
            key: 'relation_with_mom'
        },
        // {
        //     title: 'Phone',
        //     dataIndex: 'phone',
        //     key: 'phone',
        // },
        {
            key: 'action',
            render: (_: any, record: any) => (
                <>
                    <Space size={"large"}>
                        <Tooltip title={`${record?.phone}`}>
                            <div className='btn'><PhoneFilled className="icon" style={{transform: 'rotate(90deg)'}}/></div>
                        </Tooltip>
                        {
                            !!(record?.updated_at) ?
                            <Tooltip title={`${record?.name} has also voted in your game, so you cannot delete this.`}>
                                <div className='btn'><DeleteFilled className="icon" /></div>
                            </Tooltip>
                            :
                            <Popover
                                text={`Are you sure you want to delete ${record?.name}?`}
                                open={openPopup === record?.id}
                                close={() => setOpenPopup(null)}
                                action={() => handleGuestDelete(record)}
                                isLoading={isLoading}
                            >
                                <div className='btn' onClick={() => setOpenPopup(record?.id)}><DeleteFilled className="icon" /></div>
                            </Popover>
                        }
                        
                    </Space>
                </>
            ),
          },
    ];

    const handleGuestDelete = async (data: any) => {
        setIsLoaidng(true)
        const response = await deleteGuest(data?.website_id, data?.id)
        setIsLoaidng(false)

        if(response.status === "success"){
            setOpenPopup(null)
            setSubscribers(subscribers.filter((subscriber: any) => subscriber.id !== data.id));
        }
        else{
            Notification("error", "These is error. Try again later.")
        }
    }

    const handleModalClose = () => {
        setOpenPopup(null)
        setTimeout(() => {
            close();
        }, 0);
    }

    useEffect(() => {
        setSubscribers([...[...website?.guests ?? []]])
    }, [])

    return (
        <>
            <Modal show={show} close={handleModalClose} head={false} title={`Your Villagers (${subscribers?.length ?? 0})`}>
                <Row className='viewSubscribersModalContainer'>
                    <Col span={24} style={{marginBottom: "30px"}}>
                        <Alert 
                            description={<> 
                                <a style={{textDecoration: "underline"}} type="primary" 
                                    onClick={() => {
                                        close()
                                        setIsAddPhoneNumberModalVisible(true)
                                    }}
                                >
                                    Click here to add more friends and family.
                                </a>
                                </>

                            }
                            type="success" 
                        />
                    </Col>
                    <Col span={24}>
                        <Table 
                            columns={columns} 
                            dataSource={[...subscribers]?.sort(
                                (a, b) => b.created_at.localeCompare(a.created_at)
                              )} 
                            pagination={false}
                            scroll={{ x: 240 }}
                        />
                    </Col>
                </Row>
            </Modal>

            <AddGuestPhoneNumberModal
                data={{website: { id: website?.id }}}
                show={isAddPhoneNumberModalVisible}
                close={() => setIsAddPhoneNumberModalVisible(false)}
                addedByMom={true}
            />
        </>
    )
}

export default ViewSubscribersModal;