import classNames from "classnames";
import "./styles.scss"
import React, { useEffect, useState } from 'react';

interface props {
    items?: any
    styles?: any
    defaultActivePanel?: any
    closingCollapse?: boolean
    setClosingCollapse?: any
}

const Collapse: React.FC<props> = ({items, defaultActivePanel = null, closingCollapse = false, setClosingCollapse}) => {
    const [activePanel, setActivePanel] = useState<any>(null)

    const handleClose = (obj: any) => {
        activePanel === obj?.id ?
            setActivePanel(null) : setActivePanel(obj.id)
    }

    useEffect(() => {
        setActivePanel(defaultActivePanel);
    }, [defaultActivePanel]);

    useEffect(() => {
        if(closingCollapse){
            setActivePanel(null)
            setClosingCollapse(false)
        }
    }, [closingCollapse])

    return (
        <>
        <div className='collapseContainer'>
            {
                items?.map((item: any) => (
                    <div className='panel' key={item?.id}>
                        <div className="topPanel" onClick={() => item?.dontClose ? null : handleClose(item)}>
                            <p className='headerPanel'>{item?.header}</p> 
                            {
                                item?.comingSoon ?
                                <p className="comingSoon">Coming Soon</p>
                                :
                                !item?.hideExpandIcon &&
                                <img 
                                    className='iconPanel'
                                    src={`../assets/newIcons/collapse-${activePanel === item?.id ? "close" : "open"}.svg`} 
                                />
                            }
                            
                        </div>
                        {activePanel === item.id && !item?.comingSoon && (
                        <div className={classNames("bottomPanel", {"showBottomPanel": true})}>
                            <p className="descriptionPanel">{item.description}</p>
                            {item.child}
                        </div>
                    )}
                    </div>
                ))
            }
        </div>
        </>
        

    )
}


export default Collapse;