import "./styles.scss";
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd';
import Section from "shared/components/section";
import Heading from "shared/components/heading";
import Note from "shared/composedComponents/note";
import Button from "shared/components/bButton";
import NotesDisplayModal from "../../modals/notesDisplayModel";
import useWindowSize from "shared/hooks/use-window-size";
import { getNotesByWebsiteSlug } from "modules/website/redux/operators";
import Skeleton from "shared/components/skeleton";
import { getBtnBackgroundColor } from "shared/utils/helper";
import { getTextColor } from "shared/utils/helper";

interface props{
    website: any
    color?: string
    increaseIndex?: any
}

const NotesSection: React.FC<props> = ({website, color, increaseIndex}) => {
    const {width} = useWindowSize()
    const [isNotesDisplayModalVisible, setIsNotesDisplayModalVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [notes, setNotes] = useState([])
    const [refresh, setRefresh] = useState(false)
    
    const fetchNotes = async (websiteId: string, slug: string) => {
        setIsLoading(true)
        const response = await getNotesByWebsiteSlug(websiteId, slug)
        setIsLoading(false)

        if (response?.status === "success") {
            setNotes(response?.data?.notes)
        }
    }

    useEffect(() => {
        fetchNotes(website?.id, website?.slug)
    }, [])

    useEffect(() => {
        if (refresh) {
            fetchNotes(website?.id, website?.slug)
            setRefresh(false)
        }
    }, [refresh])

    if(notes?.length === 0) {
        return <></>
    }
    else{
        increaseIndex()
    }

    return (
        <>
             <Section backgroundColor={color}>
                {
                    isLoading ?
                    <Skeleton title paragraph />
                    :
                    <Row className='notesSectionContainer' gutter={[0,20]}>
                        <Col span={24} style={{textAlign: width > 450 ? "center" : "left"}}>
                            { 
                                <Heading 
                                    text={"Notes + Love Letters"}
                                    styles={getTextColor(website?.website_color, color)}
                                />
                            }
                        </Col>
                        {notes.slice(0, 6).map((note: any) => (
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} key={note?.id}>
                                <Note note={note} website={website} setRefresh={setRefresh}/>
                            </Col>
                        ))}
                        <Col span={24} className="lastRow">
                            <Button 
                                classes={["btn"]}
                                styles={getBtnBackgroundColor(website?.website_color, color)} type="theme" text="View all Notes" action={() => setIsNotesDisplayModalVisible(true)}
                            />
                        </Col>
                    </Row>
                }
            </Section>

            <NotesDisplayModal 
                data={{website, notes}}
                show={isNotesDisplayModalVisible}
                close={() => setIsNotesDisplayModalVisible(false)}
                setRefresh={setRefresh}
            />
        </>
    )
}

export default NotesSection