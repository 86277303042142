import React, { useEffect, useState } from 'react';
import './style.scss';
import { Form } from 'antd';
import Input from 'shared/components/binput';
import { app } from 'shared/infra/config';
import Button from 'shared/components/bButton';
import Tooltip from 'shared/components/tooltip';
import { useSelector } from 'react-redux';
import Spinner from 'shared/components/spinner';
import { customizeWebsiteUrl } from 'modules/website/redux/operators';
import Notification from 'shared/components/notification';
import HelperText from 'shared/components/helperText';
import CustomModal from 'shared/components/modal';
import { isChecklistTaskCompleted, updateChecklistByInfoAndStringify } from 'shared/utils/helper';
import { addWebsiteSetting } from 'modules/website/redux/operators';
import { getWebsiteSettings } from 'shared/infra/redux/global/global';
import { PostHog } from 'shared/services/posthog';

interface props {
    isCustomModalVisible: any;
    close: any;
    data: any;
    actionAfterSuccess?: any
}

const CustomizeUrlModal: React.FC<props> = ({ isCustomModalVisible, close, data, actionAfterSuccess = () => {} }) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const checklist = useSelector(getWebsiteSettings)?.checklist;
    const [url, setUrl] = useState('');

    useEffect(() => {
        if (!!data?.slug) {
            setUrl(data.slug);
        }
    }, [data]);

    const handleUrlChange = (e: any) => {
        setUrl(e.target.value);
    };
    
    const handleCustomization = async () => {
        if (url?.length === 0) {
            return 0;
        }

        setIsLoading(true);
        const response = await customizeWebsiteUrl(data?.id, { slug: url });
        setIsLoading(false);

        if (response?.status === 'success') {
            Notification('success', response?.message);

            if (!isChecklistTaskCompleted(checklist, 'Update URL')) {
                const payload = {
                    setting_key: 'checklist',
                    setting_value: updateChecklistByInfoAndStringify(
                        checklist?.setting_value,
                        'Update URL'
                    ),
                };

                await addWebsiteSetting(data?.id, payload);
                PostHog.track("update_domain_gem", {})
            }

            actionAfterSuccess()
            form.resetFields();
            close();

        } else {
            setError(response?.message);
        }
    };

    return (
        <>
            <CustomModal
                show={isCustomModalVisible}
                close={() => close(false)}
                width={600}
                title=""
                showCloseBtn={true}
                maskClosable={true}
                destroyOnClose={true}
            >
                <Form
                    name="slugEditForm"
                    onFinish={handleCustomization}
                    className="customizeUrlModalContainer"
                >
                    <div>
                        <p className="title">
                            Let's customize your Villie link!&nbsp;
                            <Tooltip
                                placement="bottom"
                                content={[
                                    `Consider using the baby’s name or nickname.`,
                                    `Opt for your family’s last name, like "/BabySmith"`,
                                    `Let your creativity flow - make it memorable for your villagers!`,
                                ]}
                            >
                                <img src="../assets/icons/tooltip.svg" alt="tooltip" />
                            </Tooltip>
                        </p>
                    </div>
                    <div>
                        <p className="link">
                            {`${app?.APP_PUBLIC_URL?.replace('https://', '')}${url}`}
                        </p>
                    </div>
                    <Input
                        name="slug"
                        classes={['input']}
                        placeholder="Your custom URL ending"
                        onChange={handleUrlChange}
                        rules={[
                            {
                                required: true,
                                min: 6,
                                message: 'URL must be 6 or more letters and no spaces.',
                            },
                            {
                                pattern: /^[a-zA-Z0-9]+$/,
                                message:
                                    'Your link can only contain letters and numbers. No spaces.',
                            },
                        ]}
                    />
                    {!!error && (
                        <HelperText text={error} type="error" styles={{ marginTop: '-1rem' }} />
                    )}
                    <Button
                        text={isLoading ? <Spinner /> : 'Update'}
                        htmlType="submit"
                        type="base3"
                        classes={['w-100-p', 'm-tb-25-px']}
                    />
                </Form>
            </CustomModal>
        </>
    );
};

export default CustomizeUrlModal;
