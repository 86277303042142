// import mixpanel from 'mixpanel-browser'
// import {mixpanel as config} from "../infra/config"

// export namespace Mixpanel {

//     export const initialize = () => {
//         if(config?.MIXPANEL_KEY){
//             mixpanel.init(config?.MIXPANEL_KEY , { debug: true, track_pageview: true, persistence: 'localStorage' });
//         }
//     }

//     export const setIdentity = (userId: string) => {
//         mixpanel.identify(userId)
//     }

//     export const track = (eventName: string, params?: Record<string, any>) => {
//         mixpanel.track(eventName, {
//             ...params
//         })
//     };

//     export const setUserProfile = (name: string, email: string, obj?: any) => {
//         mixpanel.people.set({
//             $email: email,
//             $name: name,
//             ...obj
//         })
//     }

// }

// import mixpanel from 'mixpanel-browser'
// import {mixpanel as config} from "../infra/config"

// export namespace Mixpanel {

//     export const initialize = () => {
//         if(config?.MIXPANEL_KEY){
//             mixpanel.init(config?.MIXPANEL_KEY , { debug: true, track_pageview: true, persistence: 'localStorage' });
//         }
//     }

//     export const setIdentity = (userId: string) => {
//         mixpanel.identify(userId)
//     }

//     export const track = (eventName: string, params?: Record<string, any>) => {
//         mixpanel.track(eventName, {
//             ...params
//         })
//     };

//     export const setUserProfile = (name: string, email: string, obj?: any) => {
//         mixpanel.people.set({
//             $email: email,
//             $name: name,
//             ...obj
//         })
//     }

// }

import mixpanel from 'mixpanel-browser';
import { mixpanel as config } from "../infra/config";

export namespace Mixpanel {

    let initialized = false;

    const lazyInit = () => {
        if (!initialized && config?.MIXPANEL_KEY) {
            mixpanel.init(config?.MIXPANEL_KEY, { debug: true, track_pageview: true, persistence: 'localStorage' });
            initialized = true;
        }
    };

    export const initialize = () => {
        if (document.readyState === 'complete') {
            lazyInit();
        } else {
            window.addEventListener('load', lazyInit);
        }
    };

    export const setIdentity = (userId: string) => {
        if (!initialized) {
            lazyInit();
        }
        mixpanel.identify(userId);
    };

    export const track = (eventName: string, params?: Record<string, any>) => {
        if (!initialized) {
            lazyInit();
        }
        mixpanel.track(eventName, {
            ...params
        });
    };

    export const setUserProfile = (name: string, email: string, obj?: any) => {
        if (!initialized) {
            lazyInit();
        }
        mixpanel.people.set({
            $email: email,
            $name: name,
            ...obj
        });
    };
}
