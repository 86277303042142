import UpdateForm from "modules/website/pages/edit/components/updateForm";
// import "./styles.scss";
import React from 'react';
import Modal from 'shared/components/modal';
// import { addNote } from "modules/website/redux/operators";
// import Notification from "shared/components/notification";
// import { Mixpanel } from "shared/services/mixpanel";

interface props {
    show: boolean
    close: any
    editUpdate: any
    setData: any
    website: any
}


const EditUpdateModal: React.FC<props> = ({  show, close, editUpdate, website, setData }) => {
    return (
        <>
            <Modal show={show} close={close} head={false} title="Edit Update" destroyOnClose>
                <UpdateForm 
                    isUpdating={true} 
                    data={editUpdate} 
                    website={website} 
                    setData={setData}
                    handleFormClosing={close}
                />
            </Modal>
        </>
    )
}

export default EditUpdateModal;