// import "./styles.scss";
// import React from 'react';
// import { Image } from "antd";
// import { chunkArray } from "shared/utils/helper";
// import useWindowSize from "shared/hooks/use-window-size";

// interface props {
//     photos: any
//     forModal?: boolean
// }

// const PhotoCollage:React.FC<props> = ({photos, forModal = false}) => {
//     const {width} = useWindowSize()

//     return(
//         <>
//             {
//                 <div className="photoCollageContainer" style={{gridTemplateColumns: `repeat(auto-fill, minmax(${width <= 450 ? "45" : forModal? "24" : "20"}%, 1fr))`}}>
//                     {
//                         chunkArray([...photos,...photos,...photos], 2)?.map((c, columnIndex) => (
//                             <div className="column">
//                                 {c?.map((photo, index) => <Image className={`image${(columnIndex % 2 === 0) ? index + 1 : 2 - index}`} preview={forModal} src={photo?.img_url} />)}
//                             </div>
//                         ))
//                     }
//                 </div>
//             }
//         </>
//     )
// }


// export default PhotoCollage;


import "./styles.scss";
import React from 'react';
import { Row, Col, Image } from "antd";

interface props {
    photos: any
    forModal?: boolean
    overview?: boolean
    overviewCount?: number
}

const PhotoCollage: React.FC<props> = ({ photos, overview = false, overviewCount = 4, forModal = false }) => {
    return (
        <>
            <Row className="photoCollageContainer" justify="space-between" gutter={[0, 20]}>
                {
                    overview ?
                        photos?.slice(0, overviewCount)?.map((photo: any) => (
                            <Col xs={11} sm={11} md={6} lg={6} xl={6}>
                                <Image className="image1" preview={forModal} src={photo?.img_url} />
                            </Col>
                        ))
                        :
                        photos?.map((photo: any) => (
                            <Col xs={11} sm={11} md={7} lg={7} xl={7}>
                                <Image
                                    className="image1"
                                    preview={forModal}
                                    src={photo?.img_url} />
                            </Col>
                        ))
                }

                {/* <Col>
                        <Image className="image1" preview={forModal} src="https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072821_1280.jpg" />
                    </Col>
                    <Col>
                        <Image className="image1" preview={forModal} src="https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072821_1280.jpg" />
                    </Col>
                    <Col>
                        <Image className="image1" preview={forModal} src="https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072821_1280.jpg" />
                    </Col> */}
            </Row>
            {/* {
                width > 450 ?
                <div className="photoCollageContainer">
                    <div className="column">
                        <Image className="image1" preview={forModal} src="https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072821_1280.jpg" />
                        <Image className="image2" preview={forModal} src="https://cdn.pixabay.com/photo/2018/04/06/00/25/trees-3294681_1280.jpg" />
                    </div>
                    <div className="column">
                        <Image className="image2" preview={forModal} src="https://cdn.pixabay.com/photo/2017/04/09/09/56/avenue-2215317_1280.jpg" />
                        <Image className="image1" preview={forModal} src="https://cdn.pixabay.com/photo/2018/06/09/17/25/trees-3464777_1280.jpg" />
                    </div>
                    <div className="column">
                        <Image className="image1" preview={forModal} src="https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072821_1280.jpg" />
                        <Image className="image2" preview={forModal} src="https://cdn.pixabay.com/photo/2018/04/06/00/25/trees-3294681_1280.jpg" />
                    </div>
                    {
                        !forModal &&
                        <div className="column">
                            <Image className="image2" preview={forModal} src="https://cdn.pixabay.com/photo/2017/04/09/09/56/avenue-2215317_1280.jpg" />
                            <Image className="image1" preview={forModal} src="https://cdn.pixabay.com/photo/2018/06/09/17/25/trees-3464777_1280.jpg" />
                        </div>
                    }
                </div>
                :
                    <div className="photoCollageContainer">
                        <div className="column">
                            <Image className="image1" preview={forModal} src="https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072821_1280.jpg" />
                            <Image className="image2" preview={forModal} src="https://cdn.pixabay.com/photo/2018/04/06/00/25/trees-3294681_1280.jpg" />
                        </div>
                        <div className="column">
                            <Image className="image2" preview={forModal} src="https://cdn.pixabay.com/photo/2017/04/09/09/56/avenue-2215317_1280.jpg" />
                            <Image className="image1" preview={forModal} src="https://cdn.pixabay.com/photo/2018/06/09/17/25/trees-3464777_1280.jpg" />
                        </div>
                    </div>
            } */}

        </>
    )
}


export default PhotoCollage;