import "./styles.scss";
import React from 'react';
import { useNavigate } from "react-router-dom";
import Preview from "shared/components/preview";
import ShareBox from 'shared/components/sharebox';
import useWindowSize from "shared/hooks/use-window-size";
// import Button from "shared/components/bButton";
// import CParagraph from "shared/components/paragraph";
// import { Image, Typography } from "antd";

interface props{
    data: any
}

// const {Paragraph} = Typography

const PreviewBox: React.FC<props> = ({data}) => {
    // const [randomImage, _] = useState(data?.slug?.length % 3)
    const {width} = useWindowSize()
    const navigate = useNavigate()


    return (
        <div className='previewBoxContainer'>
            <div className="topPreview">
                <div className="previewBtn"
                    onClick={() => navigate(`/${data?.slug}?preview=true`)}
                >
                    Preview
                </div>
                <Preview data={data}/>
                {width > 450 && <ShareBox data={data}/>}
            </div>
            <div className="bottomPreview">
                <ShareBox data={data}/>
            </div>
        </div>
    )
}

export default PreviewBox