import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialUserState from './states';
import type { UserState } from "./states"
import { RootState } from '../../../shared/infra/redux/reducers';

export const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        setUser:(state: UserState, action: PayloadAction<{ user: any }>) => {
            state.user = action.payload.user;
        },
        resetUserState: (state: UserState) => {
            state.user = null
        },
    },
});


export const getUser = (state: RootState) => state.user.user;

export const { setUser, resetUserState } = userSlice.actions;

export default userSlice.reducer;
