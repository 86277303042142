import "./styles.scss"
import React from 'react'
import classNames from "classnames"

interface props{
    text: string
    action: any
    classes?: string[];

}

const Url:React.FC<props> = ({text, action,  classes = []}) => {
    return (
        <p 
            className={classNames('urlContainer', ...classes)} 
            onClick={action}>
                {text}
        </p>
    )
}

export default Url