import "./styles.scss"
import React from 'react';
import { Form, Input } from 'antd';
import classNames from "classnames";

interface props {
    label?: string
    name: string
    placeholder?: string
    rows?: number
    classes?: any
    styles?: any
    required?: boolean
    errorMessage?: string
    autoSize?: any
}

const TextArea: React.FC<props> = ({label, name, classes=[], styles, placeholder, rows=5, required=false, errorMessage, autoSize = true}) => {

    const generateRules = () => {
        const rule = []

        if(required){
            rule.push({ required: true, message: errorMessage ? errorMessage : "This is required" })
        }

        return rule
    }

    return (
        <Form.Item 
            label={label}
            className="textAreaFormItem" 
            name={name}
            rules={generateRules()}
        >
            <Input.TextArea
                className={classNames('textArea', ...classes)}
                style={{...styles}}
                rows={rows}
                spellCheck={false}
                placeholder={placeholder} 
                autoSize={autoSize}/>
        </Form.Item>
    );
}

export default TextArea;