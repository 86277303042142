import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialAuthState from './states';
import type { DynamicDrawerState } from "./states"
import { RootState } from '../reducers';

export const dynamicDrawerSlice = createSlice({
    name: 'dynamicDrawer',
    initialState: initialAuthState,
    reducers: {
        setDrawerVisibility:(state: DynamicDrawerState, action: PayloadAction<{ showSidebar: any }>) => {
            state.showSidebar = action.payload.showSidebar;
        },
        setDrawerComponent:(state: DynamicDrawerState, action: PayloadAction<{ sidebarComponent: any }>) => {
            state.sidebarComponent = action.payload.sidebarComponent;
        },
    },
});


export const getDrawerComponent = (state: RootState) => state.dynamicDrawer.sidebarComponent;
export const getDrawerVisibility = (state: RootState) => state.dynamicDrawer.showSidebar;

export const { setDrawerComponent, setDrawerVisibility } = dynamicDrawerSlice.actions;

export default dynamicDrawerSlice.reducer;