import './styles.scss';
import React from 'react';
import classNames from 'classnames';
import { Col, Row, Image, Typography } from 'antd';
// import Label from 'shared/Label';
import Paragraph from 'shared/components/paragraph';
// import { formatDate } from 'shared/utils/helper';
import useWindowSize from 'shared/hooks/use-window-size';
import { formatDate } from 'shared/utils/helper';
import { useSelector } from 'react-redux';
import { getUser } from 'modules/user/redux/userSlice';
// import Divider from 'shared/components/divider';

type props = {
    event?: any
    action?: any
    classes?: string[]
    handleEditEvent: any
    website: any
};

const Event: React.FC<props> = ({ classes = [], event, handleEditEvent, website }) => {
    const { width } = useWindowSize()
    const user = useSelector(getUser)

    return (
        <Row
            className={classNames("eventContainer", ...classes,)}
        // onClick={action}
        >
            <Col span={24} className='imageBox'>
                {!!user && user?.id === website?.user_id && <div
                    className='tag'
                    onClick={() => handleEditEvent(event)}
                >
                    Edit
                </div>}
                
                    <Image className="image"
                        src={event?.images?.length > 0? event?.images[0]?.src : `../assets/placeholders/event/image${event?.id?.length % 3}.png`}
                        alt="logo" preview={true}
                    />
                {/* <Image className="image" 
                    src={event?.img_url || `../assets/placeholders/event/image${event?.id?.length % 3}.png`}
                    alt="logo" preview={true} 
                /> */}
            </Col>
            <Col span={24}>
                <Paragraph classes={["name"]}>{event?.title}</Paragraph>
            </Col>
            <Col className='details' span={24}>
                <Typography.Paragraph ellipsis={
                    {
                        rows: width > 450 ? 2 : 4,
                        expandable: true,
                        symbol: "MORE...",
                    }
                } className="discription">{event?.notes}</Typography.Paragraph>
            </Col>
            <Col className='locationBox'>
                <div className='locDetails'>
                    <Image className='icon' src={`../assets/newIcons/calendar.svg`} preview={false} />
                    <p>{event?.date && formatDate(event?.date, "EEEE, MMMM dd")}</p>
                </div>
                <div className='locDetails'>
                    <Image className='icon' src={`../assets/newIcons/map-pin.svg`} preview={false} />
                    <p>{event?.location}</p>
                </div>
            </Col>
            {/* <Col span={24}>
                <Divider />
            </Col>
            <Col span={24} className='engagementBox'>
                <div className='action'>
                    <Image className='btn' src={`../assets/newIcons/chat.png`} preview={false}/> 0
                </div>
                <Divider type='vertical'/>
                <div className='action'>
                    <Image className='btn' src={`../assets/newIcons/like.png`} preview={false}/> 0
                </div>
            </Col> */}
        </Row>
    )
}

export default Event;