import "./styles.scss";
import React from 'react';

interface Props {
    tabs: {text: string, action: any}[]
}

const NavbarTabs: React.FC<Props> = ({tabs = []}) => {
    return (
        <div className="tabsContainer">
            {
                tabs?.length > 0 &&
                <>
                    {
                        tabs?.map(tab => (
                                <div className="tabContent" onClick={tab?.action}>{tab.text}</div>
                            ))
                    }
                    
                </>
            }
        </div>
    );
}

export default NavbarTabs;
