import "./styles.scss";
import React from 'react';
import Modal from 'shared/components/modal';
import Button from "shared/components/bButton";
import Spinner from "shared/components/spinner";

interface props {
    show: boolean
    close: any
    heading?: string
    subheading?: string
    okText?: string
    okAction: any
    cancelText?: string
    cancelAction?: any
    isOkLoading?: boolean
    isCancelLoading?: boolean
}

const Confirmation: React.FC<props> = ({show, close, heading, subheading, isOkLoading = false, okText = "Yes", okAction, isCancelLoading = false, cancelText = "No", cancelAction = () => {}}) => {
    return (
        <>
            <Modal show={show} close={close} head={false} showCloseBtn={false} title={``} classes={["confirmationModal"]} maskClosable={false}> 
                <div className='confirmationModalContainer'>
                    {!!heading && <p className="heading">{heading}</p>}
                    {!!subheading && <p className="subheading">{subheading}</p>}
                    <Button
                        classes={["w-100-p"]} 
                        type="base5"
                        text={isOkLoading? <Spinner /> : okText}
                        action={okAction}
                    />
                    <Button 
                        classes={["w-100-p"]} 
                        type="base4"
                        text={isCancelLoading? <Spinner /> : cancelText}
                        action={() => {
                            close(); 
                            cancelAction()
                        }}
                    />
                </div>
            </Modal>
        </>
    )
}

export default Confirmation;