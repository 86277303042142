import "./styles.scss";
import React, { useState } from 'react';
import Modal from 'shared/components/modal';
import Input from "shared/components/newinput";
import Button from "shared/components/bButton";
import { Row, Col, Form } from 'antd';
import { addGuest } from "modules/website/redux/operators";
import Spinner from "shared/components/spinner";
import Notification from "shared/components/notification";
import { useSelector } from "react-redux";
import { getUser } from "modules/user/redux/userSlice";

interface props {
    show: boolean
    close: any
    data: any
    addedByMom?: boolean
}

const AddGuestPhoneNumberModal: React.FC<props> = ({ show, close, data, addedByMom = false }) => {
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    const user = useSelector(getUser)

    const addGuestInfo = async (values: any) => {
        if(values?.phone === user?.phone){
            return Notification("error", "You cann't add your own number")
        }
        if(isLoading) return 0

        setIsLoading(true)
        const response = await addGuest(data?.website?.id, {...values, addedByMom})
        setIsLoading(false)

        if(response?.status === "success"){
            form.resetFields()
            close()
            Notification("success", "Done")
        }
        else{
            Notification("error", response?.message)
        }
    }   

    return (
        <>
            <Modal show={show} close={close} head={false} title={addedByMom ? "Add villagers" : "Subscribe to this village"}>
                <Form
                    name="addGuestPhoneNumberForm"
                    onFinish={addGuestInfo}
                    autoComplete="off"
                    form={form}
                    layout="vertical"
                >
                    <Row className='addGuestPhoneNumberModalContainer' gutter={[0, 0]}>
                        <Col span={24}>
                            <Input 
                                label={addedByMom ? "Full name" :"What’s your full name?"}
                                name="name" 
                                placeholder='First and last name' 
                                required
                            />
                        </Col>
                        <Col span={24}>
                            <Input 
                                label={addedByMom ? "Phone number" : "What’s your phone number?"}
                                name="phone" 
                                placeholder='Your phone number' 
                                required
                                minLength={"10"}
                            />
                        </Col>
                        <Col span={24}>
                            <Input
                                label={addedByMom ? "Email (optional)" : "What’s your email address?"}
                                name="email"
                                placeholder='Your email address'
                                required={!addedByMom}
                            />
                        </Col>
                        <Col span={24}>
                            <Input
                                label={addedByMom ? "Relationship to you" : "What’s your relation with mom?"}
                                name="relation_with_mom"
                                placeholder='e.g aunt, cousin, friend etc'
                                required
                            />
                        </Col>
                        <Col span={24}>
                            <Button 
                                classes={["w-100-p"]}
                                type="base3"
                                text={isLoading? <Spinner /> : "Add"}
                                htmlType={"submit"}
                            />
                        </Col>
                    </Row>
                </Form>

            </Modal>
        </>
    )
}

export default AddGuestPhoneNumberModal;