import './styles.scss';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Col, Row, Image } from 'antd';
import Label from 'shared/Label';
import Paragraph from 'shared/components/paragraph';
import useWindowSize from 'shared/hooks/use-window-size';
// import Button from 'shared/components/bButton';
import { capitalize, getFirstNameAndLastName, calculateTrimesterAndMop, openInNewTab, isFreeTrialValid } from 'shared/utils/helper';
// import { Mixpanel } from 'shared/services/mixpanel';
import BButton from 'shared/components/bButton';
import CancelPremiumProducts from './cancelPremiumProducts';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'modules/user/redux/userSlice';
import { Mixpanel } from 'shared/services/mixpanel';
import { getTextColor } from 'shared/utils/helper';
import { setUpgradeModalVisible } from 'shared/infra/redux/global/global';

type props = {
    classes?: string[]
    action: any
    website: any
    setRefresh: any
    color?: string;
};

const Products: React.FC<props> = ({ classes = [], action, website, setRefresh, color }) => {
    const remove_add_explore: any = new URLSearchParams(window.location.search).get(
		'remove_add_explore'
	);
    const { width } = useWindowSize()
    const [isCancelPremiumProductsVisible, setIsCancelPremiumProductsVisible] = useState(false)
    const user = useSelector(getUser)
    const dispatch = useDispatch()

    useEffect(() => {
        if(!!remove_add_explore){
            setIsCancelPremiumProductsVisible(true)
        }
    }, [remove_add_explore])

    return (
        <>
            <Row
                className={classNames("productsContainer", ...classes,)}
            >
                {
                    // (website?.country === "United States" && !website?.remove_ads_explore) &&
                    (website?.country === "United States" && website?.package !== "tier1" && !isFreeTrialValid(website?.free_trial_started_at)) &&
                    <>
                        <Col span={24}>
                            <Label styles={getTextColor(website?.website_color, color) || {undefined}}  text={`Surprise ${getFirstNameAndLastName(website?.village_owner)?.first_name} with These Gifts!`} />
                        </Col>
                        <Col span={24} style={{ marginBottom: "3.34rem" }}>
                            <Row justify="space-between" gutter={width > 450 ? [30, 15] : [20, 16]}>
                                {

                                    [
                                        ...(
                                        ["Due date has passed", "Third trimester"].includes(calculateTrimesterAndMop(website?.due_date_or_dob)?.trimester)
                                        ? [
                                            { name: "SquareBaby Baby Food", image: "square-baby.svg", initialAmount: 99, otherAmount: [169, 229, 279, 25, 50] },
                                            { name: "Zomee Breastfeeding Supplies", image: "zomee-breast-pump.png", initialAmount: 35, otherAmount: [40, 75, 100, 145] },
                                            ]
                                        : [
                                            { name: "Believe Diapers", image: "believe-diapers.svg", initialAmount: 25, otherAmount: [25, 50, 75, 100, 250] },
                                            { name: "Wellness", image: "women-fertility-trio.png", initialAmount: 35, otherAmount: [40, 75, 100, 145] }
                                        ]
                                        )
                                    ]?.map((p, index) => (
                                    <Col span={12} key={index}>
                                        <div className='product'>
                                            {
                                                user && user?.id === website?.user_id && 
                                                <span
                                                    className="btnDelete"
                                                    // onClick={() => setIsCancelPremiumProductsVisible(true)}
                                                    onClick={() => dispatch(setUpgradeModalVisible({isUpdgradeModalVisible: true}))}
                                                >
                                                <img src="../assets/delete.svg" alt="x" />
                                                </span>
                                            }
                                            
                                            <Image
                                                className='image'
                                                src={`../assets/brands/${p?.image}`}
                                                preview={false}
                                            />

                                            <Paragraph classes={["brandName"]}>{p?.name}</Paragraph>
                                            <div className='priceArea'>
                                                <BButton 
                                                    text="Gift Now"
                                                    classes={["w-100-p"]}
                                                    type='base6'
                                                    styles={{textTransform: "capitalize"}}
                                                    action={() => action({ type: "brand", ...p })}
                                                />
                                            </div>
                                            
                                            {false && <div className='priceArea'>
                                                <Paragraph classes={["price"]}>${p.initialAmount}.00</Paragraph>
                                                <Image className='btn' src={`../assets/newIcons/add-products-icon.svg`} preview={false}
                                                    onClick={() => action({ type: "brand", ...p })}
                                                />
                                            </div>}
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </>
                }

                {
                    website?.registries?.length > 0 &&
                    <Col span={24}>
                        <Label styles={getTextColor(website?.website_color, color) || {undefined}} text="Baby Registry" />
                    </Col>
                }
                {
                    website?.registries?.map((r: any) => (
                        <Col span={24} className='registry' key={r?.id}
                            onClick={() => {
                                Mixpanel.track("VILLAGER_clicks_baby_registry", {
                                    website_id: website?.id,
                                    website_slug: website?.slug,
                                    registry_brand: r?.registry_brand
                                })
                                openInNewTab(r?.registry_link)
                            }}
                        >
                            {
                                r?.registry_brand !== "Other" && 
                                <Image
                                    src={`../assets/registries/${capitalize(r?.registry_brand)}.svg`}
                                    preview={false}
                                />
                            }
                            
                            <Paragraph classes={["name"]}>{capitalize(r?.registry_brand)} Baby Registry</Paragraph>
                            {/* <Button
                                classes={["w-60-p"]}
                                type='base3'
                                text={"Visit Wishlist"}
                                action={() => {
                                    Mixpanel.track("VILLAGER_clicks_baby_registry", {
                                        website_id: website?.id,
                                        website_slug: website?.slug,
                                        registry_brand: r?.registry_brand
                                    })
                                    openInNewTab(r?.registry_link)
                                }}
                            /> */}
                        </Col>
                    ))
                }
            </Row>

            <CancelPremiumProducts 
                show={isCancelPremiumProductsVisible}
                close={() => setIsCancelPremiumProductsVisible(false)}
                website={website}
                setRefresh={setRefresh}
            />
        </>
    );
};

export default Products;
