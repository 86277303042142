import "./styles.scss";
import React, { useState } from "react";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import Button from "shared/components/button";
import ImageCropingModal from "../imageCroping";
import { compressImage } from "shared/utils/helper";
import BButton from "../bButton";
import { Mixpanel } from "shared/services/mixpanel";

const UploadImagesForSteps = ({
	name = '',
	label = '',
	sublabel = '',
	btnSublabel = 'Your pregnancy announcement, bumpie, or family portrait.',
	images = [],
	limitSize = 1048576 * 15,
	maxSize = 1048576 * 15,
	onChange,
	tooltip = false,
	full = false,
	noOfImagesAllowed = 3,
	forStep = false,
}) => {
	const id = name.replace(/\s/g, '_').toLowerCase();
	const [imageSizeError, setImageSizeError] = useState(null)
	const [isImageCropingModalVisible, setIsImageCropingModalVisible] = useState(false)
	const [croppedImage, setCroppedImage] = useState([]);
	const [cropingImage, setCropingImage] = useState([]);
	const [imagesBackup, setImagesBackup] = useState([])

	//Show thumbnail images and postback to parent component
	const createThumbnail = async (files, i, thumbnailId) => {
		try {
			const compressedBlob = await compressImage(files[i]);
		
			onChange([...images, {
			  	id: thumbnailId,
			  	src: URL.createObjectURL(compressedBlob),
			  	blob: compressedBlob, // Store the compressed blob for later use
			}]);
		} catch (error) {
			const blob = new Blob([files[i]], { type: "application/zip" })

			onChange([...images, {
				id: thumbnailId,
				src: URL.createObjectURL(blob),
				blob: blob
			}]);
		}
	};

	//Handler for the button that removes the thumbnail image
	const deleteThumbnail = (thumbID) => {
		const imagesUpdated = images.filter((image) => image.id !== thumbID);
		onChange(imagesUpdated);
	};

	//User-friendly byte to size converter
	const bytesToSize = (bytes) => {
		const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
		if (bytes === 0) return "0 Byte";
		const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		return `${Math.round(bytes / Math.pow(1024, i), 2)}${sizes[i]}`;
	};

	//Controller for handling images loaded in the input file
	const handleFileChange = (e) => {
		if (e.target.files.length > 0) {
			const files = e.target.files;

			for (let i = 0; i < files.length; i++) {
				if (files[i].size > maxSize) {
					setImageSizeError("Oh no, this image is too big! Please try another image.")
					return e.target.value = "";
				}
				if (images.length === noOfImagesAllowed) {
					alert(`Only ${noOfImagesAllowed} images are allowed.`);
					return e.target.value = "";
				}
				if (files[i]?.size <= limitSize) {
					let thumbnailId = Math.floor(Math.random() * 30000) + "_" + Date.now();
					createThumbnail(files, i, thumbnailId);
				} else {
					alert(`I couldn't upload it because it weighs more than ${bytesToSize(limitSize)}`)
					// toast.error(
					//   `(${
					//     files[i].name
					//   }) I couldn't upload it because it weighs more than ${bytesToSize(
					//     limitSize
					//   )}`,
					//   {}
					// );
				}
			}

			e.target.value = "";
		}
	};

	const handleCroppedImage = (croppedImage) => {
		let tempImage = null
			
		let replacedImagesArray = images?.map(img => {
			if(img?.id == croppedImage?.id){
				tempImage = img
				return {...img, ...croppedImage}
			}
			else{
				return img
			}
		})

		onChange(replacedImagesArray)

		let flag = false
		imagesBackup?.map((b) => {
			if(b?.id === tempImage?.id){
				flag = true;
			}
		})

		if(!flag){
			setImagesBackup([...imagesBackup, tempImage])
		}
		
		flag = false

		setIsImageCropingModalVisible(false)
		setCroppedImage(croppedImage)
	}

	const GetCropingImage = (img) => {
		let returnValue = null

		imagesBackup?.map((b) => {
			if(b?.id === img?.id){
				returnValue = b
			}
		})

		return !!returnValue ? returnValue : img
	}

	return (
		<>
			<div className={classNames("uploadImagesForSteps", { full: full })}>
				{
					label?.length > 0 &&
						<p className="label">{label}</p>	 
				}

				{
					sublabel?.length > 0 &&
					<p className="sublabel">{sublabel}</p>
				}
				
				<div style={{marginTop: ".5rem"}}>
					{images.map((img) => {
						return (
							<div className="preview-img" key={'preview-' + img?.id} id={img?.id}>
								<span
									className="btn-delete"
									onClick={() => {
										deleteThumbnail(img?.id);
									}}
								>
									<img src="../assets/delete.svg" alt="x" />
								</span>
								<Button
									classes={["imageEditBtn"]}
									text={"Edit"}
									action={() => {
										setCropingImage(GetCropingImage(img))
										setIsImageCropingModalVisible(true)
										Mixpanel.track(
											"PARENT_edit_image",
										)
									}}
								/>
								<img src={img?.src} alt="thumb" />
							</div>
						);
					})}
					{
						images?.length < noOfImagesAllowed &&
						<label className="image-btn">
							<img src={`../assets/icons/file-upload.svg`} alt="burger" />
							<div className="title">Upload your photo</div>
							{btnSublabel?.length > 0 && <div className="paragraph">{btnSublabel}</div>}
							<input
								name={`${id}[]`}
								style={{ display: "none" }}
								type="file"
								onChange={handleFileChange}
								accept="image/png, image/gif, image/jpeg"
								multiple
								onClick={() => {
									if (!!imageSizeError) {
										setImageSizeError(null)
									}
								}}
							/>
						</label>
					}
				</div>
				{!!imageSizeError && <p className="imageSizeError">{imageSizeError}</p>}
			</div>
			<ImageCropingModal
				show={isImageCropingModalVisible}
				close={() => setIsImageCropingModalVisible(false)}
				cropingImage={cropingImage}
				handleCroppedImage = {handleCroppedImage}
			/>
		</>
	);
};

export default UploadImagesForSteps;