export type GlobalState = {
    isAddNotesModalVisible: boolean
    website: any
    websiteSetting: any
    refresh: boolean
    websiteSettingsRefresh: boolean
    isUpdgradeModalVisible: boolean
    guessWhoGame: any
}

const initialState: GlobalState = {
    isAddNotesModalVisible: false,
    website: null,
    websiteSetting: null,
    refresh: false,
    websiteSettingsRefresh: false,
    isUpdgradeModalVisible: false,
    guessWhoGame: null
}

export default initialState