import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import "./styles.scss";

const CustomPieChart: React.FC<any> = ({data, forMobile=false}) => {
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <>
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
        >
          {`${(percent * 100)?.toFixed(0)}%`}
        </text>
        <text
          x={x}
          y={y + 12}
          fill="white"
          textAnchor="middle"
          dominantBaseline="central"
        >
          {` ${data[index].name} `}
        </text>
      </>
    );
  };

  return (
    <ResponsiveContainer width={forMobile? 75 : 103} height={forMobile? 75 : 103} className="pieChart">
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          startAngle={90}
          endAngle={-270}
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={forMobile? 37.5 : 51.5} // outerradius = Math.min(width, height) / 2;
          fill="#8884d8"
          stroke="none"
          dataKey="value"
        >
          {data.map((entry: any, i: any) => (
            <Cell key={`cell-${i}`} fill={entry.color} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CustomPieChart;
