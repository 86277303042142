import "./styles.scss";
import React, {useEffect, useState} from 'react';
import { Dropdown, Menu, Button as AntdButton } from 'antd';


interface props {
    setOption: any
    options: any
    child?: any
    defaultOption: any
    label: any
}

const DonationDropdown: React.FC<props> = ({setOption, options, child, defaultOption, label}) => {
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const [visible, setVisible] = useState(false);

    const handleMenuClick = (e: any) => {
        setSelectedOption(e.key);
        setOption(e.key)
        setVisible(false)
    };

    useEffect(() => {
        setSelectedOption(defaultOption)
    }, [defaultOption])

    const menu = (
        <Menu onClick={handleMenuClick}>
            {options?.map((option: any) => (
                <Menu.Item key={option}>{option}</Menu.Item>
            ))}
            {child}
        </Menu>
    );

    return (
        <div className="dropdownContainer">
            {!!label && <p className="dropDownLabel">{label}</p>}
           <Dropdown overlay={menu} className="customBDropdown" visible={visible}>
                <AntdButton onClick={(e: any) => {e.preventDefault(); setVisible(!visible)}}>
                    {selectedOption} 
                        <img 
                            style={{width: "24px", height:"24px"}}
                            className='iconPanel'
                            src={`../assets/newIcons/collapse-${visible ? "close" : "open"}.svg`} 
                        />
                    
                </AntdButton>
            </Dropdown>
        </div>
    )
}

export default DonationDropdown;