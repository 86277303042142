import "./styles.scss";
import React, { useState } from "react";
import Modal from "shared/components/modal";
import { Row, Col, Typography, Checkbox, Button } from "antd";
import { endGame } from "modules/website/redux/operators";
import Spinner from "shared/components/spinner";
import Notification from "shared/components/notification";
import { useDispatch } from "react-redux";
import { setWebsiteRefresh } from "shared/infra/redux/global/global";

interface props {
	show: boolean;
	close: any;
	data: any;
	afterAction?: any;
	from?: boolean;
}

const { Paragraph } = Typography;

const EndGrgModal: React.FC<props> = ({
	show,
	close,
	data,
	afterAction,
	from = false,
}) => {
	const dispatch = useDispatch()
	const [isAcknowledge, setIsAcknowledge] = useState(false);
	const [gender, setGender] = useState<null | string>(null);
	const [errorMessage, setErrorMessage] = useState<null | string>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [winner, setWinner] = useState<null | any>(null);

	const handleAcknowlegment = (e: any) => {
		setIsAcknowledge(e.target.checked);
		setErrorMessage(null);
	};

	const handleEndGame = async () => {
		if (!gender) return setErrorMessage("Please select the gender.");

		if (!isAcknowledge) return setErrorMessage("Please acknowledge.");

		setIsLoading(true);
		const response = await endGame(data?.game?.id, {
			baby_gender: gender === "boy" ? 0 : 1,
		});
		if (from === true) {
			await afterAction(true);
		} else {
			await afterAction();
		}
		setIsLoading(false);

		if (response?.status === "success") {
			if (response?.data?.winner) {
				setWinner(response?.data?.winner);
				dispatch(setWebsiteRefresh({refresh: true}))
			} else {
				setWinner(
					`There is no winner because no one voted for ${gender}!`
				);
			}
		}
		else{
			Notification("error", response?.message)
		}
	};

	const handleCashout = () => {
		window.open("https://www.villie.com/grg-cashout", "_blank");
	};

	const handleModalClose = () => {
		setIsAcknowledge(false);
		setGender(null);
		setWinner(null);
		close();
	};

	return (
		<>
			<Modal
				show={show}
				close={handleModalClose}
				head={false}
				title={`End Game`}
			>
				<Row className="endGrgModalContainer">
					{!winner ? (
						<>
							<Col span={24}>
								<Paragraph className="heading">
									Select your baby gender
								</Paragraph>
							</Col>

							<Row className="buttonSection">
								<Col>
									<div
										className="vottingButton"
										style={{
											background:
												gender === "boy"
													? "#5263FF"
													: "",
										}}
										onClick={() => {
											setGender("boy");
											setErrorMessage(null);
										}}
									>
										<img
											src={"../assets/white-bear.svg"}
											alt="icon-btn"
										/>
										<span>It’s a Boy!</span>
									</div>
								</Col>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
								<Col>
									<div
										className="vottingButton"
										style={{
											background:
												gender === "girl"
													? "#CC8DFF"
													: "",
										}}
										onClick={() => {
											setGender("girl");
											setErrorMessage(null);
										}}
									>
										<img
											src={"../assets/white-bear.svg"}
											alt="icon-btn"
										/>
										<span>It’s a Girl!</span>
									</div>
								</Col>
							</Row>

							<Col span={24}>
								<Checkbox
									className="acknowledge"
									onChange={handleAcknowlegment}
								>
									I acknowledge that "Once I will choose the
									gender, the game is over and cannot be
									replayed."
								</Checkbox>
							</Col>

							<Col span={24}>
								<Paragraph className="errorMessage">
									{errorMessage}
								</Paragraph>
							</Col>
						</>
					) : typeof winner === "string" ? (
						<Col span={24}>
							<br />
							<Paragraph className="acknowledge">
								{winner}
							</Paragraph>
							<br />
						</Col>
					) : (
						<Col span={24}>
							<div
								className="winnerCard"
								style={{
									border: `1px solid ${
										gender === "girl"
											? "#CC8DFF"
											: "#5263FF"
									}`,
								}}
							>
								<Paragraph
									className="title"
									style={{
										color:
											gender === "girl"
												? "#CC8DFF"
												: "#5263FF",
									}}
								>
									winner
								</Paragraph>
								<Paragraph className="subtitle">
									{winner?.voter_first_name}{" "}
									{winner?.voter_last_name?.substring(0, 1)}.
								</Paragraph>
							</div>
						</Col>
					)}
					<Col span={24}>
						<Button
							className="endBtn"
							style={{
								background:
									gender === "girl"
										? "#CC8DFF"
										: gender === "boy"
										? "#5263FF"
										: "",
							}}
							onClick={!winner ? handleEndGame : handleCashout}
						>
							{isLoading ? (
								<Spinner />
							) : !winner ? (
								"Yes, End Game"
							) : (
								"Cash Out"
							)}
						</Button>
					</Col>
				</Row>
			</Modal>
		</>
	);
};

export default EndGrgModal;
