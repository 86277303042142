import AddGRGForm from "../addGrgForm";
import "./styles.scss";
import React, { useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Badge } from "antd";
import GuessWhoGameForm from "../games/guessWho";

interface props {
	setClosingCollapse: any;
}

const AddGames: React.FC<props> = ({ setClosingCollapse }) => {
	const [game, setGame] = useState<any>(null);

	return (
		<div className="addGamesContainer">
			{!game ? (
				<div className="allGamesSection">
					<div
						className="gameBox"
						style={{ color: "#ffbe0b" }}
						onClick={() => setGame("grg")}
					>
						Gender Reveal Game
					</div>
					<div
						className="gameBox"
						style={{ color: "#8ac926" }}
						onClick={() => setGame("guessWho")}
					>
						Guess Who?
					</div>
					<Badge.Ribbon text="Coming soon" color="#a2d2ff">
						<div
							className="gameBox"
							style={{ color: "#c1121f" }}
							onClick={() => setGame("comingSoon")}
						>
							Name Game
						</div>
					</Badge.Ribbon>
					<Badge.Ribbon text="Coming soon" color="#a2d2ff">
						<div
							className="gameBox"
							style={{ color: "#ff99c8" }}
							onClick={() => setGame("comingSoon")}
						>
							Bump Lapse
						</div>
					</Badge.Ribbon>
				</div>
			) : (
				<>
					<div className="backButton" onClick={() => setGame(null)}>
						<ArrowLeftOutlined /> &nbsp; Back
					</div>
					{game === "grg" && (
						<AddGRGForm
							setClosingCollapse={() => {
								setGame(null);
								setClosingCollapse(true);
							}}
						/>
					)}
					{game === "guessWho" && (
						<GuessWhoGameForm 
							setClosingCollapse={() => {
								setGame(null);
								setClosingCollapse(true);
							}}
						/>)
					}
					{game === "comingSoon" && <div>🌟 Coming soon</div>}
				</>
			)}
		</div>
	);
};

export default AddGames;
