import { BaseAPI } from 'shared/infra/services/baseAPI';

export namespace WebsiteService {
    export const createWebsite = (data: any) => BaseAPI.post(`platform/website/`, data)
    // export const findWebsite = (slug: any) => BaseAPI.post(`platform/website/slug/${slug}`, {})
    export const verifyVisitorPassword = (websiteId: string, data: any) => BaseAPI.post(`platform/website/${websiteId}/verifyVisitorPassword`,data)
    export const findWebsite = (slug: string) => BaseAPI.get(`platform/website/slug/${slug}`)
    export const findWebsiteByUser = (userId: string) => BaseAPI.get(`platform/website/user/${userId}`)
    export const updateWebsite = (websiteId: string, data: any) => BaseAPI.patch(`platform/website/${websiteId}`, data)
    export const getUserWebsites = () => BaseAPI.get(`platform/website/`)
    export const addNewUpdate = (websiteId: string, data: any) => BaseAPI.post(`platform/website/${websiteId}/updates/`, data)
    export const editUpdate = (websiteId: string, updateId: string, data: any) => BaseAPI.put(`platform/website/${websiteId}/updates/${updateId}`, data)
    export const deleteUpdate = (websiteId: string, updateId: string) => BaseAPI.del(`platform/website/${websiteId}/updates/${updateId}`)
    export const deletePhoto = (websiteId: string, photoId: string) => BaseAPI.del(`platform/website/${websiteId}/photos/${photoId}`)
    export const setMainImage = (websiteId: string, photoId: string) => BaseAPI.put(`platform/website/${websiteId}/photos/setMainImage/${photoId}`)
    export const addNewRegistry = (websiteId: string, data: any) => BaseAPI.post(`platform/website/${websiteId}/registry/`, data)
    export const deleteRegistry = (websiteId: string, registryId: string) => BaseAPI.del(`platform/website/${websiteId}/registry/${registryId}`)
    export const updateRegistry = (websiteId: string, registryId: string, data: any) => BaseAPI.post(`platform/website/${websiteId}/registry/${registryId}`, data)
    export const addNewEvent = (websiteId: string, data: any) => BaseAPI.post(`platform/website/${websiteId}/milestone/`, data)
    export const editEvent = (websiteId: string, milestoneId: string, data: any) => BaseAPI.put(`platform/website/${websiteId}/milestone/${milestoneId}`, data)
    export const getEventsByWebsiteSlug = (websiteId: string, slug: string) => BaseAPI.get(`platform/website/${websiteId}/milestone/${slug}`)
    export const deleteEvent = (websiteId: string, milestoneId: string) => BaseAPI.del(`platform/website/${websiteId}/milestone/${milestoneId}`)
    export const likeAnEvent = (websiteId: string, milestoneId: string, data: any) => BaseAPI.post(`platform/website/${websiteId}/milestone/like/${milestoneId}`, data)
    export const commentOnEvent = (websiteId: string, milestoneId: string, data: any) => BaseAPI.post(`platform/website/${websiteId}/milestone/comment/${milestoneId}`, data)
    export const addDonation = (websiteId: string, data: any) => BaseAPI.post(`platform/website/${websiteId}/donation/`, data)
    export const createPaymentSecret = (websiteId: string, data: any) => BaseAPI.post(`platform/website/${websiteId}/donation/secret`, data)
    export const uploadImagesToGallery = (websiteId: string, data: any) => BaseAPI.post(`platform/website/${websiteId}/gallery/`, data)
    export const addGuest = (websiteId: string, data: any) => BaseAPI.post(`platform/website/${websiteId}/guest/`, data)
    export const customizeWebsiteUrl = (websiteId: string, data: any) => BaseAPI.post(`platform/website/${websiteId}/customizeUrl`, data)
    export const addNote = (websiteId: string, data: any) => BaseAPI.post(`platform/website/${websiteId}/note`, data)
    export const deleteNote = (websiteId: string, noteId: string) => BaseAPI.del(`platform/website/${websiteId}/note/${noteId}`)
    export const getNotesByWebsiteSlug = (websiteId: string, slug: string) => BaseAPI.get(`platform/website/${websiteId}/note/${slug}`)
    export const deleteGuest = (websiteId: string, guestId: string) => BaseAPI.del(`platform/website/${websiteId}/guest/${guestId}`)
    export const addWebsiteSetting = (websiteId: string, data: any) => BaseAPI.post(`platform/website/${websiteId}/settings/`, data)
    export const updateWebsiteSetting = (websiteId: string, data: any) => BaseAPI.patch(`platform/website/${websiteId}/settings/`, data)
    export const getWebsiteSetting = (websiteId: string, key: any) => BaseAPI.get(`platform/website/${websiteId}/settings/${key}`)
}