// import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { store } from './shared/infra/redux/store';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Fonts } from './shared/styles/fonts';
import './app.css';

let persistor = persistStore(store)

const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(
	// <React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<App />
				<Fonts />
			</PersistGate>
		</Provider>
	// </React.StrictMode>
);

reportWebVitals();
