import "./styles.scss";
import React from 'react';
import { Empty, Button } from 'antd';

interface props {
    description?: string
    action?: any
    image?: any
}

const NoData:React.FC<props> = ({description, image = Empty.PRESENTED_IMAGE_DEFAULT, action = null}) => {

    return (
        <Empty 
            image={image}
            description={description}
            className="noDataContainer"
        >
            {!!action && <Button className="actionBtn" onClick={action}>Create Website</Button>}
        </Empty>
    );

}

export default NoData;