import { resetAuthState, setToken } from "./authSlice"
import { resetUserState, setUser } from "modules/user/redux/userSlice"
import { AuthService } from "../services/http/auth"
import { Mixpanel } from "shared/services/mixpanel"
import { resetOnBoardingState } from "shared/infra/redux/onboarding/onboarding"

export const signup = (data: any) => async (dispatch: any) => {
    try {
        const response = await AuthService.signup(data)
        const {user, accessToken} = response?.data?.data

        Mixpanel.track(
            "signup",
            {
                $name: user?.name,
                $email: user?.email
            }
        )

        dispatch(setToken({accessToken: accessToken}))
        dispatch(setUser({user: user}))

        return {status: "success"}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const login = (data: any) => async (dispatch: any) => {
    try {
        const response = await AuthService.login(data)
        const {user, accessToken} = response?.data?.data

        dispatch(setToken({accessToken: accessToken}))
        dispatch(setUser({user: user}))

        return {status: "success"}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}


export const forgotPassword = async (data: any) => {
    try {
        await AuthService.forgetPassword(data)
        return {status: "success"}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const resetPassword = async (data: any) => {
    try {
        await AuthService.resetPassword(data)
        return {status: "success"}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}

export const logout = (data: any) => async (dispatch: any) => {
    try {
        await dispatch(resetAuthState())
        dispatch(resetUserState())
        dispatch(resetOnBoardingState())
        
        await AuthService.logout(data)
        
        return {status: "success"}
    } catch (error: any) {
        return {...error?.response?.data}
    }
}