import React, {useEffect, useState} from "react";
import "./styles.scss";
import Modal from "shared/components/modal";
import { Row, Col, Form } from "antd";
import CustomInput from "shared/components/binput";
import BButton from "shared/components/bButton";
import { updateWebsite } from "modules/website/redux/operators";
import Spinner from "shared/components/spinner";
import Notification from "shared/components/notification";

interface props {
	show: boolean;
	close: any;
	data: any
	refetch: any;
}

const AddPassCodeModal: React.FC<props> = ({ show, close, data, refetch }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [form] = Form.useForm();

	const handlePassCode = async (values: any) => {	
		const payload = {
			"password": values.password,
			"password_instructions": values.instruction,
			"is_password_protected": true
		};

		setIsLoading(true);
		const response = await updateWebsite(data.id, payload);
		setIsLoading(false);

		if (response?.status === "success") {
			Notification("success", "Passcode added successfully")
			form.resetFields()
			refetch()
			close();
		}
		else {
			Notification("error", "There is an error in adding passcode. Try again later.")
		}
	}

	const handleModalClose = () => {
		form.resetFields();
		close();
	};

	useEffect(() => {
		if(!!data?.password_instructions) {
			form.setFieldsValue({
				instruction: data?.password_instructions,
			})
		}
	}, [data])

	return (
		<>
			<Modal show={show} close={handleModalClose} head={false} title="">
				<Row className="addPassCodeModalContainer">
					<Col span={24}>
						<p className="title">Page Passcode</p>
						<p className="subtitle">Setup the password to protect your villie page</p>
						<div className="passCodeBox">
							<Form
								form={form}
								name="basic"
								layout="vertical"
								onFinish={handlePassCode}
								autoComplete="off"
								className="passCodeForm"
							>
								<CustomInput
									label="ENTER PASSCODE"
									name="password"
									placeholder="Passcode"
									required
									classes={["c-bg-w"]}
								/>

								<CustomInput
									label="INSTRUCTIONS FOR VILLAGERS"
									name="instruction"
									placeholder="If you don't know the password, reach out to <mom>"
									required
									classes={["c-bg-w"]}
								/>

                                <BButton
                                    type="base4"
                                    htmlType="submit"
                                    text={isLoading ? <Spinner /> :"Save Changes"}
                                />
							</Form>
						</div>
					</Col>
				</Row>
			</Modal>
		</>
	);
};

export default AddPassCodeModal;
