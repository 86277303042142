import { combineReducers } from 'redux'

import userSlice from 'modules/user/redux/userSlice';
import authSlice from 'modules/auth/redux/authSlice';
import dynamicDrawerSlice from 'shared/infra/redux/dynamicDrawer/dynamicDrawerSlice';
import onBoardingSlice from './onboarding/onboarding';
import globalSlice from "./global/global";

const reducers = combineReducers({
    user: userSlice,
    auth: authSlice,
    dynamicDrawer: dynamicDrawerSlice,
    onBoarding: onBoardingSlice,
    global: globalSlice,
})

export default reducers;

export type RootState = ReturnType<typeof reducers>;