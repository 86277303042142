import React, { useEffect, useState } from 'react';
import { Mixpanel } from 'shared/services/mixpanel';
import { addNewRegistry, addWebsiteSetting, updateRegistry } from 'modules/website/redux/operators';
import {
    isChecklistTaskCompleted,
    removeBeforeHttp,
    updateChecklistByInfoAndStringify,
} from 'shared/utils/helper';
import HelperText from 'shared/components/helperText';
import CustomDropdown from 'shared/components/customDropdown';
import CustomInput from 'shared/components/binput';
import { Form } from 'antd';
import Notification from 'shared/components/notification';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'modules/user/redux/userSlice';
import Button from 'shared/components/bButton';
import Spinner from 'shared/components/spinner';
import {
    getWebsiteSettings,
    setWebsite,
    setWebsiteSettingsRefresh,
} from 'shared/infra/redux/global/global';
import { PostHog } from 'shared/services/posthog';

interface props {
    data: any;
    successAction: any;
    isUpdating?: boolean;
    updatingData?: any;
}

const AddRegistryForm: React.FC<props> = ({
    data,
    successAction,
    isUpdating = false,
    updatingData,
}) => {
    const checklist = useSelector(getWebsiteSettings)?.checklist;
    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [registry, setRegistry] = useState<any>('Select Registry');
    const [registryError, setRegistryError] = useState(false);

    const handleAddRegistry = async (values: any) => {
        if (!registry || registry === 'Select Registry') return setRegistryError(true);
        if (isLoading) return 0;

        values.registry_link = removeBeforeHttp(values.registry_link);

        setIsLoading(true);
        const response = await addNewRegistry(data?.id, { ...values, registry_brand: registry });
        setIsLoading(false);

        if (response?.status === 'success') {
            Mixpanel.track('PARENT_adds_a_baby_registry', {
                $name: user?.name,
                $email: user?.email,
            });
            dispatch(
                setWebsite({
                    website: {
                        ...data,
                        registries: [...data?.registries, ...response?.data?.registry],
                    },
                })
            );
            form.resetFields();
            setRegistry('Select Registry');
            successAction();

            if (!isChecklistTaskCompleted(checklist, 'Add a Baby Registry')) {
                const payload = {
                    setting_key: 'checklist',
                    setting_value: updateChecklistByInfoAndStringify(
                        checklist?.setting_value,
                        'Add a Baby Registry'
                    ),
                };

                await addWebsiteSetting(data?.id, payload);
                PostHog.track("add_registry_gem", {})
                dispatch(setWebsiteSettingsRefresh({ websiteSettingsRefresh: true }));
            }

            Notification('success', 'Registry added successfully.');
        } else {
            Notification('error', response?.message);
        }
    };

    const handleUpdateRegistry = async (values: any) => {
        if (!registry || registry === 'Select Registry') return setRegistryError(true);
        if (isLoading) return 0;

        values.registry_link = removeBeforeHttp(values.registry_link);

        setIsLoading(true);
        const response = await updateRegistry(data?.id, updatingData?.id, {
            ...values,
            registry_brand: registry,
        });
        setIsLoading(false);

        if (response?.status === 'success') {
            const updatedRegistries = data.registries.map((r: any) =>
                r.id === updatingData.id
                    ? { ...updatingData, ...values, registry_brand: registry }
                    : r
            );

            dispatch(setWebsite({ website: { ...data, registries: updatedRegistries } }));

            Notification('success', 'Registry updated successfully');
            successAction();
        } else {
            Notification('error', response?.message);
        }
    };

    useEffect(() => {
        if (isUpdating) {
            form.setFieldsValue({
                registry_link: updatingData?.registry_link,
            });
            setRegistry(updatingData?.registry_brand);
        }
    }, [updatingData]);

    return (
        <Form
            layout="vertical"
            onFinish={isUpdating ? handleUpdateRegistry : handleAddRegistry}
            autoComplete="off"
            form={form}
        >
            <CustomDropdown
                options={[
                    'Select Registry',
                    'Amazon',
                    'Walmart',
                    'Target',
                    'Babylist',
                    'Poppylist',
                    'Other',
                ]}
                setOption={(registry: string) => {
                    setRegistryError(false);
                    setRegistry(registry);
                }}
                defaultOption={registry}
                label={''}
            />
            {registryError && (
                <HelperText
                    text="This is required"
                    type="error"
                    styles={{ marginBottom: '-16px', marginTop: '8px' }}
                />
            )}
            <br />
            <CustomInput
                name="registry_link"
                label="Registry Link (Copy and paste the entire link)"
                classes={['c-bg-faf3f78a']}
                required
            />
            <Button
                text={isLoading ? <Spinner /> : `${isUpdating ? 'Update' : 'Save'} Registry`}
                type="base4"
                htmlType="submit"
                classes={['w-100-p']}
            />
        </Form>
    );
};

export default AddRegistryForm;
