import { Form } from 'antd';
import './styles.scss';
import React, { useState } from 'react';
import CustomInput from 'shared/components/binput';
import TextArea from 'shared/components/btextArea';
import Notification from 'shared/components/notification';
import { addWebsiteSetting, updateWebsite } from 'modules/website/redux/operators';
import Button from 'shared/components/bButton';
import Spinner from 'shared/components/spinner';
import { Mixpanel } from 'shared/services/mixpanel';
import { getUser } from 'modules/user/redux/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getWebsite, getWebsiteSettings, setWebsite, setWebsiteSettingsRefresh } from 'shared/infra/redux/global/global';
import { isChecklistTaskCompleted, updateChecklistByInfoAndStringify } from 'shared/utils/helper';
import { PostHog } from 'shared/services/posthog';

interface props {
    // data: any
    // setData: any
    setClosingCollapse: any;
}

const CustomWelcomeMessageForm: React.FC<props> = ({ setClosingCollapse }) => {
    const user = useSelector(getUser);
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const data: any = useSelector(getWebsite);
    const dispatch = useDispatch();
    const checklist = useSelector(getWebsiteSettings)?.checklist

    const handleWebsiteUpdate = async (values: any) => {
        if (isLoading) return 0;

        setIsLoading(true);
        const response = await updateWebsite(data?.id, values);
        setIsLoading(false);

        if (response?.status === 'success') {
            Mixpanel.track('PARENT_customise_welcome_message', {
                $name: user?.name,
                $email: user?.email,
                website_id: data?.id,
                website_slug: data?.slug,
            });
            Notification('success', 'Website update successfully');
            // setData({...data, ...values})
            dispatch(setWebsite({ website: { ...data, ...values } }));
            if (values.description && values.description.trim() !== "" && !isChecklistTaskCompleted(checklist, "Add a Welcome Message")) {
                const payload = {
                    setting_key: "checklist",
                    setting_value: updateChecklistByInfoAndStringify(checklist?.setting_value, "Add a Welcome Message")
                }

                await addWebsiteSetting(data?.id, payload)
                PostHog.track("add_welcome_message_gem", {})
                dispatch(setWebsiteSettingsRefresh({ websiteSettingsRefresh: true }));
            }
            setClosingCollapse(true);
        } else {
            setIsLoading(false);
            Notification('error', 'There is an error. Try again later.');
        }
    };

    return (
        <>
            <div className="">
                <Form
                    name="customizeWelcomeMessage"
                    onFinish={handleWebsiteUpdate}
                    autoComplete="off"
                    form={form}
                    layout="vertical"
                    initialValues={{ ...data }}
                >
                    <CustomInput
                        styles={{ marginBottom: '0' }}
                        name="title"
                        placeholder="Welcome to Our Villie"
                        label="Title"
                        rules={[{ max: 100, message: 'Title must be less than 100 characters.' }]}
                    />
                    <TextArea
                        name="description"
                        placeholder={
                            'You are our village! Sign up to follow my Villie for baby and family updates.'
                        }
                        label="Message"
                        // rows={6}
                    />
                    <Button
                        type={'base4'}
                        htmlType="submit"
                        classes={['w-100-p', 'm-t-3-p']}
                        text={isLoading ? <Spinner /> : `Save`}
                    />
                </Form>
            </div>
        </>
    );
};

export default CustomWelcomeMessageForm;
