import "./styles.scss";
import React from 'react';
import { Row, Col} from "antd";
import Modal from 'shared/components/modal';
import Heading from "shared/components/heading";
import useWindowSize from "shared/hooks/use-window-size";
import Update from "shared/composedComponents/update";
// import Paragraph from "shared/components/paragraph";
// import Input from "shared/components/binput";
// import Button from "shared/components/bButton";
// import TextArea from "shared/components/btextArea";
import { getFirstNameAndLastName } from "shared/utils/helper";
// import Input from "shared/components/binput";

interface props {
    show: boolean
    close: any
    website: any
    handleEditUpdate: any
}

const ViewUpdatesAndMilestonesModal: React.FC<props> = ({ show, close, website, handleEditUpdate }) => {
    const { width } = useWindowSize()
    // const [selectedUpdate, setSelectedUpdate] = useState<any>(null)
    // const [addComment, setAddComment] = useState(false)

    const handleClose = () => {
        close()
        // setSelectedUpdate(null)
        // setAddComment(false)
    }

    return (
        <>
            <Modal show={show} close={handleClose} head={false} title={``} classes={["viewVillieUpdatesModal"]}>
                <div className="viewVillieUpdatesModalContainer">
                    <Row className="viewVillieUpdatesModalSection">
                        {/* { */}
                            {/* // !selectedUpdate ? */}
                                {/* <> */}
                                    <Col span={24}>
                                        <Heading 
                                            classes={["heading"]} 
                                            text={width > 450 ? <>{getFirstNameAndLastName(website?.village_owner)?.first_name}’s <span className='accent'>Updates</span></> : <>Updates</>} 
                                        />
{/* 
                                        {
                                            width > 450 ?
                                                <Heading classes={["heading"]} text={<>{getFirstNameAndLastName(website?.village_owner)?.first_name}’s <span className='accent'>Updates</span></>} />
                                                :
                                                <Heading classes={["heading"]} text={<>Updates</>} />
                                        } */}
                                    </Col>
                                    <Col span={24}>
                                        <Row justify="space-between" gutter={width > 450 ? [0, 20] : [0, 10]}>
                                            {
                                                website?.updates?.map((update: any) => (
                                                    <Col span={24} key={update?.id}>
                                                        <Update forModal data={update} handleEditUpdate={handleEditUpdate} website={website}/>
                                                    </Col>
                                                ))
                                            }
                                        </Row>
                                    </Col>
                                {/* </> */}

                                {/* :
                                <Col span={24}>
                                    {
                                        !addComment ?
                                            <Update forModal haveImage={false} details addCommentAction={() => setAddComment(true)} data={[]}/>
                                            :
                                            <>

                                                <Row justify="space-between">
                                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                        <div className="leftSide">
                                                            <Paragraph classes={["headline"]}>
                                                                <Image
                                                                    onClick={() => setAddComment(false)}
                                                                    className="backBtn" src={`../assets/newIcons/go-back.svg`}
                                                                    preview={false}
                                                                />
                                                                Leave a Comment
                                                            </Paragraph>
                                                            <Image className="image"
                                                                src={"https://cdn.pixabay.com/photo/2021/07/09/02/29/family-6398107_1280.jpg"}
                                                                alt="logo" preview={false}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                                        <div className="rightSide">
                                                        <Form
                                                            name="followOurVillieForm"
                                                            // onFinish={addGuestInfo}
                                                            autoComplete="off"
                                                            // form={form}
                                                            layout="vertical"
                                                        >
                                                            <Input
                                                                label="Your Name"
                                                                name={"name"}
                                                            />
                                                            <Input
                                                                label="Email address"
                                                                name={"email"}
                                                            />
                                                            <TextArea
                                                                label="your Comment"
                                                                name="comment"
                                                            />
                                                            <Button
                                                                type="base3"
                                                                text={"Post your comment"}
                                                                classes={["submitBtn"]}
                                                            />
                                                        </Form>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </>
                                    }
                                </Col>
                        } */}
                    </Row>
                </div>
            </Modal>
        </>
    )
}

export default ViewUpdatesAndMilestonesModal;