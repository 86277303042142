import React from 'react';
import { Button as AntdButton, Tooltip } from 'antd';
import classNames from "classnames";
import "./styles.scss";
import { CSSProperties } from 'shared/utils/interfaces';

interface props {
    text: any
    type?: "base1" | "base2" | "primary" | "secondary" | "disabled"
    action?: any
    styles?: CSSProperties
    icon?: any
    tooltip?: string
    htmlType?: "button" | "submit" | "reset" | undefined
    classes?: string[]
}

const Button: React.FC<props> = ({text, action, styles, classes=[], icon, tooltip, type = "primary", htmlType = "button"}) => {
    
    const getButtonClass = (type: "base1" | "base2" | "primary" | "secondary" | "disabled") => {
        const classNames: any = {
            "base1": "base1Btn",
            "base2": "base2Btn",
            "primary": "primaryBtn",
            "secondary": "secondaryBtn",
            "disabled": "disabledBtn",
        }
    
        return classNames[type]
    }

    return (
        <>
            <Tooltip title={tooltip}>
                <AntdButton 
                    className={classNames("legacyButtonContainer", getButtonClass(type), ...classes)}
                    onClick={action}
                    style={{...styles}}
                    icon={icon}
                    htmlType={htmlType}
                >
                    {text}
                </AntdButton>
            </Tooltip>
        </>
    )
}

export default Button;