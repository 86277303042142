export type OnBoardingState = {
    onBoardingStep: number
    addOns: any 
    onBoardingData: any
}

const initialState: OnBoardingState = {
    onBoardingStep: 1,
    addOns: [],
    onBoardingData: null
}

export default initialState