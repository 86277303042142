import "./styles.scss";
import React, { useState } from 'react';
import { Row, Col, Image } from "antd"
import useScrollPosition from "shared/hooks/use-scroll-position";
import classNames from "classnames";
import Button from "../bButton";
import Paragraph from "../paragraph";
import { CloseOutlined } from '@ant-design/icons';
import ContributionModal from "modules/website/pages/newPreviewMode/modals/contributionModal";
import FollowOurVillieModal from "modules/website/pages/newPreviewMode/modals/followOurVillieModal";
import { setIsAddNotesModalVisible } from "shared/infra/redux/global/global";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "modules/user/redux/userSlice";
// import { scrollToSection } from "shared/utils/helper";

interface props{
    website: any
}

const Actions:React.FC<props> = ({website}) => {
    const user = useSelector(getUser)
    const dispatch = useDispatch()
    const { scrollY } = useScrollPosition();
    const isSticky = scrollY > -1;
    const [isShowMoreVisible, setIsShowMoreVisible] = useState(false)
    const [isContributionModalVisible, setIsContributionModalVisible] = useState(false)
    const [contributionType, setContributionType] = useState({})
    const [isFollowOurVillieModalVisible, setIsFollowOurVillieModalVisible] = useState(false)
    // const [isNotesModalVisible, setIsNotesModalVisible] = useState(false)

    const handleCloseShowMore = () => setIsShowMoreVisible(false)
    
    return (
        <>
        <div className={classNames('actionContainer', {"sticky" : isSticky})}>
            {isShowMoreVisible && <div className="topRowOfActions">
                <Row className="moreSection">
                    <Col className="top">
                        <Paragraph classes={["heading"]}>Add to this Villie</Paragraph>
                        <div className='closeBtn'>
                            <CloseOutlined onClick={() => setIsShowMoreVisible(false)} className='icon'/>
                        </div>
                    </Col>
                    <Col span={24} className="middle">
                        {/* <img className="topVector" src={`../assets/newIcons/note-top-vector.svg`}/> */}
                        <div className="f">
                            <div className="ac"
                                onClick={() => {
                                    setIsShowMoreVisible(false)
                                    dispatch(setIsAddNotesModalVisible({ isAddNotesModalVisible: true }))
                                    // setIsNotesModalVisible(true)
                                }}
                            >Add a note</div>
                            <a href="#brands-gifts" className="ac" onClick={() => setIsShowMoreVisible(false)}>Give a gift</a>
                        </div>
                        <div className="f">
                            <div className="ac"
                                onClick={() => {
                                    setIsShowMoreVisible(false)
                                    setIsFollowOurVillieModalVisible(true)
                                }}>Follow this villie</div>
                            <a href="#games" className="ac" onClick={() => setIsShowMoreVisible(false)}>Games</a>
                        </div>
                        <Button classes={["contributionButton"]} 
                            action={() => {
                                setIsShowMoreVisible(false)
                                setIsContributionModalVisible(true)
                                setContributionType({type: "fund", name: website?.fund_type})
                            }}
                            text="Contribute to ville" 
                            type="base3"
                            // styles={{zIndex: "10"}}
                        />
                        {/* <img className="bottomVector" src={`../assets/newIcons/note-bottom-vector.svg`}/> */}
                    </Col>
                    <Col span={24} className="bottom">
                        <div className="b">
                            <div>Contact</div>
                            <div>Privacy Policy</div>
                            <div>Terms & Conditions</div>
                        </div>
                        
                    </Col>
                </Row>
            </div>}
            <div className="bottomRowOfActions">
                <a className="action" href="#updates" onClick={handleCloseShowMore}>
                    <Image className='icon' src={`../assets/newIcons/updates.svg`} preview={false}/>Updates
                </a>
                <a className="action" href="#gifts" onClick={handleCloseShowMore}>
                    <Image className='icon' src={`../assets/newIcons/gifts.svg`} preview={false}/>Gifts
                </a>
                <a className="action" href="#photos" onClick={handleCloseShowMore}>
                    <Image className='icon' src={`../assets/newIcons/photos.svg`} preview={false}/>Photos
                </a>
                <a className="action" onClick={() => {
                                    setIsShowMoreVisible(false)
                                    dispatch(setIsAddNotesModalVisible({ isAddNotesModalVisible: true }))
                                }}>
                    <Image className='icon' src={`../assets/newIcons/note.svg`} preview={false}/>Note
                </a>
                <div className="action" onClick={() => setIsShowMoreVisible(!isShowMoreVisible)}>
                    <Image className='icon' src={`../assets/newIcons/more.svg`} preview={false}/>More
                </div>
            </div>

            {
                !(!!user && user?.id === website?.user_id) && 
                <Button 
                    type="base3"
                    classes={["addNotesBtn"]}
                    text="Add a note"
                    action={() => setIsShowMoreVisible(true)}
                />
            }
            
        </div>

            <ContributionModal 
                website={website}
                show={isContributionModalVisible}
                close={() => {setIsContributionModalVisible(false)}}
                contributionType={contributionType}
            />

            <FollowOurVillieModal 
                website={website}
                show={isFollowOurVillieModalVisible}
                close={() => setIsFollowOurVillieModalVisible(false)}
            />

            
        </>
    )
}

export default Actions


