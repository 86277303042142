import React from 'react';
import './style.scss';
import CustomModal from 'shared/components/modal';

interface props {
    show: boolean;
    close: any;
    type: "error" | "success"
    content: any
}

const InfoModal: React.FC<props> = ({ show, close, content}) => {
    return (
        <>
            <CustomModal
                show={show}
                close={() => close(false)}
                classes={"infoModal"}
                title=""
                showCloseBtn={true}
                maskClosable={true}
                destroyOnClose={true}
            >
                {content}
            </CustomModal>
        </>
    );
};

export default InfoModal;
