import "./styles.scss";
import React, { useState } from 'react';
import Modal from 'shared/components/modal';
import Cropper from 'react-easy-crop';
import getCroppedImg from "shared/utils/helper";
import Button from "shared/components/button";

interface props {
    show: boolean
    close: any
    cropingImage: any
    handleCroppedImage: any
}

const ImageCropingModal: React.FC<props> = ({ show, close, cropingImage, handleCroppedImage }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState<any>(1)

    const onCropComplete = (_: any, croppedAreaPixels: any) => {
        // croppedArea: any, croppedAreaPixels: any
        setCroppedAreaPixels(croppedAreaPixels)
    }


    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [rotation, setRotation] = useState(0)

    const handleImageCroping = async (img: any) => {
        try {
            const croppedImage: any = await getCroppedImg(
                img?.src,
                croppedAreaPixels,
                rotation
            )
            
            handleCroppedImage({...img, blob: croppedImage?.blob , src: croppedImage?.img_url})

            setCrop({ x: 0, y: 0 })
            setZoom(1)
            setRotation(0)
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <Modal show={show} close={close} head={false} >
                <div className="imageCropingModalContainer">
                    <div className="cropContainer">
                        <Cropper
                            image={cropingImage?.src}
                            crop={crop}
                            zoom={zoom}
                            aspect={3 / 5}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </div>
                    <div className="controls">
                        <label className="label">Zoom</label>

                        <input
                            type="range"
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e) => {
                                setZoom(e.target.value)
                            }}
                            className="zoom-range"
                        />
                    </div>
                </div>

                <Button
                    text={"Done"}
                    action={() => {
                        handleImageCroping(cropingImage)
                    }}
                />
            </Modal>
        </>
    )
}


export default ImageCropingModal;