import "./styles.scss";
import React from 'react'
import { Typography } from 'antd';
import Section from "shared/components/section";
import Heading from "shared/components/heading";
import Button from "shared/components/bButton";
import { openInNewTab } from "shared/utils/helper";
import { getBtnBackgroundColor } from "shared/utils/helper";
import { getTextColor } from "shared/utils/helper";

const {Paragraph} = Typography;

interface props{
    website: any
    color: string
}

const VilliePromoSection: React.FC<props> = ({website,color}) => {
    return (
        <>
            <Section backgroundColor={color}>
                <div className='villiePromoSectionContainer'>
                        <Heading 
                            classes={["heading"]}
                            text={<>Create your own Villie</>}
                            styles={getTextColor(website?.website_color, color)}/>
                        <Paragraph className="description" style={getTextColor(website?.website_color, color) || {undefined}}>
                            Share updates, get support and celebrate every step, all in one place.
                        </Paragraph>
                        <Button  styles={getBtnBackgroundColor(website?.website_color, color)}
                        classes={["btn"]} action={() => openInNewTab("http://villie.com/?utm_source=Referral&utm_medium=Direct+page+referral+&utm_campaign=Bottom+of+page&utm_id=Page+")} type="theme" text="get started now!"/>
                </div>
            </Section>
        </>
    )
}

export default VilliePromoSection