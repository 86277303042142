import "./styles.scss";

type NotificationType = 'success' | 'error' | 'info' | 'warning';
type PlacementType = 'topRight' | 'topLeft' | 'bottomRight' | 'bottomLeft';

const Notification = (type: NotificationType, message: string, placement: PlacementType = "topRight") => {
    const containerId = `notificationContainer-${placement}`;
    let container = document.getElementById(containerId);
    if (!container) {
        container = document.createElement('div');
        container.id = containerId;
        container.className = `notificationContainer ${placement}`;
        document.body.appendChild(container);
    }

    const notification = document.createElement('div');
    notification.className = `notification ${type}`;
    // notification.innerHTML = `
    //     <img class="icon" src="../assets/newicons/success-notification.svg"/>
    //     <span>${message}</span>
    //     <img class="closeBtn" onclick="this.parentElement.style.display='none';" src="../assets/newicons/close.svg"/>
    // `;

    notification.innerHTML = `
        <div class="notification-content">
            <img class="icon" src="../assets/newIcons/success-notification.svg"/>
            <span class="message">${message}</span>
        </div>
        <img class="closeBtn" onclick="this.parentElement.style.display='none';" src="../assets/newIcons/close.svg"/>
    `;

    container.appendChild(notification);

    setTimeout(() => {
        notification.style.display = 'none';
    }, 5000);
};

export default Notification;
